import { createSlice } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import pickBy from 'lodash/pickBy'
import api, { createWrappedAsyncThunk } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { setVisibility } from 'src/modules/visibility/visibilitySlice'
import {
  closeInformationRequest,
  reopenInformationRequest,
} from 'src/modules/informationRequest/informationRequestSlice'

import { setSourceTypes } from './SourcesCommon'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'
import { updateNodeDirectoryFromContent } from '../viewer/viewerSlice'

const SLICE_NAME = 'content'

export const pinArticle = createWrappedAsyncThunk(
  `${SLICE_NAME}/pinArticle`,
  ({ pinTargetId, articleId }) => {
    return api.patch(`/history/${getTreeSlugFromStore()}/pin_content/`, {
      body: pickBy({ pinTargetId, articleId }),
    })
  }
)

const fetchArticleThunk = async (
  { id, treeSlug, block_limit },
  { dispatch }
) => {
  const queryStringParameters = pickBy({
    block_limit,
  })

  const slug = treeSlug || getTreeSlugFromStore()

  const article = await api.get(`/history/${slug}/content/${id}/`, {
    queryStringParameters,
  })

  await dispatch(updateNodeDirectoryFromContent(article))

  return article
}

export const fetchArticleStub = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchArticleStub`,
  fetchArticleThunk
)

export const fetchArticle = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchArticle`,
  fetchArticleThunk
)

// Content
export const fetchContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchContent`,
  id => {
    return api.get(`/history/${getTreeSlugFromStore()}/content/${id}/`)
  }
)

// Comments
export const createComment = createWrappedAsyncThunk(
  `${SLICE_NAME}/createComment`,
  ({ contentId, text }) => {
    return api.post(
      `/history/${getTreeSlugFromStore()}/content/${contentId}/comment/`,
      {
        body: { text },
      }
    )
  }
)

export const deleteComment = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteComment`,
  ({ id }) => api.del(`/history/${getTreeSlugFromStore()}/comment/${id}/`)
)

/**
 * This extra reducer can be configured from any slice that manages
 * an item of content or a list of content which can receive comments.
 *
 * It can receive a state accessor from the slice in question to allow
 * it to find the content to update.
 *
 * @param {*} stateAccessor
 * @returns
 */
export const createCommentFulfilled =
  stateAccessor =>
  (state, { payload, meta: { arg } }) => {
    const contentId = arg.contentId
    const user = arg.user
    const newComment = {
      ...payload,
      author: user,
    }
    const contentState = stateAccessor(state)
    const content = isArray(contentState)
      ? contentState.find(content => content.id === contentId)
      : contentState
    if (content) {
      content.comments = [newComment, ...content.comments]
    }
    sendEvent(ga4Events.COMMENT_CREATED)
  }

export const deleteCommentFulfilled =
  stateAccessor =>
  (state, { meta: { arg } }) => {
    const contentId = arg.contentId
    const contentState = stateAccessor(state)
    const content = isArray(contentState)
      ? contentState.find(content => content.id === contentId)
      : contentState
    if (content) {
      content.comments = content.comments.filter(
        comment => comment.id !== arg.id
      )
    }
  }

const initialState = {
  content: null,
  article: null,
}

const updateContent = (state, { payload }) => {
  if (state.content?.id === payload.id) {
    state.content = payload
  }
}

export const contentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetContent: state => initialState,
  },
  extraReducers: {
    [fetchArticleStub.pending]: state => {
      state.content = null
    },
    [fetchArticleStub.fulfilled]: (state, { payload }) => {
      state.content = payload
      //the UI needs to know a source's type to display it, the type is not persisted to the db but can be derived - derive it now.
      setSourceTypes(state.content)
    },
    [fetchArticle.fulfilled]: (state, { payload }) => {
      state.content = payload
      setSourceTypes(state.content)
    },
    [fetchContent.pending]: state => {
      state.content = null
    },
    [fetchContent.fulfilled]: (state, { payload }) => {
      state.content = payload
      setSourceTypes(state.content)
    },
    [createComment.fulfilled]: createCommentFulfilled(state => state.content),
    [deleteComment.fulfilled]: deleteCommentFulfilled(state => state.content),
    [setVisibility.fulfilled]: (state, { meta }) => {
      const { instanceId: id, visibility } = meta.arg
      if (state.content?.id === id) {
        state.content.visibility = visibility
      }
    },
    [closeInformationRequest.fulfilled]: updateContent,
    [reopenInformationRequest.fulfilled]: updateContent,
  },
})

export const { resetContent } = contentSlice.actions

export const selectContent = state => state.content.content

export default contentSlice.reducer
