import { useRouteMatch } from 'react-router-dom'

import { Tabs } from 'src/modules/ui'
import { Title } from 'src/modules/home/Home'
import PublicFeed from '../page/PublicFeed'
import PublicResearch from '../page/PublicResearch'
import PublicDocuments from '../page/PublicDocuments'
import PublicHomePhotos from './PublicHomePhotos'
import PublicHomePageList from './PublicHomePageList'
import HtmlHeader from '../../app/HtmlHeader'
import PublicHomePageTree from './PublicHomePageTree'
import { useSelector } from 'react-redux'
import { selectTree } from 'src/modules/public/tree/treeSlice'
import { useMemo } from 'react'
import { usePrerender } from '../hooks'
import PublicPinnedArticle from '../page/PublicPinnedArticle'
import { PINNED_ARTICLE_DISPLAY_CONFIG } from '../../page/PinnedArticle'
import { useIsAlphaFeatureUser } from '../../auth/hooks'
import PublicHomeIndividualList from './PublicHomeIndividualList'

const publicHomeUrl = (url, tab) => `${url}/${tab}/`

const PublicHome = () => {
  const tree = useSelector(selectTree)
  let { url } = useRouteMatch()
  usePrerender(tree !== undefined)
  const showFeature = useIsAlphaFeatureUser()?.documents

  const defaultRoutes = [
    {
      navLabel: 'Tree',
      path: publicHomeUrl(url, 'tree'),
      route: publicHomeUrl(url, 'tree'),
      component: PublicHomePageTree,
      fullWidth: true,
    },
    {
      navLabel: 'Research & Recollections',
      path: publicHomeUrl(url, 'research'),
      route: publicHomeUrl(url, 'research'),
      component: PublicResearch,
    },
    showFeature && {
      navLabel: 'Documents',
      path: publicHomeUrl(url, 'documents'),
      route: publicHomeUrl(url, 'documents'),
      component: PublicDocuments,
    },
    {
      navLabel: 'Media',
      path: publicHomeUrl(url, 'media'),
      route: publicHomeUrl(url, 'media'),
      component: PublicHomePhotos,
    },
    {
      navLabel: 'Occasions',
      path: publicHomeUrl(url, 'occasions'),
      route: publicHomeUrl(url, 'occasions'),
      component: PublicHomePageList,
    },
    {
      navLabel: 'Artifacts',
      path: publicHomeUrl(url, 'artefacts'),
      route: publicHomeUrl(url, 'artefacts'),
      component: PublicHomePageList,
    },
    {
      navLabel: 'Places',
      path: publicHomeUrl(url, 'places'),
      route: publicHomeUrl(url, 'places'),
      component: PublicHomePageList,
    },
    {
      navLabel: 'People',
      path: publicHomeUrl(url, 'people'),
      route: publicHomeUrl(url, 'people'),
      component: PublicHomeIndividualList,
    },
  ].filter(x => x)

  const title = useMemo(
    () => <Title titleText={tree?.name} fromPublic={true} isPublic={true} />,
    [tree]
  )

  const headerProps = {
    title: tree?.name,
    type: 'website',
    url: window.location.href,
  }

  let routes = defaultRoutes
  if (tree?.pinnedArticle) {
    const welcomeRoute = {
      navLabel: 'Welcome',
      exact: true,
      path: url,
      route: url,
      component: PublicPinnedArticle,
      componentParams: {
        id: tree?.pinnedArticle.id,
        config: PINNED_ARTICLE_DISPLAY_CONFIG,
      },
    }

    const newsFeed = {
      navLabel: 'Feed',
      exact: true,
      path: publicHomeUrl(url, 'feed'),
      route: publicHomeUrl(url, 'feed'),
      component: PublicFeed,
    }

    routes = [welcomeRoute, newsFeed].concat(defaultRoutes)
  } else {
    const newsFeed = {
      navLabel: 'News Feed',
      exact: true,
      path: url,
      route: url,
      component: PublicFeed,
    }
    routes = [newsFeed].concat(defaultRoutes)
  }

  return (
    <>
      <HtmlHeader {...headerProps} />

      <Tabs
        disableSearch
        title={title}
        routes={routes}
        fullWidthSubRootContainer={true}
      />
    </>
  )
}

export default PublicHome
