import { DEFAULT_ARTICLE_DISPLAY_CONFIG } from '../content/Article'
import React, { useState } from 'react'
import { Button, LoadingIndicator } from '../ui'
import { Box, Stack, styled } from '@mui/material'
import { pinArticle } from '../content/contentSlice'
import { useActionDispatcher, useNotification } from '../app'
import { useDispatch } from 'react-redux'
import SearchArticleDialog from './SearchArticleDialog'
import { DEFAULT_WRITE_ARTICLE_CONFIG } from '../writeArticle/WriteArticle'
import { WriteArticle } from '../writeArticle'
import Article from '../content/Article'
import { resetHomePageState } from '../home/homeSlice'
import { ACTION_CREATE, ACTION_EDIT } from '../app/appConstants'
import { INSTANCE_TYPE_ARTICLE, useTreeLink } from '../app/links'
import { usePermissions } from '../auth/authHooks'
import { AddStoryButton } from '../ui/actionButtons'

export const PINNED_ARTICLE_DISPLAY_CONFIG = {
  ...DEFAULT_ARTICLE_DISPLAY_CONFIG,
  showHTMLHeaders: false,
  showFullHeader: false,
  showInLineHeader: true,
  showBackGround: false,
  showComments: false,
  showTags: false,
  showSources: false,
  inLineEdit: false,
}

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

const PinExistingArticleButton = ({
  pinButtonText,
  onArticleLoaded,
  pinTarget,
  pinTargetType,
}) => {
  const dispatchPinArticle = useActionDispatcher(pinArticle)
  const dispatch = useDispatch()
  const { showError, showSuccess } = useNotification()

  const checkPermissions = usePermissions()

  const handlePinUnPinArticle = article => {
    if (!article) {
      return
    }

    if (
      checkPermissions(ACTION_EDIT, {
        instanceType: INSTANCE_TYPE_ARTICLE,
        instance: article,
      }).hasPermission
    ) {
      dispatchPinArticle({
        pinTargetId: pinTarget?.id,
        articleId: article?.id,
      }).then(() => {
        dispatch(onArticleLoaded(article))
        showSuccess('Pin Successful')
      })
    } else {
      showError('You do not have rights to pin this item')
    }
  }

  return (
    <>
      <SearchArticleDialog
        onSelectArticle={handlePinUnPinArticle}
        trigger={props => (
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{
              instanceType: INSTANCE_TYPE_ARTICLE,
            }}
            color="primary"
            size="large"
            {...props}
          >
            {pinButtonText}
          </Button>
        )}
      />
    </>
  )
}

const CreatePinnedArticleButton = ({
  buttonText,
  pinTarget,
  pinTargetType,
  onArticleChanged,
  presetTargets,
  className,
  articleTitle = '',
}) => {
  const writeLink = useTreeLink('write-article')

  return (
    <AddStoryButton
      className={className}
      to={{
        pathname: writeLink,
        state: {
          pinnedTarget: pinTarget?.id,
          presetTargets: presetTargets,
          articleTitle: articleTitle,
          joyrideName: 'joyride_add_content_block_hint',
          joyrideTimeStamp: Date.now(),
        },
        data: {
          onArticleChanged: onArticleChanged,
        },
      }}
      buttonText={buttonText}
    />
  )
}

export const PinnedArticleButtons = ({
  createButtonText,
  pinButtonText,
  pinTarget,
  pinTargetType,
  onArticleChanged,
  presetTargets,
  onArticleLoaded,
  showPinButton = true,
  articleTitle = '',
}) => {
  return (
    <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
      <CreatePinnedArticleButton
        articleTitle={articleTitle}
        className={'joyride_add_profile'}
        buttonText={createButtonText}
        pinTarget={pinTarget}
        pinTargetType={pinTargetType}
        onArticleChanged={onArticleChanged}
        presetTargets={presetTargets}
      />

      {showPinButton && (
        <PinExistingArticleButton
          pinButtonText={pinButtonText}
          pinTarget={pinTarget}
          pinTargetType={pinTargetType}
          onArticleLoaded={onArticleLoaded}
        />
      )}
    </Stack>
  )
}

const PinnedArticle = ({
  onArticleLoaded,
  cachedPinnedArticleData,
  config,
  children,
  targets,
  pinTarget,
  sx = {},
}) => {
  const dispatchPinArticle = useActionDispatcher(pinArticle)
  const dispatch = useDispatch()

  const [editMode, setEditMode] = useState(false)

  const INLINE_WRITE_ARTICLE_CONFIG = {
    ...DEFAULT_WRITE_ARTICLE_CONFIG,
    inLineEdit: config?.inLineEdit,
    onInLineSave: config?.inLineEdit ? () => setEditMode(false) : undefined,
    onArticleLoaded: onArticleLoaded,
    showTags: config?.showTags,
    showInLineDelete: true,
    onDeleteNavigationPageCount: article => 0,
    hideThumbnail: true,
  }

  if (cachedPinnedArticleData.loading) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  /*
   passing a blank article will un pin it
   */
  const handlePinUnPinArticle = () => {
    dispatchPinArticle({
      pinTargetId: pinTarget,
    }).then(() => {
      dispatch(onArticleLoaded(undefined))
    })
  }

  const handleArticleDelete = async () => {
    dispatch(resetHomePageState())
    setEditMode(false)
  }

  return (
    <>
      {cachedPinnedArticleData?.articleId ? (
        <Box>
          {!editMode && (
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <ActionButtons></ActionButtons>
            </Box>
          )}
          <>
            {editMode ? (
              <WriteArticle
                articleId={cachedPinnedArticleData?.articleId}
                config={INLINE_WRITE_ARTICLE_CONFIG}
                onArticleDelete={handleArticleDelete}
              />
            ) : (
              <Article
                handleUnPinArticle={handlePinUnPinArticle}
                onArticleLoaded={onArticleLoaded}
                cachedArticle={cachedPinnedArticleData?.article}
                articleId={cachedPinnedArticleData?.articleId}
                config={config}
                onArticleEdit={
                  config?.inLineEdit ? () => setEditMode(true) : undefined
                }
                sx={sx}
              />
            )}
          </>
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default PinnedArticle
