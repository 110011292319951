import { makeStyles } from '@mui/styles'
import React, { useState, useCallback, useRef } from 'react'

import { PhotoListNavigatorDialog } from 'src/modules/photo/MediaNavigator'
import SimpleCarousel, {
  SimpleCarouselContext,
} from 'src/modules/ui/SimpleCarousel'
import { MEDIA_ROW_ALIGNMENT_COVER } from 'src/modules/writeArticle/contentBlockConstants'

import CoverMediaRow from './CoverMediaRow'
import { MODE_DISPLAY } from '../ui/SimpleCarousel'
import ArticlePhoto from './ArticlePhoto'
import RefreshDialog from 'src/modules/ui/RefreshDialog'
import useDetectPrint from 'react-detect-print'

export const PHOTO_CAPTION_HEIGHT = 29

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
  articlePhoto: {
    marginBottom: '0 !important',
  },
  mediaImage: {
    objectFit: 'cover',
    display: 'block',
    width: 'auto',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

const MediaRowBlock = ({
  allArticlePhotos = [],
  treeSlug,
  media,
  multiRow = false,
  mediaRowAlignment,
  mediaRowHeight,
  mediaRowMeta,
  mediaRowShowTitleIfNoCaption,
  mediaDetailEditDialogTitle,
  mediaDetailShowTranscription,
  mediaDetailOfferEnterTranscription,
  mediaDetailShowTakenOnDate,
  mediaDetailHideTags,
  mediaDetailDefaultAllowNoTags,
  allowDetailsScreenOnLinks = true,
}) => {
  const isPrinting = useDetectPrint()
  const classes = useStyles()
  const coverRef = useRef()
  //const coverHeight = useCalculateCoverHeight(coverRef.current, media)

  const [clickedPhoto, setClickedMedia] = useState(null)
  const [triggerReload, setTriggerReload] = useState(false)
  const handleDialogClose = useCallback(() => {
    setClickedMedia(null)
  }, [setClickedMedia])

  const isCover = mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER

  const flexOverride = media.length > 1 ? undefined : 1
  const markup = media?.map((item, idx) => {
    const props = {
      className: classes.articlePhoto,
      imageClassName: classes.mediaImage,
      photo: item,
      onClick: allowDetailsScreenOnLinks ? () => setClickedMedia(item) : null,
      mediaRowHeight,
      height: mediaRowMeta,
      isWithinCarousel: true,
    }

    if (isCover) {
      return (
        <ArticlePhoto
          {...props}
          isWithinCarousel={false}
          height={'auto'}
          //height={coverHeight}
          key={`${item.id}-${idx}`}
          marginRight={0}
          flexOverride={flexOverride}
        />
      )
    } else {
      return (
        <SimpleCarouselContext.Consumer key={`${item.id}-${idx}`}>
          {({ handleLoaded }) => (
            <ArticlePhoto
              {...{
                ...props,
              }}
              handleLoaded={handleLoaded}
              showTitleIfNoCaption={mediaRowShowTitleIfNoCaption}
              forcePrintStyle={true}
            />
          )}
        </SimpleCarouselContext.Consumer>
      )
    }
  })

  return (
    <div className={classes.root}>
      {!!clickedPhoto && (
        <PhotoListNavigatorDialog
          media={allArticlePhotos}
          initialPhoto={clickedPhoto}
          treeSlug={treeSlug}
          onClose={handleDialogClose}
          handleDelete={() => setTriggerReload(true)}
          isArticle
          mediaRowShowTitleIfNoCaption={mediaRowShowTitleIfNoCaption}
          mediaDetailEditDialogTitle={mediaDetailEditDialogTitle}
          mediaDetailShowTranscription={mediaDetailShowTranscription}
          mediaDetailOfferEnterTranscription={
            mediaDetailOfferEnterTranscription
          }
          mediaDetailShowTakenOnDate={mediaDetailShowTakenOnDate}
          mediaDetailHideTags={mediaDetailHideTags}
          mediaDetailDefaultAllowNoTags={mediaDetailDefaultAllowNoTags}
        />
      )}
      {isCover ? (
        <CoverMediaRow isPrinting={isPrinting} ref={coverRef}>
          {markup}
        </CoverMediaRow>
      ) : (
        <SimpleCarousel
          mediaRowAlignment={mediaRowAlignment}
          multiRow={multiRow}
          mediaRowHeight={mediaRowHeight}
          mediaRowMeta={mediaRowMeta}
          mode={MODE_DISPLAY}
        >
          {markup}
        </SimpleCarousel>
      )}
      <RefreshDialog refreshModalOpen={triggerReload} />
    </div>
  )
}

export default MediaRowBlock
