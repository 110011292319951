export const formatFamilyWithDate = family => {
  if (family) {
    return `${family?.surname} - ${family?.earliestBirthYear}`
  } else {
    return ''
  }
}

export const createFamilySearchOptions = families => {
  return families.map(f => ({
    id: f.id,
    display: `${f.surname} ${f.earliestBirthYear || ''}`.trim(),
  }))
}
