import { Box } from '@mui/material'
import React, { useState } from 'react'
import MarkerNavigator from './MarkerNavigator'
import MarkerEditor from './MarkerEditor'
import { Button, ConfirmDialog } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { createMap, updateMap } from './mapSlice'
import { useActionDispatcher, useNotification } from '../app'

const MapSidePanel = ({
  id,
  currentMap,
  isEditing = false,
  navigateMarkers,
  setLocations,
  locations,
  currentMarker,
  handleRefreshMapBlock,
  closeMap,
  resetMap,
  setCurrentMarkerIndex,
  flyToEditMode,
  preview,
  setPreview,
  dropPinMode,
  setDropPinMode,
  maxHeight = 'calc(100vh - 48px)', // default to browser window height - 48px
  fixedWidth,
  showLatLong,
  mapIsFullScreen,
  mapIsFullWindow,
  maxTitleLines,
  markerInfoPanelEnabled,
  singlePoint,
  treeSlug,
  markerItemType,
}) => {
  const [editableMapTitle, setMapTitle] = useState(currentMap?.title || '')
  const [hidePanel, setHidePanel] = useState(false)
  const dispatchCreateMap = useActionDispatcher(createMap)
  const dispatchUpdateMap = useActionDispatcher(updateMap)
  const { showError } = useNotification()

  const togglePreview = () => {
    setHidePanel(true)
    if (preview) {
      resetMap()
    } else {
      if (!locations[locations.length - 1]?.id) {
        setLocations(prevState => {
          let newLocations = [...prevState]
          newLocations.pop()
          return newLocations
        })
      }
      navigateMarkers('next')
    }
    setTimeout(() => {
      if (preview) {
        setCurrentMarkerIndex(-1)
      }
      setPreview(!preview)
      setHidePanel(false)
    }, 800)
  }

  const createNewMap = async () => {
    try {
      if (!editableMapTitle) {
        showError('You must add a title to your map before saving')
        return
      }
      const map = {
        title: editableMapTitle,
        mapLinks: locations.map((locationItem, index) => ({
          mapLinkOrder: index,
          target: {
            freeText: locationItem?.address,
            latiGed: locationItem?.lat,
            longGed: locationItem?.lng,
            setPhoto: locationItem?.photo?.id,
          },
          instanceType: 'address',
          description: locationItem?.desc || '',
          title: locationItem?.title,
        })),
      }

      const newMap = await dispatchCreateMap(map)
      handleRefreshMapBlock(newMap?.payload)
    } catch (error) {
      console.log(error)
    }
  }
  const saveMap = async () => {
    try {
      if (!editableMapTitle) {
        showError('You must add a title to your map before saving')
        return
      }
      const map = {
        id: currentMap?.id,
        title: editableMapTitle,
        mapLinks: locations.map((locationItem, index) => {
          let id
          if (!locationItem?.id?.includes('SORTABLE-ID')) {
            id = locationItem?.id
          }
          return {
            id,
            mapLinkOrder: index,
            target: {
              freeText: locationItem?.address,
              latiGed: locationItem?.lat,
              longGed: locationItem?.lng,
              setPhoto: locationItem?.photo?.id,
            },
            instanceType: 'address',
            description: locationItem?.desc || '',
            title: locationItem?.title || '',
          }
        }),
      }

      const updatedMap = await dispatchUpdateMap({ id: currentMap?.id, map })
      handleRefreshMapBlock(updatedMap?.payload)
    } catch (error) {
      console.log(error)
    }
  }

  const editMode = !preview && isEditing

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        //position: 'absolute',
        //left: hidePanel ? -400 : 10,
        transitionDuration: '0.6s',
        //top: 11,
        visibility: hidePanel ? 'hidden' : 'visible',
        marginTop: '10px',
        borderRadius: '4px',
        width: fixedWidth ?? (editMode ? 400 : 'fit-content'),
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        maxHeight: maxHeight,
      }}
    >
      {markerInfoPanelEnabled && (
        <>
          {editMode ? (
            <>
              {' '}
              {!singlePoint && (
                <MarkerEditor
                  id={id}
                  closeMap={closeMap}
                  currentMap={currentMap}
                  locations={locations}
                  setLocations={setLocations}
                  handleRefreshMapBlock={handleRefreshMapBlock}
                  editableMapTitle={editableMapTitle}
                  setMapTitle={setMapTitle}
                  currentMarker={currentMarker}
                  setCurrentMarkerIndex={setCurrentMarkerIndex}
                  flyToEditMode={flyToEditMode}
                  dropPinMode={dropPinMode}
                  setDropPinMode={setDropPinMode}
                />
              )}
            </>
          ) : (
            <MarkerNavigator
              closeMap={closeMap}
              currentMarker={currentMarker}
              navigateMarkers={navigateMarkers}
              isPreview={preview}
              //onClickStartFlyby={onClickStartFlyby}
              flybyButtonText={null} //TODO remove flyby button from MarkerNavigator
              showLatLong={showLatLong}
              maxTitleLines={maxTitleLines}
              treeSlug={treeSlug}
              itemType={markerItemType} // used when creating link to navigate to item when clicked
            />
          )}
        </>
      )}
      <Box
        sx={{
          position: 'fixed',
          right: 11,
          bottom: 32,
          backgroundColor: 'white',
          padding: 1,
          borderRadius: '4px',
          display: 'flex',
          gap: 1,
          flexDirection: 'row-reverse',
        }}
      >
        {isEditing ? (
          <>
            {singlePoint ? (
              <>
                {currentMarker && (
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={e => {
                      closeMap(currentMarker)
                    }}
                  >
                    Save and close
                  </Button>
                )}
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={closeMap}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <ConfirmDialog
                  onConfirm={closeMap}
                  submitText="Exit"
                  cancelText="Continue editing"
                  submitColor="error"
                  trigger={props => (
                    <Button
                      color="error"
                      permissionAction={ACTION_ALL_ACCESS}
                      {...props}
                    >
                      Exit
                    </Button>
                  )}
                >
                  All changes will be lost, are you sure you want to exit?
                </ConfirmDialog>

                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={togglePreview}
                  variant="outlined"
                >
                  {!preview ? 'Preview' : 'Continue editing'}
                </Button>
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={currentMap?.id ? saveMap : createNewMap}
                  disabled={
                    dispatchCreateMap.status === 'loading' ||
                    dispatchUpdateMap.status === 'loading'
                  }
                >
                  {currentMap?.id ? 'Save' : 'Create'}
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {(mapIsFullWindow || mapIsFullScreen || !preview) && (
              <Button permissionAction={ACTION_ALL_ACCESS} onClick={closeMap}>
                Close
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default MapSidePanel
