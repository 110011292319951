import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'

import { ViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../common/viewConfigUtils'
import BaseIndividualsList from '../page/BaseIndividualsList'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import { Box } from '@mui/material'
import Refresh from '../ui/Refresh'
import { ActionsContainer } from '../page/LinkedPageList'
import { UpdateIndividualRelationshipsListDialog } from '../viewer/UpdateIndividualRelationships'
import {
  AddIndividualOnlyManualDialog,
  AddOrSelectIndividualButton,
  SelectIndividualOnlyManualDialog,
} from '../viewer/ChooseOrAddIndividual'

import { selectIndividualById } from '../viewer/viewerSlice'
import { useLinkedPageTargets } from './hooks'
import {
  fetchLinkedIndividuals,
  selectLinkedIndividuals,
  selectPageIndividualsSearchTerm,
  setPageIndividualsSearchTerm,
  setViewConfig,
} from './pageSlice'

import Typography from '@mui/material/Typography'
import IndividualSearchBox from '../ui/IndividualSearchBox'

const Individuals = ({ pageType }) => {
  const [addIndividualModalOpen, setAddIndividualModalOpen] = useState(false)
  const [selectIndividualModalOpen, setSelectIndividualModalOpen] =
    useState(false)
  const { target, presetTargets } = useLinkedPageTargets()
  const [selectedIndividualId, setSelectedIndividualId] = useState(null)
  var individual = useSelector(selectIndividualById(selectedIndividualId))
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatch = useDispatch()
  const dispatchFetchLinkedIndividuals = useActionDispatcher(
    fetchLinkedIndividuals
  )
  const linkedIndividuals = useSelector(selectLinkedIndividuals)
  const { next, results, updated } = linkedIndividuals
  const type = INSTANCE_TYPE_INDIVIDUAL

  const makeArgs = useCallback(
    page => {
      const args = { target, page, type, viewConfigQueryParams }
      return args
    },
    [type, viewConfigQueryParams, target]
  )

  useEffect(() => {
    const fetchLinkedIndividuals = async () => {
      await dispatchFetchLinkedIndividuals(makeArgs(0))
    }
    if (!results.length) {
      fetchLinkedIndividuals()
    }
  }, [dispatchFetchLinkedIndividuals, makeArgs, type, results.length, updated])

  const refresh = () => {
    dispatchFetchLinkedIndividuals(makeArgs(0))
  }

  const handleFetchMore = () => {
    if (dispatchFetchLinkedIndividuals.status === 'loading') {
      return
    }
    dispatchFetchLinkedIndividuals(makeArgs(linkedIndividuals.page + 1))
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setViewConfig({ type, viewConfig }))
    dispatchFetchLinkedIndividuals(makeArgs(0))
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    linkedIndividuals,
    viewConfigQueryParams
  )

  const onIndividualActionClick = individual => {
    setSelectedIndividualId(individual.id)
  }

  const search = useCallback(() => {
    dispatchFetchLinkedIndividuals(makeArgs(0))
  }, [makeArgs, dispatchFetchLinkedIndividuals])

  return (
    <>
      <ActionsContainer>
        <ViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={handleSetViewConfig}
          target={target}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
          <Typography
            variant="caption"
            display="block"
            sx={{ paddingRight: '1rem' }}
          >
            Search:
          </Typography>
          <IndividualSearchBox
            searchTermAction={setPageIndividualsSearchTerm}
            selector={selectPageIndividualsSearchTerm}
            onChange={search}
            onClear={refresh}
            busy={dispatchFetchLinkedIndividuals.status === 'loading'}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <AddIndividualOnlyManualDialog
            setModalOpen={setAddIndividualModalOpen}
            modalOpen={addIndividualModalOpen}
            presetTargets={presetTargets}
            onIndividualAdded={individual =>
              setSelectedIndividualId(individual.id)
            }
          />
          <SelectIndividualOnlyManualDialog
            setModalOpen={setSelectIndividualModalOpen}
            modalOpen={selectIndividualModalOpen}
            onIndividualSelected={individual => {
              setSelectedIndividualId(individual.id)
            }}
          />
          <AddOrSelectIndividualButton
            setSelectIndividualModalOpen={setSelectIndividualModalOpen}
            setAddIndividualModalOpen={setAddIndividualModalOpen}
          />
          <Refresh onClick={refresh} />
        </Box>
      </ActionsContainer>

      <BaseIndividualsList
        {...{
          dispatchFetchIndividuals: dispatchFetchLinkedIndividuals,
          next,
          handleFetchMore,
          individuals: linkedIndividuals,
          results,
          type,
          onIndividualActionClick: onIndividualActionClick,
        }}
      />
      <UpdateIndividualRelationshipsListDialog
        individual={individual}
        target={target}
        pageType={pageType}
        isLoadingOveride={true}
        onCloseParentDialog={() => setSelectedIndividualId(null)}
      />
    </>
  )
}

export default Individuals
