import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  Box,
  Chip,
  Input,
  Popover,
  Select,
  MenuItem,
  Stack,
  styled,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useActionDispatcher } from 'src/modules/app'
import { Button, LoadingIndicator, Typography } from 'src/modules/ui'
import TagForm from 'src/modules/writeArticle/TagForm'

import {
  fetchPhotosForSelector,
  selectPhotoSelectorOptions,
  clearPhotoOptionsResults,
} from './photoSlice'
import Thumbnail, { THUMB_SIZE_SMALL } from './Thumbnail'
import CreateCroppedPhotoDialog from './ImageCropper'
import { MEDIA_TYPE_PHOTO } from '.'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { WriteArticleContext } from '../writeArticle/contexts'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  photoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-1),
  },
  photoScrollContainer: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  photo: {
    margin: theme.spacing(1),
  },
  sidebar: {
    flexShrink: 0,
    width: 300,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  tag: {
    margin: theme.spacing(0, 0, 2, 0),
    width: '100%',
  },
  tags: {
    margin: 0,
    width: '100%',
  },
}))

const FlexOverflow = styled(Box)({
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
})

const UPLOADED_ASC = 'Newest Upload Date'
const UPLOADED_DSC = 'Oldest Upload Date'
const DATE_TAKEN_ASC = 'Newest Date Taken'
const DATE_TAKEN_DSC = 'Oldest Date Taken'

const FILTER_OPTIONS = {
  [UPLOADED_ASC]: {
    params: { ordering: '-created' },
  },
  [UPLOADED_DSC]: {
    params: { ordering: 'created' },
  },
  [DATE_TAKEN_ASC]: {
    params: {
      ordering: '-date_of_origin_earliest',
      exclude_no_date_of_origin: true,
    },
  },
  [DATE_TAKEN_DSC]: {
    params: {
      ordering: 'date_of_origin_earliest',
      exclude_no_date_of_origin: true,
    },
  },
}

const ThumbnailGallery = ({
  aspectRatio,
  cropAfterSelect = true,
  handleCloseModal = () => {},
  mediaType,
  multiple,
  onSelect,
  onSelectCropped,
  open = false,
  presetTargets = [],
  showFilterInPopover = false,
  alignTreePreview,
}) => {
  const classes = useStyles()
  const [filter, setFilter] = useState(UPLOADED_ASC)
  const [targets, setTargets] = useState(presetTargets)
  const [photoToCrop, setPhotoToCrop] = useState(null)
  const [selected, setSelected] = useState(new Set())
  const latestNetworkReq = useRef()
  const params = useParams()
  const treeSlug = params?.slug

  const dispatchFetchPhotosForSelector = useActionDispatcher(
    fetchPhotosForSelector
  )
  const dispatch = useDispatch()

  const dispatchClearPhotoOptionsResultsMemoized = useCallback(() => {
    dispatch(clearPhotoOptionsResults(undefined)) // action not a thunk
  }, [dispatch])

  const filterParams = FILTER_OPTIONS[filter].params

  const photoOptions = useSelector(selectPhotoSelectorOptions)

  useEffect(() => {
    if (open) {
      //see if we can use the results from last time, save reloading from server
      if (
        !Array.isArray(photoOptions.results) ||
        photoOptions.results.length === undefined || //assume array of 0 length means all available data has been loaded
        photoOptions.results.length === null ||
        !photoOptions.args ||
        photoOptions.args.ordering !== filterParams.ordering ||
        !photoOptions.args.targets ||
        !_.isEqual(photoOptions.args.targets, targets)
      ) {
        // Cancel any existing requests
        const action = dispatchFetchPhotosForSelector({
          targets,
          type: mediaType,
          treeSlug: treeSlug,
          ...filterParams,
        })
        latestNetworkReq.current = action
      }
    }
  }, [
    dispatchFetchPhotosForSelector,
    open,
    targets,
    mediaType,
    filterParams,
    filter,
    photoOptions.args,
    photoOptions.results,
    treeSlug,
  ])

  useEffect(() => {
    return () => {
      latestNetworkReq?.current?.abort()
    }
  }, [])

  const handleChangeTags = useCallback(
    targets => {
      latestNetworkReq?.current?.abort()
      dispatchClearPhotoOptionsResultsMemoized()
      setTargets(targets)
    },
    [setTargets, dispatchClearPhotoOptionsResultsMemoized]
  )

  const { next, page, results } = photoOptions

  const handleFetchMore = () => {
    const action = dispatchFetchPhotosForSelector({
      page: page + 1,
      targets,
      type: mediaType,
      ...filterParams,
    })

    latestNetworkReq.current = action
  }

  const handleFetchMoreMemoized = useCallback(handleFetchMore, [
    dispatchFetchPhotosForSelector,
    filterParams,
    mediaType,
    page,
    targets,
  ])

  // The user has made their initial selection
  const handleSelect = media => {
    if (multiple) {
      const newSelected = new Set(selected)
      if (newSelected.has(media.id)) {
        newSelected.delete(media.id)
      } else {
        newSelected.add(media.id)
      }
      setSelected(newSelected)
      onSelect([...newSelected])
    } else if (cropAfterSelect && media.type === MEDIA_TYPE_PHOTO) {
      setPhotoToCrop(media)
    } else {
      if (onSelect) {
        onSelect(media)
        handleCloseModal()
      }
      handleCloseModal()
    }
  }

  const handleDeselect = () => {
    setSelected(new Set())
    onSelect([])
  }

  // The user decided not to use this photo
  const handleCroppingCancelled = photo => {
    setPhotoToCrop(null)
  }

  // The user cropped and saved a new cropped photo
  const handleDoneCropping = croppedPhoto => {
    setPhotoToCrop(null)
    if (onSelectCropped) {
      onSelectCropped(croppedPhoto)
    }
    handleCloseModal()
  }

  // The user decided not to crop the photo
  const handleDidNotCrop = photo => {
    setPhotoToCrop(null)
    if (onSelect) {
      onSelect(photo)
    }
    handleCloseModal()
  }

  const handleChange = ({ target: { value } }) => setFilter(value)

  const isLoading = dispatchFetchPhotosForSelector.status === 'loading'

  // workaround to InfiniteScroll bug where it doesn't load more pages if the first page doesn't fill the screen
  // see https://github.com/ankeetmaini/react-infinite-scroll-component/issues/309
  useEffect(() => {
    const root = document.getElementById('photo-scroll-container')
    // scrollHeight is the minimum height the element would require in order to fit all the content in the viewport without using a vertical scrollbar.
    if (
      results?.length > 0 &&
      root &&
      !isLoading &&
      next &&
      root.scrollHeight <= root.clientHeight
    ) {
      // if (root.scrollHeight <= root.clientHeight) this means that there is a gap at the bottom in which more data could be loaded
      handleFetchMoreMemoized()
    }
  }, [isLoading, next, handleFetchMoreMemoized, results?.length])

  const { config } = useContext(WriteArticleContext)
  const showTags = config ? config.showTags : true

  return (
    <FlexOverflow
      sx={{
        flexGrow: 1,
        height: 700,
        overflow: 'hidden',
      }}
    >
      <FlexOverflow
        sx={{
          flexShrink: 0,
          overflow: 'visible',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {showTags && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterOptionsMenu {...{ filter, handleChange }} />
              {selected.size > 0 && (
                <Chip
                  label={`${selected.size} selected`}
                  onDelete={handleDeselect}
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
            <Box>
              {showFilterInPopover && (
                <PopoverFilterButton>
                  <Box sx={{ minWidth: 300, p: 2 }}>
                    <TagForm
                      className={classes.tags}
                      onChangeTags={handleChangeTags}
                      presetTargets={presetTargets}
                      tagClassName={classes.tag}
                      alignTreePreview="left"
                    />
                  </Box>
                </PopoverFilterButton>
              )}
            </Box>
          </>
        )}
      </FlexOverflow>
      <FlexOverflow
        sx={{ flexDirection: 'row', flexGrow: 1, overflow: 'hidden' }}
      >
        <div
          id="photo-scroll-container"
          className={classes.photoScrollContainer}
        >
          {isLoading && page === 0 ? (
            <LoadingIndicator />
          ) : results && results.length === 0 ? (
            <Typography className={classes.noMatching} color="textSecondary">
              No matching media
            </Typography>
          ) : (
            <InfiniteScroll
              dataLength={results ? results.length || 0 : 0}
              next={handleFetchMore}
              hasMore={next}
              scrollableTarget="photo-scroll-container"
              style={{ overflowY: 'hidden', overflowX: 'hidden' }}
            >
              <div className={classes.photoContainer}>
                {results?.map((photo, i) => (
                  <Thumbnail
                    className={classes.photo}
                    key={`${photo.id}-${i}`}
                    file={photo.fileThumbnail}
                    type={photo.type}
                    onClick={() => {
                      handleSelect(photo)
                    }}
                    selectable
                    selected={selected.has(photo.id)}
                    size={THUMB_SIZE_SMALL}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
          {dispatchFetchPhotosForSelector.status === 'loading' && (
            <LoadingIndicator />
          )}
        </div>
        {showTags && !showFilterInPopover && (
          <div className={classes.sidebar}>
            <Typography color="textPrimary">Filter:</Typography>
            <TagForm
              className={classes.tags}
              onChangeTags={handleChangeTags}
              presetTargets={presetTargets}
              tagClassName={classes.tag}
              alignTreePreview={alignTreePreview}
            />
          </div>
        )}
      </FlexOverflow>
      <CreateCroppedPhotoDialog
        aspectRatio={aspectRatio}
        photo={photoToCrop}
        onDoneCropping={handleDoneCropping}
        onDidNotCrop={handleDidNotCrop}
        onCroppingCancelled={handleCroppingCancelled}
        submitText="Select"
      />
    </FlexOverflow>
  )
}

const StyledInput = styled(Input)(({ theme }) => ({
  fontWeight: 500,
  position: 'relative',
  top: 1,
  '.MuiSelect-select': {
    padding: theme.spacing(0.5),
  },
}))

const FilterOptionsMenu = ({ handleChange, filter }) => (
  <Stack direction="row" alignItems="center">
    <Typography color="textPrimary">View by</Typography>
    <Select
      onChange={handleChange}
      value={filter}
      variant="standard"
      input={<StyledInput disableUnderline />}
      IconComponent={KeyboardArrowDownIcon}
    >
      {Object.keys(FILTER_OPTIONS).map((label, i) => (
        <MenuItem key={i} value={label}>
          {label}
        </MenuItem>
      ))}
    </Select>
    {[DATE_TAKEN_ASC, DATE_TAKEN_DSC].includes(filter) && (
      <Typography sx={{ ml: 1, fontSize: '0.825rem' }}>
        Showing photos with date taken only
      </Typography>
    )}
  </Stack>
)

const PopoverFilterButton = ({ children }) => {
  const [filterPopoverAnchor, setFilterPopoverVisibleAnchor] = useState(false)
  const handleShowFilterPopover = event =>
    setFilterPopoverVisibleAnchor(event.currentTarget)
  const handleHideFilterPopoper = () => setFilterPopoverVisibleAnchor(null)

  return (
    <>
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        onClick={handleShowFilterPopover}
      >
        Filter
      </Button>
      <Popover
        open={Boolean(filterPopoverAnchor)}
        anchorEl={filterPopoverAnchor}
        onClose={handleHideFilterPopoper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
      >
        {children}
      </Popover>
    </>
  )
}

export default ThumbnailGallery
