import React from 'react'
import { Box, Typography } from '@mui/material'
import { Route, Switch } from 'react-router-dom'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_ARTICLE,
  PATH_SEGMENT_DOCUMENT,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_FACTS,
  PATH_SEGMENT_FANS,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_PHOTO_ALBUM,
  PATH_SEGMENT_RESEARCH,
} from '../app/links'
import { styled } from '@mui/system'
import SiteWideIcon from './SiteWideIcon'

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: 'fit-content',
  textAlign: 'center',
  padding: theme.spacing(4),
  margin: 'auto',
  gap: 4,
  marginTop: theme.spacing(8),
}))

const EmptyStateImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.lightGrey,
  borderRadius: '50%',
  width: 145,
  height: 145,
}))

const EmptyStateWithIcon = ({ instanceType, title, bodyText }) => {
  return (
    <EmptyStateContainer>
      <EmptyStateImageContainer>
        <SiteWideIcon
          instanceType={instanceType}
          fontSize={'large'}
          sx={{ width: 145, height: 145 }}
        />
      </EmptyStateImageContainer>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography>{bodyText}</Typography>
    </EmptyStateContainer>
  )
}

const EmptyFeed = ({ message = 'Nothing added xxyet...' }) => {
  return (
    <>
      <Switch>
        <Route path={`*/${PATH_SEGMENT_FANS}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_INDIVIDUAL}
            title={'No Friends Associates or Neighbors found!'}
            bodyText={
              'FANs are not relations, but people that are associated with this person or archive.'
            }
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_ARTEFACT}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_ARTEFACT}
            title={'No Artifacts found!'}
            bodyText={
              'Artifacts are objects that have a physical link to the past, with memories and stories attached to them. e.g Vase, Pictur, Medal, etc.'
            }
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_EVENT}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_EVENT}
            title={'No Occasions found!'}
            bodyText={
              'Occasions are notable events. e.g Wedding, Graduation, Birthday, etc.'
            }
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_LOCATION}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_LOCATION}
            title={'No Places found!'}
            bodyText={
              'Places are significant locations. e.g Town, House, City, Building etc.'
            }
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_PHOTO_ALBUM}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_PHOTO_ALBUM}
            title={'No Albums found!'}
            bodyText={'Albums are collections of related images'}
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_MEDIA}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_MEDIA}
            title={'No Media items found!'}
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_DOCUMENT}`}>
          <EmptyStateWithIcon
            instanceType={INSTANCE_TYPE_DOCUMENT}
            title={'No Documents Found'}
            bodyText={
              'Documents are records of physical books, letters, etc.  With a image per page'
            }
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_RESEARCH}`}>
          <EmptyStateWithIcon
            instanceType={PATH_SEGMENT_ARTICLE}
            title={'No Articles found!'}
            bodyText={
              'Articles are rich pages where you can add stories and embed trees and images.  e.g biography, history, etc.'
            }
          />
        </Route>
        <Route path={`*/${PATH_SEGMENT_FACTS}`}>
          <EmptyStateWithIcon
            instanceType={PATH_SEGMENT_FACTS}
            title={'No Facts found!'}
            bodyText={
              'Facts are key events in a person’s life. e.g Birth, Death, Marriage, etc.'
            }
          />
        </Route>
        <Route>
          <Box sx={{ mt: 4, mb: 4 }}>
            <Typography color="textSecondary">{message}</Typography>
          </Box>
        </Route>
      </Switch>
    </>
  )
}

export default EmptyFeed
