import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchHomePhotos,
  selectHomePhotos,
  setHomeViewConfig,
} from './homeSlice'
import { MediaList } from 'src/modules/photo'
import { Actions } from './HomeContentList'
import { INSTANCE_TYPE_MEDIA } from '../app/links'
import { getViewConfig } from '../common/viewConfigUtils'

const HomePhotoList = () => {
  const dispatch = useDispatch()
  const dispatchFetchHomePhotos = useActionDispatcher(fetchHomePhotos)
  const media = useSelector(selectHomePhotos)

  useEffect(() => {
    dispatchFetchHomePhotos({ page: 0 })
  }, [dispatchFetchHomePhotos])

  const onFetchMore = () => {
    if (dispatchFetchHomePhotos.status === 'loading') {
      return
    }
    dispatchFetchHomePhotos({ page: media.page + 1 })
  }

  const refreshMedia = () => {
    dispatchFetchHomePhotos({ page: 0 })
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setHomeViewConfig({ type: INSTANCE_TYPE_MEDIA, viewConfig }))
    dispatchFetchHomePhotos({ page: 0 })
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(media)

  return (
    <>
      <Actions
        onSetViewConfig={handleSetViewConfig}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        type={INSTANCE_TYPE_MEDIA}
      />
      <MediaList
        onFetchMore={onFetchMore}
        media={media}
        status={dispatchFetchHomePhotos.status}
        refreshMedia={refreshMedia}
      />
    </>
  )
}

export default HomePhotoList
