import React, { useState } from 'react'
import { Box, Dialog, styled } from '@mui/material'
import { DialogTitle, Typography } from '../ui'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import {
  ALBUMS_TAB,
  generateHomeLink,
  generateLinkForObject,
  generateTreeLink,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_PHOTO_ALBUM,
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_ARTICLE,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_HOME,
  PATH_SEGMENT_INDIVIDUAL,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_RESEARCH,
  PATH_SEGMENT_WRITE_ARTICLE,
} from './links'
import AddMediaDialog from '../photo/AddMediaDialog'
import CreateUpdateLinkedPageDialog from '../page/CreateUpdateLinkedPage'
import {
  selectFamilyByFamilyId,
  selectIndividualById,
} from '../viewer/viewerSlice'
import { isUndefined, template } from 'lodash'
import SiteWideIcon from '../ui/SiteWideIcon'
import SelectFamiliyOrIndidvidualDialog from '../viewer/SelectFamilyOrIndividualDialog'
import {
  fetchLinkedPhotos,
  fetchPageContentAlbums,
  selectLinkedPageItem,
} from '../page/pageSlice'
import { formatIndividualName } from '../ui/individualUtils'
import { fetchHomeContentAlbums, fetchHomePhotos } from '../home/homeSlice'
import { useActionDispatcher, useArticleTemplates } from './hooks'
import CreateAlbumDialog from '../photo/CreateAlbum'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'
import { useAvailableFeatures } from '../auth/hooks'
import { HasAccessTooltip } from '../ui/Button'
import { ACTION_EDIT, ACTION_CREATE } from './appConstants'
import { usePermissions } from '../auth/authHooks'
import { AddIndividualOnlyDialog } from '../viewer/ChooseOrAddIndividual'
import { UpdateIndividualRelationshipsListDialog } from '../viewer/UpdateIndividualRelationships'
import { defaultContentCards } from './appContentConfig'

const AddContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  margin: 'auto',
  maxWidth: '960px',
  padding: theme.spacing(2),
}))

const AddContentGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}))

export const useStyles = makeStyles(theme => ({
  cardText: {
    height: '50%',
    padding: theme.spacing(1),
  },
  cardImage: {
    display: 'block',
    objectFit: 'cover',
    height: '50%',
  },
  cardLink: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  contenCardContainer: {
    height: '200px',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    border: '1px solid #DCDCDC',
    backgroundColor: theme.palette.backgroundGrey.main,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '10px',
    '&:hover': {
      border: '1px solid black',
    },
  },
}))

export const AddContentCardContainer = ({
  title,
  text,
  img,
  color,
  onClick,
}) => {
  const classes = useStyles()
  const canEditTree = useAvailableFeatures()?.tree?.editTree
  return (
    <HasAccessTooltip featureAvailable={canEditTree}>
      <div
        style={{ pointerEvents: !canEditTree && 'none' }}
        className={classes.cardLink}
        onClick={onClick}
      >
        <div className={classes.contenCardContainer}>
          <div className={classes.cardText}>
            <SiteWideIcon
              instanceType={img}
              fontSize={'large'}
              color={color ?? (canEditTree ? 'auto' : 'disabled')}
            />
            <Typography variant="h6" sx={{ textAlign: 'left' }}>
              {title}
            </Typography>
            <div>
              <Typography
                variant="body3"
                color={canEditTree ? 'primary' : 'gray'}
              >
                {text}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </HasAccessTooltip>
  )
}

// used for Article and Article templates
const AddContentCard = props => {
  const {
    title,
    text,
    img,
    color,
    path,
    treeSlug,
    handleCloseModal,
    linkedPageId,
    pageType,
    queryString,
    routerTo,
  } = props

  let { presetTargets } = props

  const to = generateTreeLink(treeSlug, path)

  const history = useHistory()
  const location = useLocation()

  let navPath
  if (linkedPageId) {
    if (location?.pathname?.includes('blog')) {
      navPath = generateTreeLink(treeSlug, `${pageType}/${linkedPageId}`)
    } else {
      navPath = generateTreeLink(
        treeSlug,
        `${pageType}/${linkedPageId}/${PATH_SEGMENT_RESEARCH}`
      )
    }
  } else if (location.pathname.includes(PATH_SEGMENT_HOME) !== -1) {
    navPath = `${generateHomeLink(treeSlug)}/${PATH_SEGMENT_RESEARCH}`
  }

  // remove presets
  if ([PATH_SEGMENT_ARTICLE].includes(pageType)) {
    presetTargets = []
  }

  const onClick = () => {
    sendEvent(ga4Events.ADDCONTENTDIALOG_ADDCONTENT)
    handleCloseModal()
    history.push({
      pathname: to,
      search: queryString,
      state: { presetTargets, navPath },
      ...routerTo,
    })
  }
  return (
    <AddContentCardContainer
      text={text}
      img={img}
      title={title}
      color={color}
      onClick={onClick}
    />
  )
}

const AddIndividualImageCard = props => {
  const classes = useStyles()
  const { title, text, img, handleCloseModal, treeSlug, target } = props

  const individualLink = generateLinkForObject(
    treeSlug,
    INSTANCE_TYPE_INDIVIDUAL,
    target
  )

  const closeModal = () => {
    sendEvent(ga4Events.ADDCONTENTDIALOG_ADDINDIVIDUALIMAGE)
    handleCloseModal()
  }

  return (
    <RouterLink
      className={classes.cardLink}
      onClick={closeModal}
      to={{
        pathname: individualLink,
        state: {
          joyrideName: 'joyride_individual_image_upload_hint',
          joyrideTimeStamp: Date.now(),
        },
      }}
    >
      <AddContentCardContainer text={text} img={img} title={title} />
    </RouterLink>
  )
}

const SelectIndividualImageCard = props => {
  const history = useHistory()
  const { title, text, img, treeSlug, handleCloseModal } = props

  const navigateToPage = individualId => {
    handleCloseModal()
    const link = generateLinkForObject(
      treeSlug,
      INSTANCE_TYPE_INDIVIDUAL,
      individualId
    )
    const state = {
      joyrideName: 'joyride_individual_image_upload_hint',
      joyrideTimeStamp: Date.now(),
    }

    history.push({ pathname: link, state: state })
  }

  return (
    <SelectFamiliyOrIndidvidualDialog
      onSelectIndividual={navigateToPage}
      trigger={props => (
        <AddContentCardContainer
          text={text}
          img={img}
          title={title}
          onClick={props.onClick}
        />
      )}
    />
  )
}

const AddIndividualCard = props => {
  const [selectedIndividualId, setSelectedIndividualId] = useState(null)
  var individual = useSelector(selectIndividualById(selectedIndividualId))

  const { title, text, img } = props

  return (
    <>
      <AddIndividualOnlyDialog
        onIndividualAdded={individual =>
          setSelectedIndividualId(individual?.id)
        }
        trigger={props => (
          <AddContentCardContainer
            text={text}
            img={img}
            title={title}
            onClick={props.onClick}
          />
        )}
      />
      <UpdateIndividualRelationshipsListDialog
        individual={individual}
        onCloseParentDialog={() => setSelectedIndividualId(null)}
      />
    </>
  )
}

const AddLinkedPageCard = props => {
  const history = useHistory()
  const {
    title,
    text,
    img,
    componentType,
    treeSlug,
    handleCloseModal,
    pageType,
  } = props

  let { presetTargets } = props

  const navigateToPage = page => {
    sendEvent(ga4Events.ADDCONTENTDIALOG_LINKED_PAGE)
    handleCloseModal()
    const link = generateLinkForObject(treeSlug, componentType, page.id)
    history.push({ pathname: link, state: { detail: 'some_value' } })
  }

  // remove presets
  if (
    [
      PATH_SEGMENT_ARTEFACT,
      PATH_SEGMENT_ARTICLE,
      PATH_SEGMENT_EVENT,
      PATH_SEGMENT_LOCATION,
    ].includes(pageType)
  ) {
    presetTargets = []
  }

  return (
    <CreateUpdateLinkedPageDialog
      type={componentType}
      onCreate={navigateToPage}
      presetTargets={presetTargets}
      trigger={props => (
        <AddContentCardContainer
          text={text}
          img={img}
          title={title}
          onClick={props.onClick}
        />
      )}
    />
  )
}

const AddMediaCard = props => {
  const dispatchFetchHomePhotos = useActionDispatcher(fetchHomePhotos)
  const dispatchFetchLinkedPhotos = useActionDispatcher(fetchLinkedPhotos)
  const history = useHistory()
  const {
    title,
    text,
    img,
    presetTargets,
    handleCloseModal,
    treeSlug,
    linkedPageId,
    pageType,
  } = props

  const [previewTiles, setPreviewTiles] = useState([])
  const handleFinishUploading = () => {
    sendEvent(ga4Events.ADDCONTENTDIALOG_ADDMEDIA)
    handleCloseModal()
    let navPath

    if (linkedPageId) {
      navPath = generateTreeLink(
        treeSlug,
        `${pageType}/${linkedPageId}/${PATH_SEGMENT_MEDIA}`
      )
      dispatchFetchLinkedPhotos({ target: linkedPageId, page: 0 })
    } else {
      dispatchFetchHomePhotos({ page: 0 })
      navPath = `${generateHomeLink(treeSlug)}/${PATH_SEGMENT_MEDIA}`
    }

    if (navPath) {
      history.push({ pathname: navPath, state: { refresh: true } })
    }
  }

  return (
    <AddMediaDialog
      targets={presetTargets}
      onFinishedUploading={handleFinishUploading}
      {...{ previewTiles, setPreviewTiles }}
      trigger={props => (
        <AddContentCardContainer
          text={text}
          img={img}
          title={title}
          onClick={props.onClick}
        />
      )}
    />
  )
}

const AddMediaAlbumCard = props => {
  const dispatchFetchHomeAlbums = useActionDispatcher(fetchHomeContentAlbums)
  const dispatchFetchLinkedAlbums = useActionDispatcher(fetchPageContentAlbums)
  const history = useHistory()
  const {
    title,
    text,
    img,
    presetTargets,
    handleCloseModal,
    treeSlug,
    linkedPageId,
    pageType,
  } = props

  const handleFinishUploading = () => {
    sendEvent(ga4Events.ADDCONTENTDIALOG_ADDMEDIAALBUM)
    handleCloseModal()
    let navPath

    if (linkedPageId) {
      navPath = generateTreeLink(
        treeSlug,
        `${pageType}/${linkedPageId}/${ALBUMS_TAB}`
      )
      dispatchFetchLinkedAlbums({
        target: linkedPageId,
        page: 0,
        sort: '-published_at',
        type: INSTANCE_TYPE_PHOTO_ALBUM,
      })
    } else {
      dispatchFetchHomeAlbums({
        page: 0,
        sort: '-published_at',
        type: INSTANCE_TYPE_PHOTO_ALBUM,
      })
      navPath = `${generateHomeLink(treeSlug)}/${ALBUMS_TAB}`
    }

    if (navPath) {
      history.push({ pathname: navPath, state: { refresh: true } })
    }
  }

  return (
    <CreateAlbumDialog
      presetTargets={presetTargets}
      onFinishedUploading={handleFinishUploading}
      trigger={props => (
        <AddContentCardContainer
          text={text}
          img={img}
          title={title}
          onClick={props.onClick}
        />
      )}
    />
  )
}

// click this card to show a nested dialog offering
// a grid of templates for the current pageType.
// Not used if there are no templates for the pageType.
const ArticleTemplateSelectionCard = props => {
  const {
    title,
    text,
    img,
    handleCloseModal,
    pageType,
    articleTemplates,
    routerTo,
  } = props

  const cardsForThisPageType = getTemplateCardsForPageType(
    pageType,
    articleTemplates
  )

  return (
    <AddContentDialog
      onSelect={handleCloseModal} // close this parent modal if an item on the child modal is selected
      customCards={cardsForThisPageType}
      dialogTitle="Select template"
      trigger={props => (
        <AddContentCardContainer
          text={text}
          img={img}
          title={title}
          onClick={props.onClick}
        />
      )}
      routerTo={routerTo}
    />
  )
}
ArticleTemplateSelectionCard.overrideTitle = template(
  'create a multimedia article from a template or from scratch'
)

const cardComponentMap = {
  default: AddContentCard,
  media: AddMediaCard,
  linkedPage: AddLinkedPageCard,
  addIndividualImage: AddIndividualImageCard,
  selectIndividualImage: SelectIndividualImageCard,
  addIndividual: AddIndividualCard,
  // would prefer to use a fn here to determine the card depending on whether there are configured
  // template articles for the pageType or not... but doing that would require access to the
  // articleTemplates data, which is only available in a FunctionComponent or hook
  selectArticleTemplate: ArticleTemplateSelectionCard, //delegates to default if there are no templates
  album: AddMediaAlbumCard,
}

const getCardComponent = (
  componentKey,
  cardComponents = cardComponentMap,
  pageType,
  articleTemplates
) => {
  var component = componentKey
    ? cardComponents[componentKey]
    : cardComponentMap['default']

  //if the template card, see if there are >0 templates configured for the pageType
  // - if not just return a simpler card that just creates a blank article
  if (component === ArticleTemplateSelectionCard) {
    pageType = pageType ?? 'articles'
    if (!articleTemplates || !articleTemplates[pageType]?.length) {
      //no templates for the current pageType, use the 'default' card which skips straight
      //to creating a blank article rather than showing an empty grid of templates
      component = cardComponentMap['default']
    }
  }

  return component
}

const getCards = (linkedPageId, pageType) => {
  const dynamicCards = []

  if (pageType === PATH_SEGMENT_INDIVIDUAL) {
    dynamicCards.push({
      title: 'Add Tree Image',
      text: template(
        'add/update image for <%= name %> so they appear in your family tree'
      ),
      img: INSTANCE_TYPE_INDIVIDUAL,
      component: 'addIndividualImage',
      target: linkedPageId,
      permissionAction: ACTION_EDIT,
      permissionParams: { instanceType: INSTANCE_TYPE_INDIVIDUAL },
    })
  } else {
    dynamicCards.push({
      title: 'Add Tree Image',
      text: template(
        'select a person from your tree and upload/update an image'
      ),
      img: INSTANCE_TYPE_INDIVIDUAL,
      component: 'selectIndividualImage',
      permissionAction: ACTION_EDIT,
      permissionParams: { instanceType: INSTANCE_TYPE_INDIVIDUAL },
    })
  }

  return dynamicCards.concat(defaultContentCards)
}

const AddContentTitle = ({
  pageType,
  familyObj,
  individual,
  fetchedLinkedPage,
}) => {
  var contentTitle = ''
  if (familyObj) {
    contentTitle = `to ${familyObj?.surname} Family`
  } else if (individual) {
    contentTitle = `to ${formatIndividualName({ ...individual })}`
  } else if (
    fetchedLinkedPage &&
    [PATH_SEGMENT_ARTICLE, PATH_SEGMENT_ARTEFACT, PATH_SEGMENT_EVENT].includes(
      pageType
    )
  ) {
    contentTitle = `to ${fetchedLinkedPage?.title}`
  } else {
    return null
  }

  return <> {contentTitle}</>
}

const getText = ({
  text,
  pageType,
  familyObj,
  individual,
  fetchedLinkedPage,
}) => {
  var name = ''
  if (familyObj) {
    name = familyObj?.surname
  } else if (individual) {
    name = formatIndividualName({ ...individual })
  } else if (
    fetchedLinkedPage &&
    [PATH_SEGMENT_ARTICLE, PATH_SEGMENT_ARTEFACT, PATH_SEGMENT_EVENT].includes(
      pageType
    )
  ) {
    name = fetchedLinkedPage?.title
  }

  if (typeof text === 'function') {
    return text({ name })
  } else {
    return text || ''
  }
}

// used as:
// - a base starter for all template cards - properties overridden with template configuration
// - a 'start with blank article' card in case user doesn't want to use one of the templates
const templateCardForBlankArticle = {
  title: 'Blank article',
  text: template('create a multimedia article with our super simple editor'),
  path: 'write-article',
  img: INSTANCE_TYPE_ARTICLE,
  color: 'secondary',
  permissionAction: ACTION_CREATE,
  permissionParams: { instanceType: INSTANCE_TYPE_ARTICLE },
}

const getTemplateCardsForPageType = (pageType, articleTemplates) => {
  if (!articleTemplates) {
    return []
  }
  pageType = pageType ?? 'articles'

  const cards = []
    .concat(articleTemplates[pageType])
    .filter(Boolean)
    .map(json => {
      const card = {
        ...templateCardForBlankArticle,
        ...json,
        text: json?.text ? template(json.text) : undefined,
        queryString: json?.id ? `templateId=${json.id}` : undefined,
      }
      return card
    })

  // add the 'start with blank article' card only if there are some templates,
  // otherwise allow an empty array to be returned
  if (cards?.length) {
    cards.unshift(templateCardForBlankArticle)
  }

  return cards
}

const AddContentDialog = ({
  trigger,
  customCards,
  cardComponents,
  onSelect,
  offerArticleTemplates,
  ifNoTemplates,
  dialogTitle,
  routerTo,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const checkPermissions = usePermissions()
  const location = useLocation()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const match = useRouteMatch('/:slug/:pageType/:linkedPageId/')

  const articleTemplates = useArticleTemplates()

  let { pageType, linkedPageId } = match?.params ? match.params : {}

  const familyObj = useSelector(selectFamilyByFamilyId(linkedPageId))
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const individual = useSelector(selectIndividualById(linkedPageId))

  const disabled = location.pathname.indexOf(PATH_SEGMENT_WRITE_ARTICLE) !== -1

  let presetTargets = []
  if (pageType !== PATH_SEGMENT_HOME) {
    presetTargets = linkedPageId ? [linkedPageId] : []
    if (!isUndefined(individual)) {
      presetTargets.push(individual.family)
    }
  } else {
    presetTargets = []
    pageType = null
    linkedPageId = null
  }

  let cards = customCards
  if (!customCards) {
    if (offerArticleTemplates) {
      // this is set when mounting AddContentDialog in a 'research and recollections' page around an 'Add Article' button
      // The calling page doesn't want to know whether templates are available or not so provides a 'ifNoTemplates'
      // component which should be returned if there are no templates for the pageType.

      const templatesForPageType = pageType ?? 'articles'
      cards = getTemplateCardsForPageType(
        templatesForPageType,
        articleTemplates
      )
      if (!cards?.length) {
        if (ifNoTemplates) {
          return ifNoTemplates(trigger())
        }
      }
    } else {
      // default behaviour - offer grid of content types
      cards = getCards(linkedPageId, pageType)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleShowModal = () => {
    sendEvent(ga4Events.ADDCONTENTDIALOG_CLICKED)
    setModalOpen(true)
  }

  const cardsWithPermissions = cards.filter(card => {
    const { hasPermission } = checkPermissions(
      card.permissionAction,
      card.permissionParams
    )
    return hasPermission
  })

  if (cardsWithPermissions.length === 0) {
    return null
  }

  return (
    <>
      {trigger({ onClick: handleShowModal, disabled: disabled })}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <AddContentContainer>
          <DialogTitle onClose={handleCloseModal}>
            {dialogTitle ? (
              <span>{dialogTitle}</span>
            ) : (
              <>
                {offerArticleTemplates ? (
                  <span>Select template</span>
                ) : (
                  <>
                    <span>Add Content</span>
                    <AddContentTitle
                      pageType={pageType}
                      familyObj={familyObj}
                      individual={individual}
                      fetchedLinkedPage={fetchedLinkedPage}
                    />
                  </>
                )}
              </>
            )}
          </DialogTitle>
          <AddContentGrid>
            {cardsWithPermissions.map((props, key) => {
              const CardComponent = getCardComponent(
                props.component,
                cardComponents,
                pageType,
                articleTemplates
              )

              return (
                <CardComponent
                  componentType={props.componentType}
                  presetTargets={presetTargets}
                  handleCloseModal={() => {
                    handleCloseModal()
                    if (onSelect) {
                      onSelect()
                    }
                  }}
                  target={props.targetId}
                  individual={individual}
                  linkedPageId={linkedPageId}
                  pageType={pageType}
                  key={'addContent' + key}
                  {...props}
                  text={getText({
                    text: CardComponent.overrideTitle ?? props.text,
                    pageType,
                    familyObj,
                    individual,
                    fetchedLinkedPage,
                  })}
                  treeSlug={treeSlug}
                  queryString={props.queryString}
                  articleTemplates={articleTemplates}
                  routerTo={routerTo}
                />
              )
            })}
          </AddContentGrid>
        </AddContentContainer>
      </Dialog>
    </>
  )
}

export default AddContentDialog
