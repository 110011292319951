import React from 'react'
import { Box, Stack, Typography, useMediaQuery, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import Clamp from 'react-multiline-clamp'

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import HomeIcon from '@mui/icons-material/Home'

import { SelectMedia, Thumbnail } from 'src/modules/photo'
import { IconButton } from 'src/modules/ui'
import { CreateUpdateLinkedPageDialog } from 'src/modules/page'
import { selectLinkedPageItem } from 'src/modules/page/pageSlice'
import { MEDIA_TYPE_PHOTO } from 'src/modules/photo'
import { THUMB_SIZE_PAGE_TITLE } from '../photo/Thumbnail'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
} from 'src/modules/app/links'

import LinkChips, { SplitIndividualFamilyLinkChips } from '../content/LinkChips'
import EditButton from './EditButton'
import { INSTANCE_TYPE_INFORMATION_REQUEST } from '../app/links'
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import { usePermissions } from '../auth/authHooks'
import { AuthorLink } from '../content/ContentHeaderMeta'
import { ACTION_EDIT } from '../app/appConstants'
import useDetectPrint from 'react-detect-print'

const PositionedIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  background: 'white',
  right: theme.spacing(-0.5),
  bottom: theme.spacing(-0.5),
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'left',
  lineHeight: 1,
  fontWeight: '300',
}))

const UploadPhoto = styled('div')(({ theme }) => ({
  height: 100,
  width: 100,
  backgroundColor: theme.palette.lightGrey.main,
  borderRadius: 70,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  backgroundSize: '100% 100%',
}))

export const ProfilePicture = ({
  photoUrl,
  withEditMedia,
  type,
  permissionAction,
  permissionParams,
  ...props
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))

  const placeholderStyle = {
    backgroundImage: 'url(/person-placeholder.png)',
    backgroundPosition: 'center top',
    cursor: withEditMedia ? 'pointer' : 'auto',
  }
  return (
    <Box
      sx={{
        mr: { xs: 0, md: 3.5 },
        mt: { xs: 1, md: 0 },
        mb: { xs: 1, md: 0 },
      }}
    >
      {photoUrl ? (
        <Thumbnail
          round
          size={THUMB_SIZE_PAGE_TITLE}
          file={photoUrl}
          withEditMedia={withEditMedia}
          {...props}
        />
      ) : (
        <UploadPhoto
          {...props}
          style={type === INSTANCE_TYPE_INDIVIDUAL ? placeholderStyle : null}
        >
          {withEditMedia && (
            <Typography color="#6C636E" fontWeight="bold">
              Add image
            </Typography>
          )}
          {!mobileBreakpoint && withEditMedia && (
            <PositionedIconButton white {...props}>
              <AddPhotoAlternateIcon />
            </PositionedIconButton>
          )}
        </UploadPhoto>
      )}
    </Box>
  )
}

const PageTitle = ({
  backButton,
  description,
  address,
  locations,
  entityTypeTitle,
  extraAction,
  extraLinksForUploadedMedia,
  families,
  handleSelect,
  lastKnownCustodian,
  photoUrl,
  showTags = true,
  subtitle,
  subtitleContainerMb = { xs: 1, md: 0 },
  targetId,
  target = {},
  title,
  type,
  author,
}) => {
  let mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isPrinting = useDetectPrint()
  mobileBreakpoint = isPrinting ? false : mobileBreakpoint // don't use mobile breakpoint when printing

  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const presetTargets = [targetId].concat(extraLinksForUploadedMedia || [])
  const tagsAvailable = fetchedLinkedPage.links?.length > 0 && showTags
  const checkPermissions = usePermissions()

  const { hasPermission } = checkPermissions(ACTION_EDIT, {
    instance: target,
    instanceType: type,
  })

  return (
    <Box>
      <Box>
        <Box sx={{ position: 'relative' }}>
          <Stack direction={mobileBreakpoint ? 'column' : 'row'}>
            {handleSelect && (
              <Stack alignItems="center">
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  {hasPermission ? (
                    <SelectMedia
                      aspectRatio={1}
                      onSelect={handleSelect}
                      onSelectCropped={handleSelect}
                      cropAfterSelect={true}
                      mediaType={MEDIA_TYPE_PHOTO}
                      presetTargets={presetTargets}
                      isProfileImage={type === 'individual'}
                      trigger={props => (
                        <ProfilePicture
                          className={'joyride_individual_image'}
                          {...{
                            photoUrl,
                            withEditMedia: hasPermission,
                            type,
                            ...props,
                            onClear: params => {
                              // call handleSelect with a null photo to clear it
                              handleSelect(null)
                            },
                          }}
                        />
                      )}
                    />
                  ) : (
                    <ProfilePicture
                      className={'joyride_individual_image'}
                      {...{
                        photoUrl,
                        withEditMedia: hasPermission,
                        type,
                        onClear: params => {
                          // call handleSelect with a null photo to clear it
                          handleSelect(null)
                        },
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                  <ProfilePicture
                    {...{
                      photoUrl,
                      withEditMedia: hasPermission,
                    }}
                  />
                </Box>
              </Stack>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <Stack
                direction={mobileBreakpoint ? 'column' : 'row'}
                justifyContent="space-between"
                sx={{ width: '100%', alignItems: 'center' }}
              >
                <Stack alignItems={mobileBreakpoint ? 'center' : 'flex-start'}>
                  {entityTypeTitle && (
                    <Box
                      mb={type === INSTANCE_TYPE_INFORMATION_REQUEST ? 4 : 0}
                      mt={type === INSTANCE_TYPE_INFORMATION_REQUEST ? 2 : 0}
                    >
                      <Subtitle>{entityTypeTitle}</Subtitle>
                    </Box>
                  )}
                  <Title title={title} type={type} />
                  {subtitle || (address && type !== INSTANCE_TYPE_LOCATION) ? (
                    <Box
                      // direction={mobileBreakpoint ? 'column' : 'row'}
                      sx={{
                        display: 'flex',
                        gap: 0.6,
                        alignItems: 'center',
                        flexDirection: mobileBreakpoint ? 'column' : 'row',
                      }}
                    >
                      {subtitle && (
                        <Box
                          sx={{
                            mb: subtitleContainerMb,
                            mt: { xs: 1, md: 0 },
                            pl: 0,
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {type === INSTANCE_TYPE_INDIVIDUAL ? (
                            <PersonIcon />
                          ) : (
                            <EventIcon />
                          )}
                          <Subtitle>{subtitle}</Subtitle>
                        </Box>
                      )}
                      {address && type !== INSTANCE_TYPE_LOCATION && (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 0.6,
                            alignItems: 'center',
                          }}
                        >
                          <HomeIcon />
                          <Subtitle>{address}</Subtitle>
                        </Box>
                      )}
                    </Box>
                  ) : null}
                  {type === INSTANCE_TYPE_LOCATION && address && (
                    <Box
                      sx={{
                        maxWidth: 780,
                        ml: { xs: 4, md: 0 },
                        display: 'flex',
                        gap: 0.6,
                        alignItems: 'center',
                      }}
                    >
                      <HomeIcon />
                      <Subtitle>{address}</Subtitle>
                    </Box>
                  )}
                  {families && (
                    <Box sx={{ mb: { xs: 1, md: 0 } }}>
                      <LinkChips links={families} />
                    </Box>
                  )}
                  {lastKnownCustodian && (
                    <LastKnownCustodian
                      lastKnownCustodian={lastKnownCustodian}
                    />
                  )}
                </Stack>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="column" spacing={1.4}>
                  <Stack direction="row" spacing={1} mr={0.05}>
                    {extraAction}
                    {[
                      INSTANCE_TYPE_EVENT,
                      INSTANCE_TYPE_ARTEFACT,
                      INSTANCE_TYPE_LOCATION,
                    ].includes(type) && (
                      <CreateUpdateLinkedPageDialog
                        type={type}
                        linkedPage={fetchedLinkedPage}
                        trigger={props => (
                          <Box sx={{ display: { xs: 'inline', md: 'block' } }}>
                            <EditButton
                              permissionAction={ACTION_EDIT}
                              permissionParams={{
                                instanceType: type,
                                instance: fetchedLinkedPage,
                              }}
                              fontSize="small"
                              white
                              {...props}
                            />
                          </Box>
                        )}
                      />
                    )}
                  </Stack>
                  {author && (
                    <Box display="flex" justifyContent="right">
                      <Typography>Created by: </Typography>
                      <AuthorLink
                        hidePhoto={true}
                        author={author}
                        pl={0.3}
                        gap={0.3}
                        fontSize="1.375rem"
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
              <Stack>
                {type !== INSTANCE_TYPE_ARTICLE && tagsAvailable && (
                  <SplitIndividualFamilyLinkChips
                    links={fetchedLinkedPage.links}
                    sx={{
                      mt: 1.4,
                      mb: 1,
                      pl: 0.3,
                    }}
                  />
                )}
              </Stack>
            </Box>
          </Stack>

          {
            // commented out ... on 24/8/2022 not displaying this for locations now
            /*{!!description && (*/
          }
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      mt: tagsAvailable ? 1.5 : 2.5,*/}
          {/*      mb: { xs: 0, md: 1.5 },*/}
          {/*      maxWidth: 780,*/}
          {/*      ml: { xs: 4, md: 0 },*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Description description={description} />*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Box>
      </Box>
    </Box>
  )
}

export const Title = ({ title, type }) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <Stack direction="row" alignItems="center">
      <Typography
        variant={`h1${mobileBreakpoint ? '' : 'Left'}`}
        color="primary"
        sx={{
          fontSize:
            type === INSTANCE_TYPE_INFORMATION_REQUEST
              ? { xs: '3rem', md: '3rem' }
              : { xs: '2.6rem', md: '3.5rem' },
          mb: type === INSTANCE_TYPE_ARTICLE ? 2 : 0,
        }}
      >
        {title}
      </Typography>
    </Stack>
  )
}

export const LastKnownCustodian = ({ lastKnownCustodian }) => (
  <Stack direction="row" alignItems="flex-end" gap={1}>
    <HomeIcon />
    <Subtitle>
      {/* <Clamp lines={2}> */}
      {lastKnownCustodian}
      {/* </Clamp> */}
    </Subtitle>
  </Stack>
)

export const Description = ({ description }) => (
  <Typography variant="subtitle3">
    <Clamp lines={2}>{description}</Clamp>
  </Typography>
)

export const Address = ({ address }) => (
  <Typography variant="subtitle3">
    <Clamp lines={2}>{address}</Clamp>
  </Typography>
)

export default PageTitle
