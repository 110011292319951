import { createSlice } from '@reduxjs/toolkit'
import pickBy from 'lodash/pickBy'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import {
  createComment,
  createCommentFulfilled,
  deleteComment,
  deleteCommentFulfilled,
} from 'src/modules/content/contentSlice'
import {
  cropExistingPhoto,
  rotatePhotoLeft,
  rotatePhotoRight,
  revertToOriginal,
} from 'src/modules/photo/photoSlice'
import { DEFAULT_FEED_LENGTH } from '../app/appConstants'
import {
  API_PATH_SEGMENT_FOR_INSTANCE_TYPE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_INDIVIDUAL,
} from '../app/links'
import {
  createLinkedPageItem,
  deleteLinkedPageItem,
  updateLinkedPageItem,
} from '../page/pageSlice'
import {
  createArticle,
  deleteArticle,
  publishNewRevision,
  updateArticle,
} from '../writeArticle/writeArticleSlice'
import { BLOG_TREE, sortTypes } from '../common/constants'
import {
  getViewConfig,
  updateStateWithViewConfigDefaults,
} from '../common/viewConfigUtils'
import { createIndividual, updateIndividual } from '../viewer/viewerSlice'

const SLICE_NAME = 'home'

/*
 The way the sight is setup the page knows about type which is article, document, event etc.  The tabs sort of map on to this
 but we could get more "views" of the data in the furture.  So for now am mapping the type to the state key
 */
const stateKeyMap = {}
stateKeyMap[INSTANCE_TYPE_DOCUMENT] = 'documents'
stateKeyMap[INSTANCE_TYPE_LOCATION] = 'homePagesLocations'
stateKeyMap[INSTANCE_TYPE_ARTEFACT] = 'homePagesArtefacts'
stateKeyMap[INSTANCE_TYPE_EVENT] = 'homePagesEvents'
stateKeyMap[INSTANCE_TYPE_ARTICLE] = 'content'
stateKeyMap[INSTANCE_TYPE_MEDIA] = 'media'
stateKeyMap[INSTANCE_TYPE_PHOTO_ALBUM] = 'albums'
stateKeyMap[INSTANCE_TYPE_INDIVIDUAL] = 'individuals'

const getHomeViewConfigAccessor = (state, type) => {
  const stateKey = stateKeyMap[type]
  if (stateKey) {
    return state.home[stateKey]
  }
  return {}
}

/*
 repeating get me again here .. possbile some fancy redux bits I could do here but I am a bit of a weaner at this stage and
 putting off that research
 */
export const fetchHomePageArticleId = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomePageArticleId`,
  () => api.get('/account/user/me/')
)

export const fetchHomeFeed = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeFeed`,
  arg => {
    const queryStringParameters = {}
    if (arg?.before) {
      queryStringParameters.before = arg.before
    }

    if (arg?.filterByAncestors) {
      queryStringParameters.filterByAncestors = 1
    }
    return api.get(`/history/${getTreeSlugFromStore()}/feed/`, {
      queryStringParameters,
    })
  }
)

const fetchHomePageContentThunk = (
  { page, state, type, block_type, block_limit },
  { getState }
) => {
  const limit = PAGE_SIZE
  const offset = page * limit

  const existingState = getState()

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    getHomeViewConfigAccessor(existingState, type)
  )

  const queryStringParameters = pickBy({
    type,
    offset,
    limit,
    hierarchical,
    ordering: sort,
    ancestral_only: ancestralOnly,
    state,
    block_type,
    block_limit,
  })
  return api.get(`/history/${getTreeSlugFromStore()}/content/`, {
    queryStringParameters,
  })
}

export const fetchHomeContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeContent`,
  fetchHomePageContentThunk
)

export const fetchHomeContentAlbums = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeContentAlbums`,
  fetchHomePageContentThunk
)

export const fetchHomeContentDocuments = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeContentDocuments`,
  fetchHomePageContentThunk
)

const fetchHomePageThunk = (
  { page, type, viewConfigQueryParams },
  { getState }
) => {
  const limit = PAGE_SIZE
  const offset = page * limit

  const existingState = getState()

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    getHomeViewConfigAccessor(existingState, type),
    viewConfigQueryParams
  )

  const queryStringParameters = pickBy({
    offset,
    limit,
    hierarchical,
    ordering: sort,
    ancestral_only: ancestralOnly,
  })

  const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[type]
  return api.get(`/history/${getTreeSlugFromStore()}/${pathSegment}/`, {
    queryStringParameters,
  })
}

export const fetchHomePagesLocations = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomePagesLocations`,
  fetchHomePageThunk
)
export const fetchHomePagesEvents = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomePagesEvents`,
  fetchHomePageThunk
)
export const fetchHomePagesArtefacts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomePagesArtefacts`,
  fetchHomePageThunk
)

export const fetchHomePhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomePhotos`,
  ({ page }, { getState }) => {
    const limit = PHOTO_PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { hierarchical, ancestralOnly, sort } = getViewConfig(
      getHomeViewConfigAccessor(existingState, INSTANCE_TYPE_MEDIA)
    )

    const queryStringParameters = pickBy({
      offset,
      limit,
      hierarchical,
      ordering: sort,
      ancestral_only: ancestralOnly,
    })
    return api.get(`/history/${getTreeSlugFromStore()}/media/`, {
      queryStringParameters,
    })
  }
)

export const fetchHomeIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeIndividuals`,
  ({ page }, { getState }) => {
    const limit = INDIVIDUALS_PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { hierarchical, ancestralOnly, sort } = getViewConfig(
      getHomeViewConfigAccessor(existingState, INSTANCE_TYPE_INDIVIDUAL)
    )

    const { searchTerm } = getHomeViewConfigAccessor(
      existingState,
      INSTANCE_TYPE_INDIVIDUAL
    )

    const queryStringParameters = pickBy({
      search: searchTerm,
      offset,
      limit,
      hierarchical,
      ordering: sort,
      ancestral_only: ancestralOnly,
    })
    return api.get(`/tree/${getTreeSlugFromStore()}/simple-individuals`, {
      queryStringParameters,
    })
  }
)

const fetchHomeBlogPostsThunk = ({
  page,
  state,
  type,
  block_type,
  block_limit,
  treeSlug,
  sort,
}) => {
  const limit = PAGE_SIZE
  const offset = page * limit
  const queryStringParameters = pickBy({
    type,
    offset,
    limit,
    state,
    block_type,
    block_limit,
    sort,
  })

  const slug = treeSlug || getTreeSlugFromStore()

  return api.get(`/history/${slug}/blog-posts/`, {
    queryStringParameters,
  })
}

export const fetchHomeBlogPosts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeBlogPosts`,
  fetchHomeBlogPostsThunk
)

export const fetchDraftHomeBlogPosts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchDraftHomeBlogPosts`,
  fetchHomeBlogPostsThunk
)

const fetchHomeBlogPostThunk = async (
  { id, treeSlug, block_limit },
  { dispatch }
) => {
  const queryStringParameters = pickBy({
    block_limit,
  })

  const slug = treeSlug || getTreeSlugFromStore()

  const article = await api.get(`/history/${slug}/blog-posts/${id}/`, {
    queryStringParameters,
  })

  return article
}

export const fetchHomeBlogPost = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchHomeBlogPost`,
  fetchHomeBlogPostThunk
)

const initialState = {
  feed: {
    before: null,
    hasMore: true,
    results: [],
  },
  homePagesWelcome: {
    loading: true,
    article: null,
    articleId: null,
  },
  homePagesArtefacts: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  homePagesEvents: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  homePagesLocations: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  content: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  albums: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  documents: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  media: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.CREATED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  individuals: {
    page: 0,
    count: 0,
    results: [],
    searchTerm: undefined,
    sort: sortTypes.FULL_NAME_ASC,
    hierarchical: false,
    ancestralOnly: false,
  },
  blogPosts: {
    page: 0,
    count: [],
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  blogDraftPosts: {
    page: 0,
    count: [],
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  homeBlogPost: null,
}

const PAGE_SIZE = 8
const PHOTO_PAGE_SIZE = 10
const INDIVIDUALS_PAGE_SIZE = 20

export const updateStateAfterPhotoAltered = (state, alteredMedia) => {
  state.media.results = state.media.results.map(photo => {
    return photo.id === alteredMedia.id ? alteredMedia : photo
  })
}

export const setHomeViewConfig = createWrappedAsyncThunk(
  `${SLICE_NAME}/setHomeViewConfig`,
  ({ type, viewConfig }) => {
    const key = type
    const view_key = stateKeyMap[key]

    //save the defaults if the user has selected them
    if (viewConfig.defaultSelected) {
      const params = {
        target: viewConfig.target,
        view_key,
        hierarchical: viewConfig.hierarchical,
        sort: viewConfig.sort,
        ancestral_only: viewConfig.ancestralOnly,
        showMap: viewConfig.showMap,
      }

      api.post(`/history/${getTreeSlugFromStore()}/view_config_links/`, {
        body: params,
      })
    }

    return Promise.resolve({ type, viewConfig })
  }
)

export const setHomeViewConfigDefaults = createWrappedAsyncThunk(
  `${SLICE_NAME}/setHomeViewConfigDefaults`,
  ({ treeSlug }, { getState }) => {
    const existingState = getState()
    let tree = existingState.auth.user.trees.find(
      t => t.slug === treeSlug && t.state === 'ROOT'
    )

    if (tree) {
      return Promise.resolve({ tree })
    } else {
      return Promise.resolve()
    }
  }
)

const resetHomeLinkedPageState = (state, initialState) => {
  const results = { page: 0, count: 0, results: [] }

  state.homePagesArtefacts = {
    ...state.homePagesArtefacts,
    ...results,
  }
  state.homePagesLocations = {
    ...state.homePagesLocations,
    ...results,
  }
  state.homePagesEvents = {
    ...state.homePagesEvents,
    ...results,
  }
  state.individuals = {
    ...state.individuals,
    ...initialState.individuals,
    updated: Date.now(),
  }
}

const resetArticlesAndDocuments = (state, payload) => {
  const results = { page: 0, count: 0, results: [] }
  if (payload.type === INSTANCE_TYPE_DOCUMENT) {
    state.documents = { ...state.documents, ...results }
  } else {
    state.content = { ...state.content, ...results }
  }
}

export const homeSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setHomeIndividualsSearchTerm: (state, action) => {
      state.individuals.searchTerm = action.payload
    },
    resetHomePageState: state => initialState,
    setHomePageArticle: (state, action) => {
      if (action) {
        state.homePagesWelcome.article = action.payload
        state.homePagesWelcome.articleId = action.payload?.id
      }
    },
    resetHomePageAlbums: state => {
      state.albums = initialState.albums
    },
  },
  extraReducers: {
    ...handlePaginatedAction(
      fetchHomePagesEvents,
      state => state.homePagesEvents
    ),
    ...handlePaginatedAction(
      fetchHomePagesLocations,
      state => state.homePagesLocations
    ),
    ...handlePaginatedAction(
      fetchHomePagesArtefacts,
      state => state.homePagesArtefacts
    ),
    ...handlePaginatedAction(fetchHomeContent, state => state.content),
    ...handlePaginatedAction(fetchHomeBlogPosts, state => state.blogPosts),
    ...handlePaginatedAction(
      fetchDraftHomeBlogPosts,
      state => state.blogDraftPosts
    ),
    ...handlePaginatedAction(fetchHomeContentAlbums, state => state.albums),
    ...handlePaginatedAction(
      fetchHomeContentDocuments,
      state => state.documents
    ),
    ...handlePaginatedAction(fetchHomePhotos, state => state.media),
    ...handlePaginatedAction(fetchHomeIndividuals, state => state.individuals),
    [fetchHomeFeed.pending]: (state, { meta, payload }) => {
      const before = meta.arg?.before
      if (!before) {
        state.before = null
        state.feed.hasMore = true
      } else {
        state.feed.before = before
      }
    },
    [fetchHomeFeed.fulfilled]: (state, { meta, payload }) => {
      const before = meta.arg?.before
      if (!before) {
        state.feed.results = payload
      } else {
        state.feed.results = state.feed.results.concat(payload)
      }
      if (payload.length < DEFAULT_FEED_LENGTH) {
        state.feed.hasMore = false
      }
    },
    [fetchHomePageArticleId.fulfilled]: (state, { payload }) => {
      const user = payload
      if (user?.lastViewedTree?.treeType === BLOG_TREE) {
        state.homePagesWelcome.articleId = user?.lastViewedTree?.aboutMe?.id
      } else {
        state.homePagesWelcome.articleId =
          user?.lastViewedTree?.pinnedArticle?.id
      }
      state.homePagesWelcome.loading = false
    },
    [fetchHomeBlogPost.pending]: state => {
      state.homeBlogPost = null
    },
    [fetchHomeBlogPost.fulfilled]: (state, { payload }) => {
      state.homeBlogPost = payload
    },
    [createComment.fulfilled]: createCommentFulfilled(
      state => state.feed.results
    ),
    [deleteComment.fulfilled]: deleteCommentFulfilled(
      state => state.feed.results
    ),
    [cropExistingPhoto.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoLeft.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoRight.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [revertToOriginal.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [createArticle.fulfilled]: (state, { payload }) => {
      // clear the state, next time the tab is visited the list of items will be re-fetched
      resetArticlesAndDocuments(state, payload)
    },
    [publishNewRevision.fulfilled]: (state, { payload }) => {
      resetArticlesAndDocuments(state, payload)
    },
    [updateArticle.fulfilled]: (state, { payload }) => {
      resetArticlesAndDocuments(state, payload)
    },
    [deleteArticle.fulfilled]: (state, { payload, meta }) => {
      //neither payload or meta tell us the type of the content that has just been deleted
      state.documents = initialState.documents
      state.content = initialState.content
    },
    [updateLinkedPageItem.fulfilled]: (state, { payload }) => {
      resetHomeLinkedPageState(state, initialState)
    },
    [createLinkedPageItem.fulfilled]: (state, { payload }) => {
      resetHomeLinkedPageState(state, initialState)
    },
    [deleteLinkedPageItem.fulfilled]: (state, { payload }) => {
      resetHomeLinkedPageState(state, initialState)
    },
    [setHomeViewConfigDefaults.fulfilled]: (state, { payload }) => {
      updateStateWithViewConfigDefaults(state, payload.tree?.viewConfigLinks)
    },
    [setHomeViewConfig.fulfilled]: (state, { payload }) => {
      const key = payload?.type
      const stateKey = stateKeyMap[key]

      if (stateKey) {
        state[stateKey].sort = payload?.viewConfig?.sort
        state[stateKey].hierarchical = payload?.viewConfig?.hierarchical
        state[stateKey].ancestralOnly = payload?.viewConfig?.ancestralOnly
        state[stateKey].showMap = payload?.viewConfig?.showMap
        state[stateKey].userHasUpdatedViewConfig = true
      }
    },
    [updateIndividual.fulfilled]: (state, { payload, meta }) => {
      resetHomeLinkedPageState(state, initialState)
    },
    [createIndividual.fulfilled]: (state, { payload }) => {
      resetHomeLinkedPageState(state, initialState)
    },
  },
})

export const {
  setHomeIndividualsSearchTerm,
  resetHomePageState,
  setHomePageArticle,
  resetHomePageAlbums,
} = homeSlice.actions

export const selectHomeFeed = state => state.home.feed
export const selectHomePagesEvents = state => state.home.homePagesEvents
export const selectHomePagesLocations = state => state.home.homePagesLocations
export const selectHomePagesArtefacts = state => state.home.homePagesArtefacts
export const selectHomeContent = state => state.home.content
export const selectHomeBlogPosts = state => state.home.blogPosts
export const selectDraftHomeBlogPosts = state => state.home.blogDraftPosts
export const selectHomeContentAlbums = state => state.home.albums
export const selectHomeContentDocuments = state => state.home.documents
export const selectHomePhotos = state => state.home.media
export const selectHomeIndividuals = state => state.home.individuals
export const selectHomePageArticle = state => state.home.homePagesWelcome

export const selectHomeBlogPost = state => state.home.homeBlogPost

export const selectHomeIndividualsSearchTerm = state =>
  state.home.individuals.searchTerm

export default homeSlice.reducer
