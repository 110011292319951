import { useSelector } from 'react-redux'
import { selectIsBlogTree, selectUser } from './authSlice'

import { useAvailableFeatures } from './hooks'
import { PermissionStatusManager } from './permissionUtils'
import {
  INSTANCE_TYPE_BLOG_POST,
  INSTANCE_TYPE_WELCOME,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_INFORMATION_REQUEST,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_TREE,
  INSTANCE_TYPE_MEDIA_GROUP,
  INSTANCE_TYPE_SOURCE,
  INSTANCE_TYPE_BLOG,
  INSTANCE_TYPE_FACT,
} from '../app/links'
import { useLocation } from 'react-router-dom'
import { getQueryParams } from '../../utils'

// see permission utils on why I am doing this
const instanceTypes = {
  INSTANCE_TYPE_WELCOME: INSTANCE_TYPE_WELCOME,
  INSTANCE_TYPE_BLOG_POST: INSTANCE_TYPE_BLOG_POST,
  INSTANCE_TYPE_ARTICLE: INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT: INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_ARTEFACT: INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT: INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY: INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL: INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_INFORMATION_REQUEST: INSTANCE_TYPE_INFORMATION_REQUEST,
  INSTANCE_TYPE_LOCATION: INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA: INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM: INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_TREE: INSTANCE_TYPE_TREE,
  INSTANCE_TYPE_BLOG: INSTANCE_TYPE_BLOG,
  INSTANCE_TYPE_MEDIA_GROUP: INSTANCE_TYPE_MEDIA_GROUP,
  INSTANCE_TYPE_SOURCE: INSTANCE_TYPE_SOURCE,
  INSTANCE_TYPE_FACT: INSTANCE_TYPE_FACT,
}

export const usePermissions = () => {
  const user = useSelector(selectUser)
  const location = useLocation()
  const userPermissions = user?.currentTree?.userPermissions || []
  const features = useAvailableFeatures()
  const { blogPreview } = getQueryParams(location)
  let isBlogTree = useSelector(selectIsBlogTree)
  isBlogTree = isBlogTree || blogPreview

  const permissionStatusManager = new PermissionStatusManager({
    userId: user?.id,
    userPermissions,
    isBlogTree,
    features,
    quotas: user?.abilities?.quotas,
    instanceTypes,
    user: user,
  })

  return (permissionAction, permissionParams) => {
    return permissionStatusManager.checkPermissionStatus(
      permissionAction,
      permissionParams
    )
  }
}
