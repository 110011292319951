import React, { useCallback, useEffect, useRef, useState } from 'react'
import GeocodeInput from '../services/GeocodeInput'
import { Button, IconButton } from '../ui'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {
  Box,
  Divider,
  Input,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { SortableTile } from '../photo/EditAlbum'
import PlaceIcon from '@mui/icons-material/Place'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import { AddMediaIcon } from '../writeArticle/PhotoWithFloatingControls'
import { MEDIA_TYPE_PHOTO, SelectMedia, Thumbnail } from '../photo'

const useStyles = makeStyles(theme => ({
  mapTitle: {
    padding: 0,
    border: 'none',
    outline: 'none',

    '& .MuiInput-input': {
      color: theme.palette.primary.main,
      ...theme.typography.h2,
      outline: 'none',
      border: 'none',
    },
  },
  markerTitle: {
    padding: 0,
    '& .MuiInput-input': {
      color: theme.palette.primary.main,
      ...theme.typography.h4,
    },
  },
  thumbnailImg: {
    width: '100%',
    height: 'auto',
    maxHeight: 400,
  },
}))

const MarkerInput = ({
  marker,
  handleSelectLocation,
  handleFocusMarker,
  isSelected,
  handleRemoveMarker,
  dragging,
  dragProps,
  markerIndex,
  handleBlurMarker,
}) => {
  const inputContainerRef = useRef()
  useEffect(() => {
    if (isSelected && !marker?.id) {
      const inputs = inputContainerRef?.current?.getElementsByTagName('input')
      inputs[0]?.focus()
    }
  }, [isSelected, marker?.id])

  return (
    <Box
      ref={inputContainerRef}
      onClick={() => handleFocusMarker(markerIndex)}
      onBlur={() => handleBlurMarker(markerIndex)}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        backgroundColor: isSelected ? 'rgba(204,204,204, 0.4)' : 'transparent',
        padding: 0.5,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 4,
        mt: 1,
        minWidth: 360,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <DragIndicatorIcon
          size="small"
          style={{
            cursor: dragging ? 'grabbing' : 'grab',
            outline: 'none',
            opacity: marker?.id ? 1 : 0,
            pointerEvents: marker?.id ? 'auto' : 'none',
          }}
          {...dragProps}
        />
        {marker?.address ? (
          <Typography variant="subtitle1">
            {marker?.title || marker?.address}
          </Typography>
        ) : (
          <GeocodeInput
            value={marker.address}
            handleSelectResult={handleSelectLocation}
            icons={{
              search: '',
            }}
          />
        )}
      </Box>

      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        handleSubmit={e => {
          e.stopPropagation()
          handleRemoveMarker(marker)
        }}
      >
        <RemoveCircleIcon color="primary" size="small" />
      </IconButton>
    </Box>
  )
}

const SortableMarkers = ({
  locations,
  handleRemoveMarker,
  onMarkersUpdated,
  handleSelectLocation,
  handleFocusMarker,
  handleBlurMarker,
  currentMarker,
  setCurrentMarkerIndex,
}) => {
  const [idDragging, setIdDragging] = useState(null)

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(sensor)

  const handleDragEnd = async event => {
    const { active, over } = event
    setIdDragging(null)

    if (active.id !== over.id) {
      const oldIndex = locations.findIndex(({ id }) => id === active.id)
      const newIndex = locations.findIndex(({ id }) => id === over.id)
      setCurrentMarkerIndex(newIndex)
      const newMarkers = arrayMove(locations, oldIndex, newIndex)
      onMarkersUpdated(newMarkers)
    }
  }
  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', ml: 2, width: '100%' }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={({ active }) => {
          setIdDragging(active.id)
        }}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setIdDragging(null)}
      >
        <SortableContext items={locations} strategy={rectSortingStrategy}>
          <Stack
            direction="row"
            sx={{
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {locations?.map((marker, index) => {
              if (marker?.id) {
                return (
                  <SortableTile
                    id={marker.id}
                    key={marker.id}
                    dragging={idDragging === marker.id}
                  >
                    <MarkerInput
                      marker={marker}
                      handleSelectLocation={handleSelectLocation}
                      handleFocusMarker={handleFocusMarker}
                      handleBlurMarker={handleBlurMarker}
                      isSelected={Boolean(currentMarker?.id === marker?.id)}
                      handleRemoveMarker={handleRemoveMarker}
                      dragging={idDragging === marker.id}
                      markerIndex={index}
                    />
                  </SortableTile>
                )
              } else {
                return (
                  <MarkerInput
                    marker={marker}
                    handleSelectLocation={handleSelectLocation}
                    handleFocusMarker={handleFocusMarker}
                    handleBlurMarker={handleBlurMarker}
                    isSelected={Boolean(currentMarker?.id === marker?.id)}
                    handleRemoveMarker={handleRemoveMarker}
                    markerIndex={index}
                  />
                )
              }
            })}
          </Stack>
        </SortableContext>
      </DndContext>
    </Box>
  )
}

const MarkerEditor = ({
  currentMap,
  setLocations,
  locations,
  editableMapTitle,
  setMapTitle,
  currentMarker,
  setCurrentMarkerIndex,
  flyToEditMode,
  dropPinMode,
  setDropPinMode,
}) => {
  const classes = useStyles()

  const handleAddress = (name, formattedPlace) => {
    let firstWord = formattedPlace.split(' ')[0]
    if (firstWord.at(-1) === ',') {
      firstWord = firstWord.substring(0, firstWord.length - 1)
    }

    if (firstWord !== name) {
      return `${name}${formattedPlace ? ', ' + formattedPlace : ''}`
    } else return `${formattedPlace}`
  }
  const updateCurrentMarker = updates => {
    setLocations(prevState => {
      const locationsArr = [...prevState]

      const markerIndex = locationsArr.findIndex(
        item => item.id === currentMarker.id
      )

      locationsArr[markerIndex] = {
        ...locationsArr[markerIndex],
        ...updates,
      }
      return [...locationsArr]
    })
  }

  const handleSelectLocation = location => {
    const properties = location?.properties
    const coords = properties?.coordinates
    let newMarker = {
      lat: coords.latitude,
      lng: coords.longitude,
      address: handleAddress(properties?.name, properties.place_formatted),
    }
    if (!currentMarker?.id) {
      newMarker = {
        ...newMarker,
        id: `SORTABLE-ID-${Math.random()}-${coords.lat}-${
          coords.lon
        }-${Math.random()}`,
        title: properties.name,
        icon: PlaceIcon,
      }
      setLocations(prevState => [
        ...prevState.filter(
          locationItem => locationItem.lat && locationItem.lng
        ),
        newMarker,
      ])
    } else {
      newMarker = {
        ...currentMarker,
        ...newMarker,
        marker: null,
        title: !currentMarker?.title ? properties.name : currentMarker?.title,
      }
      if (currentMarker?.marker) {
        currentMarker.marker.remove()
      }
      updateCurrentMarker(newMarker)
    }
    flyToEditMode(newMarker)
  }

  const handleEditCurrentMarker = e => {
    const value = e.target.value
    const name = e.target.name
    const updates = {
      [name]: value,
    }
    updateCurrentMarker(updates)
  }

  const handleRemoveMarker = marker => {
    if (marker?.marker) {
      marker.marker.remove()
    }
    setLocations(prevState => {
      const newLocations = prevState.filter(
        location => marker.id !== location.id
      )

      return [...newLocations]
    })
    if (currentMarker?.id === marker?.id) {
      setCurrentMarkerIndex(-1)
    }
  }

  const handleAddMarker = () => {
    if (locations?.[locations?.length - 1]?.id || !locations?.length) {
      setLocations(prevState => [...prevState, {}])
    }
    setCurrentMarkerIndex(locations.length)
  }

  const preventTitleEnterPress = useCallback(e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault()
    }
    return e
  }, [])

  useEffect(() => {
    if (!editableMapTitle) {
      setMapTitle(currentMap?.title)
    }
  }, [currentMap, editableMapTitle, setMapTitle])

  const handleFocusMarkerInput = markerIndex => {
    setCurrentMarkerIndex(markerIndex)
    if (locations[markerIndex]?.id) {
      flyToEditMode(locations[markerIndex])
      if (dropPinMode) {
        setDropPinMode(false)
      }
    }
  }

  const handleBlurMarker = markerIndex => {
    if (dropPinMode && !locations[markerIndex]?.id) {
      setDropPinMode(false)
    }
  }

  const handleAddMedia = selectedMedia => {
    const updates = {
      photo: selectedMedia,
    }
    updateCurrentMarker(updates)
  }
  const handleClearMedia = () => {
    const updates = {
      photo: null,
    }
    updateCurrentMarker(updates)
  }

  return (
    <Box p={2}>
      <Input
        multiline
        value={editableMapTitle}
        onChange={e => setMapTitle(e.target.value)}
        placeholder="Add a map title..."
        className={classes.mapTitle}
        onKeyPress={preventTitleEnterPress}
      />
      <Box
        mt={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SortableMarkers
          handleRemoveMarker={handleRemoveMarker}
          locations={locations}
          onMarkersUpdated={setLocations}
          setCurrentMarkerIndex={setCurrentMarkerIndex}
          handleSelectLocation={handleSelectLocation}
          handleFocusMarker={handleFocusMarkerInput}
          currentMarker={currentMarker}
          handleBlurMarker={handleBlurMarker}
        />
        <Box display="flex" sx={{ mt: 1 }}>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            onClick={handleAddMarker}
            variant="text"
            startIcon={<AddCircleIcon fontSize="1rem" />}
          >
            Add place
          </Button>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            onClick={() => setDropPinMode(!dropPinMode)}
            variant="text"
            startIcon={<AddLocationIcon fontSize="1rem" />}
          >
            {dropPinMode ? 'Cancel' : 'Add pin'}
          </Button>
        </Box>
      </Box>
      {currentMarker?.id && (
        <>
          <Divider sx={{ my: 2 }} />
          <Box px={2}>
            <form>
              <Box mb={1}>
                <Typography>Marker Title: </Typography>
                <Input
                  className={classes.markerTitle}
                  name="title"
                  variant="filled"
                  value={currentMarker?.title || ''}
                  placeholder="Add a title..."
                  onChange={handleEditCurrentMarker}
                  fullWidth
                />
              </Box>
              <Typography>Marker Address: </Typography>
              <GeocodeInput
                value={currentMarker.address}
                handleSelectResult={handleSelectLocation}
                placeholder="Seach for an address"
                icons={{
                  search: '',
                }}
              />
              <Box mt={1} sx={{ width: '100%' }}>
                {currentMarker?.photo ? (
                  <SelectMedia
                    aspectRatio={1}
                    onSelect={handleAddMedia}
                    onSelectCropped={handleAddMedia}
                    cropAfterSelect={true}
                    mediaType={MEDIA_TYPE_PHOTO}
                    trigger={props => (
                      <Thumbnail
                        {...{
                          className: classes.thumbnailImg,
                          file: currentMarker?.photo?.fileMedium,
                          withEditMedia: true,
                          ...props,
                          onClear: params => {
                            // call handleSelect with a null photo to clear it
                            handleClearMedia(null)
                          },
                        }}
                      />
                    )}
                  />
                ) : (
                  <AddMediaIcon
                    handleSelect={handleAddMedia}
                    media
                    height={300}
                    width={'100%'}
                    hideTags={true}
                    defaultAllowNoTags={true}
                    mediaType={MEDIA_TYPE_PHOTO}
                    onSelectCropped={handleAddMedia}
                    cropAfterSelect={true}
                  />
                )}
              </Box>

              <Typography mt={2}>Marker Description: </Typography>

              <TextField
                multiline
                variant="outlined"
                name="desc"
                rows={10}
                value={currentMarker?.desc || ''}
                placeholder="Add a description..."
                onChange={handleEditCurrentMarker}
                fullWidth
              />
            </form>
          </Box>
        </>
      )}
    </Box>
  )
}

export default MarkerEditor
