import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import FactsList from '../../page/FactsList'
import PublicIndividualRelationships from '../tree/PublicIndividualRelationships'
import { selectPublicIndividualById } from '../tree/treeSlice'
import { selectPublicPageFacts } from './pageSlice'

export default function PublicFacts() {
  const publicPageFacts = useSelector(selectPublicPageFacts)
  const { linkedPageId } = useParams()

  const individual = useSelector(selectPublicIndividualById(linkedPageId))

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <FactsList
        isPublic={true}
        facts={publicPageFacts}
        individual={individual}
      />
      {individual && <PublicIndividualRelationships individual={individual} />}
    </Box>
  )
}
