import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { INSTANCE_TYPE_LOCATION } from 'src/modules/app/links'
import LinkedPage from 'src/modules/page/LinkedPage'
import {
  getHistoryRoute,
  getPhotosRoute,
  getWelcomeRoute,
  getDocumentsRoute,
  getArtefactsRoute,
  getEventsSubjectRoute,
  getLocationsRoute,
  getPeopleRoute,
} from './Page'
import { useIsAlphaFeatureUser } from '../auth/hooks'

const LocationsPage = () => {
  const { linkedPageId } = useParams()
  const showFeature = useIsAlphaFeatureUser()?.documents

  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const routes = [
    getWelcomeRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
      makeDefault: true,
      localLabelOverride: 'Description',
    }),
    getHistoryRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
    }),
    showFeature &&
      getDocumentsRoute({
        pageType: INSTANCE_TYPE_LOCATION,
        linkedPageId,
        treeSlug,
      }),
    getPhotosRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
    }),
    getEventsSubjectRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
    }),
    getArtefactsRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
    }),
    getLocationsRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
    }),
    getPeopleRoute({
      pageType: INSTANCE_TYPE_LOCATION,
      linkedPageId,
      treeSlug,
    }),
  ].filter(x => x)

  return (
    <LinkedPage
      pageType={INSTANCE_TYPE_LOCATION}
      linkedPageId={linkedPageId}
      routes={routes}
      backButton
    />
  )
}

export default LocationsPage
