import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPageToInitialState,
  selectLinkedPageArticle,
  setLinkedPageArticle,
} from './pageSlice'
import PinnedArticle, {
  PINNED_ARTICLE_DISPLAY_CONFIG,
  PinnedArticleButtons,
} from './PinnedArticle'

import { Box } from '@mui/material'
import {
  fetchFamily,
  selectFamilyByFamilyId,
  selectIndividualById,
} from '../viewer/viewerSlice'
import { isUndefined } from 'lodash'
import { formatFamilyWithDate } from '../ui/familyUtils'
import { INSTANCE_TYPE_FAMILY } from '../app/links'

const LINKED_PINNED_ARTICLE_CONFIG = {
  ...PINNED_ARTICLE_DISPLAY_CONFIG,
  showInLineHeaderAuthor: true,
  showInLineHeaderShare: false,
  showInLineHeaderEdit: true,
  showInLineHeaderAddToBlog: true,
  showInLineHeaderUnPin: true,
}

const FamilyPinnedArticle = ({ pageType }) => {
  const { linkedPageId } = useParams()
  var familyObj = useSelector(selectFamilyByFamilyId(linkedPageId))
  const dispatch = useDispatch()
  const cachedPinnedArticleData = useSelector(selectLinkedPageArticle)

  // For creation of entities linked to individuals, preselect the
  // family as well:
  const targets = [linkedPageId]
  const individual = useSelector(selectIndividualById(linkedPageId))
  if (!isUndefined(individual)) {
    targets.push(individual.family)
  }

  const family = useSelector(selectFamilyByFamilyId(linkedPageId))

  useEffect(() => {
    if (
      !cachedPinnedArticleData.articleId &&
      cachedPinnedArticleData?.loading
    ) {
      dispatch(fetchFamily({ familyId: linkedPageId })).then(response => {
        dispatch(setLinkedPageArticle(response.payload.pinnedArticle))
      })
    }
  }, [
    dispatch,
    cachedPinnedArticleData.articleId,
    cachedPinnedArticleData?.loading,
    linkedPageId,
  ])

  return (
    <Box>
      <PinnedArticle
        cachedPinnedArticleData={cachedPinnedArticleData}
        onArticleLoaded={setLinkedPageArticle}
        config={LINKED_PINNED_ARTICLE_CONFIG}
        pinTarget={linkedPageId}
      >
        <>
          <Box sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
            Add some insights into this special family place. Create an overview
            article that will be pinned to this page.
          </Box>

          <PinnedArticleButtons
            articleTitle={formatFamilyWithDate(family || {})}
            createButtonText={'Create Overview Article'}
            pinButtonText={'Pin an Existing Article'}
            pinTarget={familyObj}
            pinTargetType={INSTANCE_TYPE_FAMILY}
            onArticleChanged={resetPageToInitialState}
            presetTargets={targets}
            onArticleLoaded={setLinkedPageArticle}
          />
        </>
      </PinnedArticle>
    </Box>
  )
}

export default FamilyPinnedArticle
