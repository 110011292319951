import React from 'react'
import { Box } from '@mui/material'

import TextBlock from './TextBlock'
import MediaRowBlock from './MediaRowBlock'
import GalleryBlock from './GalleryBlock'
import SubTreeBlock from './SubTreeBlock'
import { SourceRefLinks } from './SourcesUI'
import { makeStyles } from '@mui/styles'
import {
  blockquoteStyles,
  bodyTextStyles,
  subtitleStyles,
  titleStyles,
} from './Article'
import MapBlock from '../writeArticle/MapBlock'

const useStyles = makeStyles(theme => ({
  body: {
    '& p, blockquote, ol, ul': bodyTextStyles,
    '& h1, h2, h3, blockquote': {
      fontFamily: 'IBM Plex Sans',
    },
    marginBottom: theme.spacing(3),
    '& h4': titleStyles,
    '& h5': { ...subtitleStyles, marginTop: '1rem' },
    '& blockquote': blockquoteStyles,
  },
}))

const blockMap = {
  TEXT: TextBlock,
  MEDIA_ROW: MediaRowBlock,
  SUB_TREE: SubTreeBlock,
  GALLERY: GalleryBlock,
  MAP: MapBlock,
}

const ArticleBody = ({
  contentBlocks,
  isPublic,
  treeSlug,
  contentType,
  enableLinks = true,
  mediaDetailEditDialogTitle,
  mediaDetailShowTranscription,
  mediaDetailOfferEnterTranscription,
  mediaDetailShowTakenOnDate,
  mediaDetailHideTags,
  mediaDetailDefaultAllowNoTags,
}) => {
  const classes = useStyles()
  const allArticlePhotos = contentBlocks.reduce((allPhotos, { media = [] }) => {
    if (!media) media = []
    return [...allPhotos, ...media]
  }, [])

  return (
    <div className={classes.body}>
      {contentBlocks.map((block, idx) => {
        const Block = blockMap[block.type]
        return (
          <Box
            sx={{
              py: 2,
              '@media print': {
                overflowY: 'auto',
                height: 'fit-content',
              },
            }}
            key={idx}
          >
            <Block
              allArticlePhotos={allArticlePhotos}
              isPublic={isPublic}
              treeSlug={treeSlug}
              // multiRow={false}
              // mediaRowShowTitleIfNoCaption={false}
              mediaDetailEditDialogTitle={mediaDetailEditDialogTitle}
              mediaDetailShowTranscription={mediaDetailShowTranscription}
              mediaDetailOfferEnterTranscription={
                mediaDetailOfferEnterTranscription
              }
              mediaDetailShowTakenOnDate={mediaDetailShowTakenOnDate}
              mediaDetailHideTags={mediaDetailHideTags}
              mediaDetailDefaultAllowNoTags={mediaDetailDefaultAllowNoTags}
              contentType={contentType}
              enableLinks={enableLinks}
              {...block}
            />
            <SourceRefLinks sources={block.sources} collatePhotos={false} />
          </Box>
        )
      })}
    </div>
  )
}

export default ArticleBody
