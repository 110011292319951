import { matchPath, useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'

import { format, parseISO } from 'date-fns'
import pickBy from 'lodash/pickBy'
import { BLOG_TREE } from './modules/common/constants'
import { selectCurrentTree } from './modules/auth/authSlice'
import { useSelector } from 'react-redux'

const blog = "url('/gradient-brown.svg')"
const artefact = "url('/gradient-orange.svg')"
const location = "url('/gradient-location.jpg')"
const blue = "url('/gradient-blue.jpg')"
const family = "url('/gradient-family.svg')"
const event = "url('/gradient-red.svg')"
const person = "url('/gradient-person.svg')"

const appBackgroundMatches = [
  { path: '/:slug/blog', backgroundImage: blog },
  { path: '/blog/:slug/articles/:id', backgroundImage: event },
  { path: '/blog/:id', backgroundImage: event },
  { path: '/:slug/families/:id', backgroundImage: family },
  { path: '/:slug/individuals/:id', backgroundImage: person },
  { path: '/:slug/places/:id', backgroundImage: location },
  { path: '/:slug/artefacts/:id', backgroundImage: artefact },
  { path: '/:slug/occasions/:id', backgroundImage: event },
]

const publicBackgroundMatches = [
  { path: '/blog/:slug', backgroundImage: null },
  { path: '/public/:slug/families/:id', backgroundImage: family },
  { path: '/public/:slug/individuals/:id', backgroundImage: person },
  { path: '/public/:slug/places/:id', backgroundImage: location },
  { path: '/public/:slug/artefacts/:id', backgroundImage: artefact },
  { path: '/public/:slug/occasions/:id', backgroundImage: event },
]

const backgroundMatches = [...appBackgroundMatches, ...publicBackgroundMatches]

export const getQueryParams = location => {
  const queryParams = new URLSearchParams(
    location ? location.search : window.location.search
  )
  const treeOverride = queryParams.get('treeOverride')
  const treeTypeOverride = queryParams.get('treeTypeOverride')
  const blogPreview = queryParams.get('blogPreview') === 'true'
  const slideshow = {
    open: Boolean(queryParams.get('slideshow')),
    transition: queryParams.get('transition'),
    slideDuration: queryParams.get('slideDuration'),
    loop: queryParams.get('loop'),
  }

  return pickBy({ treeOverride, treeTypeOverride, blogPreview, slideshow })
}

export const useBackgroundImage = () => {
  const location = useLocation()
  const tree = useSelector(selectCurrentTree)

  const background = useMemo(
    () =>
      backgroundMatches
        .map(bm => (matchPath(location.pathname, bm) ? bm : null))
        .find(m => m),
    [location]
  )

  const { treeTypeOverride } = getQueryParams()

  if (treeTypeOverride === BLOG_TREE || tree?.treeType === BLOG_TREE) {
    return blog
  } else {
    return background ? background.backgroundImage : blue
  }
}

export const formatDate = dateStr => {
  return format(parseISO(dateStr), 'dd MMMM yyyy')
}

export const formatFromToDates = (fromDate, toDate) => {
  const from = new Date(`${fromDate} 00:00:00`)
  const to = new Date(`${toDate} 00:00:00`)
  const fromMonth = from.getMonth()
  const fromYear = from.getYear()
  const toMonth = to.getMonth()
  const toYear = to.getYear()

  if (fromDate && (toDate === fromDate || !toDate)) {
    return `${formatDate(fromDate)}`
  }

  if (fromYear === toYear && fromMonth !== toMonth) {
    return `
    ${format(parseISO(fromDate), 'dd')}
    ${format(parseISO(fromDate), 'MMMM')}
    -
    ${format(parseISO(toDate), 'dd')}
    ${format(parseISO(toDate), 'MMMM')}
    ${format(parseISO(fromDate), 'yyyy')}`
  }
  if (fromYear === toYear && fromMonth === toMonth) {
    return `
    ${format(parseISO(fromDate), 'dd')}
    -
    ${format(parseISO(toDate), 'dd')}
    ${format(parseISO(toDate), 'MMMM')}
    ${format(parseISO(fromDate), 'yyyy')}`
  }
  return `${formatDate(fromDate)} - ${formatDate(toDate)}`
}

/**
 * Make a best effort attempt to state whether the scroll event is
 * caused by the mouse wheel. There's no formal JS API for this so
 * we're reduced to using browser behaviour.
 */
export const scrollEventIsMouseWheel = e => {
  if (e.ctrlKey) {
    // Indicates a pinch to zoom action
    return false
  }
  if (e.deltaX !== 0) {
    // Any lateral movement means this is not mousewheel
    return false
  }
  if (e.deltaY % 1 === 0) {
    // Trackpad scroll gives whole numbers, so this is likely not to be mousewheel
    return false
  }
  return true
}

export const stripHtml = html => {
  let doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export const useContainerDimensions = myRef => {
  const getDimensions = () => {
    if (!myRef.current) {
      return { width: 0, height: 0 }
    }
    return {
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    }
  }

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions(getDimensions())
    }, 100)

    setDimensions(getDimensions())

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef.current])

  return dimensions
}
