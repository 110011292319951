import { useCallback, useEffect, useState, createContext } from 'react'
import { Dialog } from '@mui/material'

import { withOverridableComponent } from 'src/overridable'

import MediaDetail from './MediaDetail'
import { useHistory, useLocation } from 'react-router-dom'

export const EditMediaContext = createContext({})

const MediaNavigatorDialog = ({
  onReportClosed,
  photo,
  treeSlug,
  onRequestNext,
  onRequestPrev,
  canRequestNext,
  canRequestPrev,
  handleDelete,
  refreshMedia,
  isArticle,
  mediaDetailEditDialogTitle,
  mediaDetailShowTranscription,
  mediaDetailOfferEnterTranscription,
  mediaDetailShowTakenOnDate,
  mediaDetailHideTags,
  mediaDetailDefaultAllowNoTags,
  ...props
}) => {
  const open = !!photo
  const [editMediaOpen, setEditMediaOpen] = useState(false)
  const handleShowEditMedia = () => setEditMediaOpen(true)
  const handleCloseEditMedia = () => setEditMediaOpen(false)
  const history = useHistory()
  const location = useLocation()

  const handleCloseModal = useCallback(() => {
    if (onReportClosed) {
      onReportClosed()
    }
  }, [onReportClosed])

  const downHandler = useCallback(
    ({ key }) => {
      if (canRequestPrev && key === 'ArrowLeft') {
        onRequestPrev()
      } else if (canRequestNext && key === 'ArrowRight') {
        onRequestNext()
      }
    },
    [canRequestNext, canRequestPrev, onRequestNext, onRequestPrev]
  )

  useEffect(() => {
    if (open && !editMediaOpen) {
      window.addEventListener('keydown', downHandler)
    } else {
      window.removeEventListener('keydown', downHandler)
    }
    return () => window.removeEventListener('keydown', downHandler)
  }, [open, downHandler, editMediaOpen])

  const handleReportDeleted = instances => {
    handleCloseModal()
    handleDelete(instances)
  }

  useEffect(() => {
    if (open && !location?.state?.prevUrl) {
      history.push({
        pathname: window.location.pathname,
        search: window.location.search,
        state: { prevUrl: window.location.href },
      })
    }

    const unblock = history.block((location, action) => {
      handleCloseModal()
    })

    return () => {
      unblock()
    }
  }, [open, handleCloseModal, history, location?.state?.prevUrl])

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      maxWidth={false}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 0,
        },
        zIndex: 1100, // 'send feedback' and 'ask family' buttons are at 1200
      }}
    >
      {open && (
        <EditMediaContext.Provider
          value={{ editMediaOpen, handleShowEditMedia, handleCloseEditMedia }}
        >
          <MediaDetail
            isArticle={isArticle}
            photoListItem={photo}
            treeSlug={treeSlug}
            reportDeleted={handleReportDeleted}
            canRequestPrev={canRequestPrev}
            canRequestNext={canRequestNext}
            onClose={handleCloseModal}
            onClickPrev={onRequestPrev}
            onClickNext={onRequestNext}
            refreshMedia={refreshMedia}
            editDialogTitle={mediaDetailEditDialogTitle}
            showTranscription={mediaDetailShowTranscription}
            offerEnterTranscription={mediaDetailOfferEnterTranscription}
            showTakenOnDate={mediaDetailShowTakenOnDate}
            hideTags={mediaDetailHideTags}
            defaultAllowNoTags={mediaDetailDefaultAllowNoTags}
            {...props}
          />
        </EditMediaContext.Provider>
      )}
    </Dialog>
  )
}

/**
 * Where we are navigating through a simple list of media (without server
 * calls for paging), we can use this simplified component.
 */
export const SimplePhotoListNavigatorDialog = ({
  initialPhoto,
  treeSlug,
  onClose,
  media,
  handleDelete,
  refreshMedia,
  isArticle,
  mediaDetailEditDialogTitle,
  mediaDetailShowTranscription,
  mediaDetailOfferEnterTranscription,
  mediaDetailShowTakenOnDate,
  mediaDetailHideTags,
  mediaDetailDefaultAllowNoTags,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState(initialPhoto)

  const currentPhotoIndex = media.indexOf(selectedPhoto)
  const lastPhotoIndex = media.length - 1

  const canRequestPrev = currentPhotoIndex > 0
  const canRequestNext = currentPhotoIndex < lastPhotoIndex

  const handleRequestPrevPhoto = () => {
    setSelectedPhoto(media[currentPhotoIndex - 1])
  }

  const handleRequestNextPhoto = () => {
    setSelectedPhoto(media[currentPhotoIndex + 1])
  }

  const handleReportClosed = () => {
    setSelectedPhoto(null)
    if (onClose) {
      onClose()
    }
  }
  return (
    <MediaNavigatorDialog
      isArticle={isArticle}
      photo={selectedPhoto}
      treeSlug={treeSlug}
      onReportClosed={handleReportClosed}
      onRequestNext={handleRequestNextPhoto}
      onRequestPrev={handleRequestPrevPhoto}
      canRequestNext={canRequestNext}
      canRequestPrev={canRequestPrev}
      handleDelete={handleDelete}
      refreshMedia={refreshMedia}
      mediaDetailEditDialogTitle={mediaDetailEditDialogTitle}
      mediaDetailShowTranscription={mediaDetailShowTranscription}
      mediaDetailOfferEnterTranscription={mediaDetailOfferEnterTranscription}
      mediaDetailShowTakenOnDate={mediaDetailShowTakenOnDate}
      mediaDetailHideTags={mediaDetailHideTags}
      mediaDetailDefaultAllowNoTags={mediaDetailDefaultAllowNoTags}
    />
  )
}

export const PhotoListNavigatorDialog = withOverridableComponent(
  SimplePhotoListNavigatorDialog,
  'PhotoListNavigatorDialog'
)

export default MediaNavigatorDialog
