import { createTheme } from '@mui/material/styles'

export const createDefaultThemeParams = () => {
  const defaultColors = {
    darkPurple: '#240048',
    purpleLight: '#89579C',
    pink: '#B029FF',
    grey: '#7D737D',
    darkGrey: '#303030',
    toolbarGrey: '#F1F1F1',
    lightGrey: '#CAC6C9',
    backgroundGrey: '#F7F6F8',
    secondaryGrey: '#ADA5AD',
    profileGrey: '#6C636E',
    purpleShadow: '#FAF8F9',
    fadedPurple: '#E7E2E8',
  }

  const defaultTypography = {
    fontFamily: "'At Hauss Std', sans-serif",
    h1: {
      fontFamily: 'FS Split Serif',
      fontSize: '3.5rem',
      fontWeight: 300,
      textAlign: 'center',
      lineHeight: 1.1,
    },
    h1Left: {
      fontFamily: 'FS Split Serif',
      fontSize: '3.5rem',
      fontWeight: 300,
      textAlign: 'left',
      lineHeight: 1.1,
    },
    h2: {
      fontFamily: 'FS Split Serif',
      fontSize: '2.75rem',
      fontWeight: 300,
      textAlign: 'left',
    },
    h3: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '2.25rem',
      fontWeight: 300,
      color: defaultColors.darkPurple,
    },
    h4: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '1.5rem',
      fontWeight: 400,
      color: defaultColors.darkPurple,
    },
    h5: {
      fontFamily: "'At Hauss Std TRIAL', sans-serif",
      color: defaultColors.grey,
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    h6: {
      fontFamily: "'At Hauss Std', sans-serif",
      color: defaultColors.grey,
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    h7: {
      fontFamily: "'At Hauss Std TRIAL', sans-serif",
      color: defaultColors.grey,
      fontSize: '1.125rem',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '1.125rem',
      fontWeight: 300,
      color: defaultColors.grey,
    },
    subtitle1Bold: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '1.125rem',
      fontWeight: 600,
      color: defaultColors.darkGrey,
      lineHeight: 1.75, //this appears in subtitle1 somehow?
    },
    subtitle2: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '0.9375rem',
      fontWeight: 500,
      color: defaultColors.grey,
    },
    subtitle3: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '1.125rem',
      fontWeight: 400,
      color: defaultColors.profileGrey,
    },
    subtitle4: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '0.9375rem',
      fontWeight: 400,
      color: defaultColors.secondaryGrey,
    },
    subtitle5: {
      fontFamily: "'At Hauss Std', sans-serif",
      color: defaultColors.darkPurple,
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle6: {
      fontFamily: "'At Hauss Std', sans-serif",
      color: defaultColors.darkPurple,
      fontSize: '0.8rem',
      fontWeight: 500,
    },
    hint: {
      fontFamily: "'At Hauss Std', sans-serif",
      color: defaultColors.secondaryGrey,
      fontSize: '0.8375rem',
      fontWeight: 100,
    },
    body1: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '0.9375rem',
      fontWeight: 100,
      color: defaultColors.grey,
    },
    body2: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '0.9375rem',
      fontWeight: 400,
      color: defaultColors.grey,
    },
    body3: {
      fontFamily: "'At Hauss Std', sans-serif",
      fontSize: '0.9375rem',
      fontWeight: 100,
      color: defaultColors.darkPurple,
    },
  }

  const defaultBackgroundImage = ''

  return {
    colors: defaultColors,
    typography: defaultTypography,
    backgroundImage: defaultBackgroundImage,
  }
}

export const themeBuilder = params => {
  const typography = params?.typography

  // tweaked defaul settings for md to be 800 to make printing work
  // twrrible hack to avoid unpicking all the mobile styles + print styles in the articles
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1920,
      },
    },
    headerHeight: 85,
    maxWidth: 1280,
    backgroundImage: params?.backgroundImage,
    palette: {
      primary: { main: params?.colors?.darkPurple },
      lightPurple: { main: params?.colors?.purpleLight },
      secondary: { main: params?.colors?.pink },
      secondaryGrey: { main: params?.colors?.secondaryGrey },
      grey: { main: params?.colors?.grey },
      lightGrey: { main: params?.colors?.lightGrey },
      toolbarGrey: { main: params?.colors?.toolbarGrey },
      backgroundGrey: { main: params?.colors?.backgroundGrey },
      profileGrey: { main: params?.colors?.profileGrey },
      purpleShadow: { main: params?.colors?.purpleShadow },
      fadedPurple: { main: params?.colors?.fadedPurple },
      text: {
        primary: params?.colors?.darkPurple,
        secondary: params?.colors?.profileGrey,
      },
    },
    typography: typography,
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiInput: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: params?.colors?.darkPurple,
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '10px',
          },
        },
      },
    },
  })

  return theme
}

const defaultThemeParams = createDefaultThemeParams()
const theme = themeBuilder(defaultThemeParams)

export default theme
