import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu } from '@mui/material'
import CopyTreeButton from './CopyTreeButton'
import DeleteTreeButton from './DeleteTreeButton'
import DownloadGedcomMediaTreeButton from './DownloadGedcomMediaTreeButton'
import ChangeHomePersonButton from './ChangeHomePersonButton'
import ChangeMyTreeIndividualButton from './ChangeMyTreeIndividualButton'
import ExportTreeButton from './ExportTreeButton'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import DummyMenuButton from './DummyMenuButton'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { selectTreeBySlug } from '../auth/authSlice'
import { useSelector } from 'react-redux'

const ManageTreeMenu = ({
  treeSlug,
  draftTree,
  isCurrentTree,
  manageConfig,
}) => {
  const [anchorEl, setAnchorEl] = useState()
  const userIsAlpha = useIsAlphaFeatureUser()?.export
  const open = anchorEl
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const tree = useSelector(selectTreeBySlug(treeSlug))

  // only show the 'download gedcom images' item if there are some there.
  // the menu item will be disabled if the user doesn't have TREE_SETTINGS permission or doesn't have a paid/VIP sub
  const showDownloadGedcomMedia =
    (tree.remainingDownloadableObjesCount ?? 0) !== 0

  return (
    <div>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {manageConfig.showChangeHomePerson && (
          <ChangeHomePersonButton fromTreeSlug={treeSlug} />
        )}
        {manageConfig.showChangeMyTreeIndividual && (
          <ChangeMyTreeIndividualButton fromTreeSlug={treeSlug} />
        )}
        {showDownloadGedcomMedia && (
          <DownloadGedcomMediaTreeButton
            manageConfig={manageConfig}
            treeSlug={treeSlug}
            tree={tree}
          />
        )}
        {manageConfig.showExport && userIsAlpha && (
          <ExportTreeButton treeSlug={treeSlug} />
        )}
        {manageConfig.showCopy && !draftTree && (
          <CopyTreeButton fromTreeSlug={treeSlug} />
        )}
        {manageConfig.showDelete && !isCurrentTree && (
          <DeleteTreeButton manageConfig={manageConfig} treeSlug={treeSlug} />
        )}
        {manageConfig.showDelete && isCurrentTree && (
          <DummyMenuButton
            title={manageConfig.deletePrompt + ' (disabled for selected)'}
          />
        )}
      </Menu>
    </div>
  )
}

export default ManageTreeMenu
