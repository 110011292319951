import { Box, styled, useMediaQuery } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'

import { EmptyFeed, LoadingIndicator, MasonryCardList } from 'src/modules/ui'
import { PhotoAlbum } from 'src/modules/content/PhotoAlbum'
import { ArticleCard } from 'src/modules/content/ArticleCard'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_INFORMATION_REQUEST,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from 'src/modules/app/links'

const StyledPhotoAlbum = styled(PhotoAlbum)(({ theme }) => ({
  margin: theme.spacing(1),
}))

export const StyledInfiniteScroll = styled(InfiniteScroll, {
  shouldForwardProp: prop => prop !== 'wrapPhotos',
})(({ theme, wrapPhotos }) => ({
  ...(wrapPhotos
    ? {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(-1),
      }
    : { display: 'flex', flexDirection: 'column', alignItems: 'center' }),
}))

const BaseContentList = ({
  actions,
  content,
  instanceTypeMap,
  dispatchFetchContent,
  handleFetchMore,
  loading,
  next,
  results,
  type,
  user,
  urlOverride,
  pinnedContent,
  state,
  treeType,
  PinPostDialog,
  hasPinnedArticle,
  ...props
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))

  const contentMap = {
    [INSTANCE_TYPE_ARTICLE]: ArticleCard,
    [INSTANCE_TYPE_DOCUMENT]: ArticleCard,
    [INSTANCE_TYPE_INFORMATION_REQUEST]: ArticleCard,
    [INSTANCE_TYPE_PHOTO_ALBUM]: StyledPhotoAlbum,
  }

  //this was a defaul in the props but a weird race condition made some of the components undefined.  moved in here
  //to force the contentMap to be chipper - RGS 2/9/2022
  instanceTypeMap = instanceTypeMap ? instanceTypeMap : contentMap

  // the user's My Articles list also includes documents so type is an array
  // which won't match the contentMap, and the only component that can handle
  // a page with multiple types on it is ArticleCard.
  const ComponentForType = Array.isArray(type)
    ? ArticleCard
    : instanceTypeMap[type]

  const modifiedResults =
    pinnedContent && state === 'PUBLISHED'
      ? [pinnedContent, ...results]
      : results

  return (
    <Box>
      {actions}
      {(loading && content.page === 0 && modifiedResults.length === 0) ||
      (hasPinnedArticle && !pinnedContent) ? (
        <LoadingIndicator />
      ) : modifiedResults.length === 0 && !loading ? (
        <EmptyFeed />
      ) : (
        <StyledInfiniteScroll
          dataLength={modifiedResults.length}
          next={handleFetchMore}
          hasMore={next}
          wrapPhotos={type === INSTANCE_TYPE_PHOTO_ALBUM && !mobileBreakpoint}
        >
          {mobileBreakpoint && type !== INSTANCE_TYPE_PHOTO_ALBUM ? (
            <MasonryCardList
              contentData={modifiedResults}
              user={user}
              urlOverride={urlOverride}
            />
          ) : (
            modifiedResults.map((content, i) => (
              <ComponentForType
                key={content.id}
                urlOverride={urlOverride}
                content={content}
                treeType={treeType}
                PinPostDialog={PinPostDialog}
                {...{
                  user,
                  disableUp: 0 === i,
                  disableDown: modifiedResults.length - 1 === i,
                  ...props,
                }}
              />
            ))
          )}
        </StyledInfiniteScroll>
      )}
      {dispatchFetchContent.status === 'loading' && content.page > 0 && (
        <LoadingIndicator />
      )}
    </Box>
  )
}

export default BaseContentList
