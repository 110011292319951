import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Tabs } from 'src/modules/ui'
import PublicPageTitle from './PublicPageTitle'
import PublicResearch from './PublicResearch'
import PublicDocuments from './PublicDocuments'
import PublicPhotos from './PublicPhotos'
import PublicFeed from './PublicFeed'
import PublicPageList from './PublicPageList'
import PublicTree from './PublicTree'
import {
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_DOCUMENT,
  PATH_SEGMENT_FACTS,
} from 'src/modules/app/links'
import PublicPinnedArticle from './PublicPinnedArticle'
import {
  fetchPublicPageArtefacts,
  fetchPublicPageContent,
  fetchPublicPageDocuments,
  fetchPublicPageEvents,
  fetchPublicPageFacts,
  fetchPublicPageIndividuals,
  fetchPublicPageLocations,
  fetchPublicPagePhotos,
  selectPublicContent,
  selectPublicDocuments,
  selectPublicPageArtefacts,
  selectPublicPageEvents,
  selectPublicPageFacts,
  selectPublicPageIndividuals,
  selectPublicPageLocations,
  selectPublicPhotos,
} from './pageSlice'
import { useActionDispatcher } from '../../app'
import { useSelector } from 'react-redux'
import { PublicContext } from '../contexts'
import { titleCase } from 'title-case'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import {
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  PATH_SEGMENT_FANS,
  PATH_SEGMENT_PEOPLE,
} from '../../app/links'
// import { TEST_FACTS } from '../../page/Facts'
import PublicFacts from './PublicFacts'
import PublicPageIndividualList from './PublicPageIndividualList'
import { useViewConfigQueryParams } from '../../common/viewConfigUtils'

export const routeComponentMap = {
  [PATH_SEGMENT_FACTS]: PublicFacts,
  research: PublicResearch,
  documents: PublicDocuments,
  [PATH_SEGMENT_MEDIA]: PublicPhotos,
  feed: PublicFeed,
  [PATH_SEGMENT_EVENT]: PublicPageList,
  [PATH_SEGMENT_ARTEFACT]: PublicPageList,
  [PATH_SEGMENT_LOCATION]: PublicPageList,
  [PATH_SEGMENT_PEOPLE]: PublicPageIndividualList,
  [PATH_SEGMENT_FANS]: PublicPageIndividualList,
  tree: PublicTree,
  welcome: PublicPinnedArticle,
}

const PublicPage = ({
  routes,
  linkedPageId,
  pageType,
  publicTreeRoute,
  ...props
}) => {
  const viewConfigQueryParams = useViewConfigQueryParams()
  const [pageRoutes, setPageRoutes] = useState(routes)
  const { treeSlug } = useContext(PublicContext)
  const history = useHistory()
  const location = useLocation()
  const { url } = useRouteMatch()
  const latestNetworkReqs = useRef([])

  const dispatchFetchPublicPageFacts = useActionDispatcher(fetchPublicPageFacts)
  const dispatchFetchPublicPageArtefacts = useActionDispatcher(
    fetchPublicPageArtefacts
  )
  const dispatchFetchPublicPageEvents = useActionDispatcher(
    fetchPublicPageEvents
  )
  const dispatchFetchPublicPageLocations = useActionDispatcher(
    fetchPublicPageLocations
  )
  const dispatchFetchPublicPageContent = useActionDispatcher(
    fetchPublicPageContent
  )
  const dispatchFetchPublicPageDocuments = useActionDispatcher(
    fetchPublicPageDocuments
  )
  const dispatchFetchPublicPagePhotos = useActionDispatcher(
    fetchPublicPagePhotos
  )

  const dispatchFetchPublicPageIndividuals = useActionDispatcher(
    fetchPublicPageIndividuals
  )

  const publicPageArtefacts = useSelector(selectPublicPageArtefacts)
  // const publicPageFacts = TEST_FACTS
  const publicPageFacts = useSelector(selectPublicPageFacts)
  const publicPageEvents = useSelector(selectPublicPageEvents)
  const publicPageLocations = useSelector(selectPublicPageLocations)
  const publicPageArticles = useSelector(selectPublicContent)
  const publicPageDocuments = useSelector(selectPublicDocuments)
  const publicPageIndividuals = useSelector(selectPublicPageIndividuals)

  const media = useSelector(selectPublicPhotos)

  const handleDisabledRoutes = useCallback(() => {
    const articlesCount = publicPageArticles.count
    const factsCount = publicPageFacts.length
    const documentsCount = publicPageDocuments.count
    const locationsCount = publicPageLocations.count
    const eventsCount = publicPageEvents.count
    const artefactsCount = publicPageArtefacts.count
    const mediaCount = media.count
    const individualsCount = publicPageIndividuals.count

    let publicPageRoutes = routes.map(route => {
      if (
        route.navLabel === 'Research and recollections' &&
        articlesCount > 0
      ) {
        return { ...route, disabled: false, makeDefault: true }
      } else if (
        route.navLabel === titleCase(PATH_SEGMENT_DOCUMENT) &&
        documentsCount > 0
      ) {
        return { ...route, disabled: false }
      } else if (
        route.navLabel === titleCase(PATH_SEGMENT_FACTS) &&
        factsCount > 0
      ) {
        return { ...route, disabled: false }
      } else if (
        route.navLabel === titleCase(PATH_SEGMENT_MEDIA) &&
        mediaCount > 0
      ) {
        return { ...route, disabled: false }
      } else if (
        route.navLabel === titleCase(PATH_SEGMENT_EVENT) &&
        eventsCount > 0
      ) {
        return { ...route, disabled: false }
      } else if (
        route.navLabel === titleCase(PATH_SEGMENT_ARTEFACT) &&
        artefactsCount > 0
      ) {
        return { ...route, disabled: false }
      } else if (
        route.navLabel === titleCase(PATH_SEGMENT_LOCATION) &&
        locationsCount > 0
      ) {
        return { ...route, disabled: false }
      } else if (route.navLabel === 'People' && individualsCount > 0) {
        return { ...route, disabled: false }
      } else if (route.navLabel === 'FANs' && individualsCount > 0) {
        return { ...route, disabled: false }
      } else return route
    })

    if (
      pageType !== INSTANCE_TYPE_FAMILY &&
      pageType !== INSTANCE_TYPE_INDIVIDUAL
    ) {
      if (
        !viewConfigQueryParams.hierarchical &&
        publicPageRoutes[0].disabled &&
        publicPageRoutes[1].disabled &&
        location.pathname.substring(location.pathname.length - 5) !== 'media' &&
        location.pathname.substring(location.pathname.length - 6) !==
          'albums' &&
        media.count > 0 &&
        publicPageArticles.count === 0
      ) {
        // redirect to media tab if no description + no article
        const subroot =
          url.substring(url.length - 1) === '/' ? 'media' : '/media'
        history.replace(`${url}${subroot}`)
      }
    }

    setPageRoutes(publicPageRoutes)
  }, [
    pageType,
    routes,
    history,
    location.pathname,
    url,
    publicPageArticles.count,
    publicPageFacts.length,
    publicPageDocuments.count,
    publicPageLocations.count,
    publicPageEvents.count,
    publicPageArtefacts.count,
    media.count,
    publicPageIndividuals.count,
    viewConfigQueryParams.hierarchical,
  ])

  useEffect(() => {
    handleDisabledRoutes()
  }, [
    publicPageArtefacts,
    publicPageFacts,
    publicPageEvents,
    publicPageLocations,
    publicPageArticles,
    publicPageDocuments,
    media,
    handleDisabledRoutes,
  ])

  const makeArgs = useCallback(
    (page, tabPageType) => ({
      page,
      pageType: tabPageType,
      target: linkedPageId,
      treeSlug,
      viewConfigQueryParams,
    }),
    [linkedPageId, treeSlug, viewConfigQueryParams]
  )

  useEffect(() => {
    const fetchPublicPages = async () => {
      latestNetworkReqs.current.push(
        dispatchFetchPublicPageContent(makeArgs(0, 'article'))
      )
      latestNetworkReqs.current.push(
        dispatchFetchPublicPageDocuments(makeArgs(0, 'document'))
      )
      latestNetworkReqs.current.push(
        dispatchFetchPublicPagePhotos(makeArgs(0, 'media'))
      )
      latestNetworkReqs.current.push(
        dispatchFetchPublicPageIndividuals(makeArgs(0, 'individual'))
      )
      if (
        pageType === INSTANCE_TYPE_FAMILY ||
        pageType === INSTANCE_TYPE_INDIVIDUAL
      ) {
        latestNetworkReqs.current.push(
          dispatchFetchPublicPageEvents(makeArgs(0, 'event'))
        )
        latestNetworkReqs.current.push(
          dispatchFetchPublicPageArtefacts(makeArgs(0, 'artefact'))
        )
        latestNetworkReqs.current.push(
          dispatchFetchPublicPageLocations(makeArgs(0, 'location'))
        )
        if (pageType === INSTANCE_TYPE_INDIVIDUAL) {
          latestNetworkReqs.current.push(
            dispatchFetchPublicPageFacts({ target: linkedPageId, treeSlug })
          )
        }
      }
    }
    fetchPublicPages()

    const reqsToAbort = latestNetworkReqs.current
    return () => {
      reqsToAbort.forEach(req => {
        req?.abort()
      })
    }
  }, [
    location,
    makeArgs,
    pageType,
    linkedPageId,
    treeSlug,
    dispatchFetchPublicPageContent,
    dispatchFetchPublicPageDocuments,
    dispatchFetchPublicPagePhotos,
    dispatchFetchPublicPageFacts,
    dispatchFetchPublicPageEvents,
    dispatchFetchPublicPageArtefacts,
    dispatchFetchPublicPageLocations,
    dispatchFetchPublicPageIndividuals,
  ])

  return (
    <Tabs
      disableSearch
      routes={pageRoutes}
      fullWidthSubRootContainer={true}
      isPublic={true}
      title={
        <PublicPageTitle
          {...{
            ...props,
            pageType,
            treeSlug,
          }}
        />
      }
    />
  )
}

export default PublicPage
