import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useState, useContext, useCallback, useRef, useEffect } from 'react'

import { Formik } from 'formik'

import { Box, styled } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import {
  Button,
  ConfirmDialog,
  IconButton,
  FormikTextField,
  Typography,
} from 'src/modules/ui'

import TagForm from '../writeArticle/TagForm'
import { deletePhoto, getPhotoInstances, updatePhoto } from './photoSlice'
import { EditMediaContext } from './MediaNavigator'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'
import { handleImgName } from './MediaDropzone'

import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import { selectIsBlogTree } from '../auth/authSlice'
import { useSelector } from 'react-redux'
import { ACTION_ALL_ACCESS, ACTION_DELETE } from '../app/appConstants'
import { INSTANCE_TYPE_MEDIA } from '../app/links'

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 2, 2),
  },
}))

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(3, 3, 0),
}))

const PhotoInstanceList = ({ photoInstances }) => {
  const instances = photoInstances.map(instance => (
    <Typography>{instance}</Typography>
  ))

  return (
    <Box my={1} sx={{ paddingLeft: 1 }}>
      {instances}
    </Box>
  )
}

const EditMedia = ({
  trigger,
  photo,
  onFinishedUpdating,
  onFinishedDeleting,
  dialogTitle = 'Edit Photo',
  showTakenOnDate = true,
  hideTags = false,
  defaultAllowNoTags = false,
}) => {
  const { editMediaOpen, handleShowEditMedia, handleCloseEditMedia } =
    useContext(EditMediaContext)
  const { dateTakenGed, links } = photo
  const classes = useStyles()
  const dispatchDeletePhoto = useActionDispatcher(deletePhoto)
  const dispatchGetPhotoInstances = useActionDispatcher(getPhotoInstances)
  const dispatchUpdatePhoto = useActionDispatcher(updatePhoto)
  const isBlogTree = useSelector(selectIsBlogTree)

  const presetTargets = [
    ...(links.length > 0 ? links.map(({ target }) => target) : []),
  ]
  const [targets, setTargets] = useState([...presetTargets])

  const [photoInstances, setPhotoInstances] = useState([])

  const initialValues = {
    dateTakenGed: dateTakenGed,
    description: photo.description,
    title: handleImgName(photo.title),
  }

  const handleDeletePhoto = async () => {
    try {
      await dispatchDeletePhoto(
        { mediaId: photo.id },
        { successNotification: 'Media deleted' }
      ).unwrap()
      handleCloseEditMedia()
      if (onFinishedDeleting) {
        onFinishedDeleting(photoInstances)
      }
    } catch (err) {}
  }

  const getInstances = useCallback(async () => {
    try {
      const photos = await dispatchGetPhotoInstances({
        mediaId: photo.id,
      })
      setPhotoInstances(photos.payload)
    } catch (err) {
      console.log(err)
    }
  }, [dispatchGetPhotoInstances, photo.id])

  useEffect(() => {
    getInstances()
  }, [getInstances])

  const noTagsDialogRef = useRef()

  const handleUpdatePhoto = async (
    { dateTakenGed, description, title },
    { setErrors, resetForm },
    allowNoTags = defaultAllowNoTags
  ) => {
    if (!isBlogTree && !allowNoTags && targets.length === 0) {
      noTagsDialogRef.current.showDialog(async () => {
        //call this function again but with allowNoTags=true
        handleUpdatePhoto(
          { dateTakenGed, description, title },
          { setErrors, resetForm },
          (allowNoTags = true)
        )
      })
      return
    }
    try {
      await dispatchUpdatePhoto({
        mediaId: photo.id,
        dateTakenGed,
        description,
        targets,
        title: title,
      }).unwrap()
    } catch (err) {
      console.log(err)
      setErrors(err.data)
      return
    }
    resetForm()
    handleCloseEditMedia()
    if (onFinishedUpdating) {
      onFinishedUpdating()
    }
  }

  function DraggablePaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    )
  }

  const handleChangeTags = tags => {
    setTargets(tags)
  }

  return (
    <>
      {trigger({ onClick: handleShowEditMedia })}
      <Dialog
        open={editMediaOpen}
        onClose={handleCloseEditMedia}
        maxWidth={false}
        PaperComponent={DraggablePaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent sx={{ minWidth: '500px' }}>
          <NoTagsConfirmationDialog ref={noTagsDialogRef} />
          <Title id="draggable-dialog-title">
            <Typography variant="h7">{dialogTitle}</Typography>
            <Box sx={{ mr: -1, ml: 'auto' }}>
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                onClick={handleCloseEditMedia}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Title>
          <Formik
            initialValues={initialValues}
            onSubmit={handleUpdatePhoto}
            enableReinitialize={false}
          >
            {({ handleSubmit, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Box sx={{ mr: 3, ml: 3 }}>
                    <FormikTextField
                      fullWidth
                      label="Title"
                      name="title"
                      placeholder={`Write a title...`}
                    />
                    <FormikTextField
                      fullWidth
                      multiline
                      label="Notes"
                      name="description"
                      placeholder={`Add some notes...`}
                    />
                    {showTakenOnDate && (
                      <FormikGedDatePicker
                        fullWidth
                        label="Taken On"
                        margin="normal"
                        name="dateTakenGed"
                        setFieldValue={setFieldValue}
                        mt={1}
                      />
                    )}
                    {!hideTags && (
                      <>
                        <Typography sx={{ mb: 1, mt: 1 }}>
                          Add to media section of:
                        </Typography>
                        <TagForm
                          onChangeTags={handleChangeTags}
                          presetTargets={targets}
                          links={links}
                        />
                      </>
                    )}
                  </Box>
                  <DialogActions className={classes.buttons}>
                    <ConfirmDialog
                      onConfirm={handleDeletePhoto}
                      trigger={props => (
                        <Button
                          permissionAction={ACTION_DELETE}
                          permissionParams={{
                            instanceType: INSTANCE_TYPE_MEDIA,
                            instance: photo,
                          }}
                          {...props}
                        >
                          Delete
                        </Button>
                      )}
                    >
                      {photoInstances.length ? (
                        <div>
                          <Typography variant="subtitle2">
                            This photo or media is currently used elsewhere in
                            your site:
                          </Typography>
                          <PhotoInstanceList photoInstances={photoInstances} />
                          <Typography variant="subtitle2">
                            Deleting this photo or media will delete it
                            everywhere listed above, are you sure you want to
                            continue?
                          </Typography>
                        </div>
                      ) : (
                        <Typography>Delete this photo or media?</Typography>
                      )}
                    </ConfirmDialog>
                    <Button
                      permissionAction={ACTION_DELETE}
                      permissionParams={{
                        instanceType: INSTANCE_TYPE_MEDIA,
                        instance: photo,
                      }}
                      isLoading={isSubmitting}
                      onClick={() => handleSubmit()}
                      color="primary"
                    >
                      Update
                    </Button>
                  </DialogActions>
                </>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default EditMedia
