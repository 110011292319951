import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom'
import qs from 'qs'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { ComponentOverrideContext } from 'src/overridable'

import PublicNavBar from './PublicNavBar'
import PublicArticle from './content/PublicArticle'
import PublicContentPhotos from './content/PublicContentPhotos'
import PublicArticlePhoto from './content/PublicArticlePhoto'
import PublicMediaDetail from './photo/PublicMediaDetail'
import PublicMediaPage from './photo/PublicMediaPage'
import PublicHomePage from './home/PublicHomePage'
import BasePublicPage from './page/BasePublicPage'
import PublicIndividualsPage from './page/PublicIndividualsPage'
import PublicFamiliesPage from './page/PublicFamiliesPage'
import PublicAlbumPage from './photo/PublicAlbumPage'
import PublicSubTreeBlock from './content/PublicSubTreeBlock'
import PublicPhotoNavigator from './photo/PublicPhotoNavigator'
import PublicTreeExplorer from './tree/PublicTreeExplorer'
import PublicAncestralFamiliesContainer from './tree/PublicAncestralFamiliesContainer'
import PublicHomePyramid from './home/PublicHomePyramid'

import {
  fetchFamilies,
  fetchIndividuals,
  fetchIndividualsForPyramid,
  fetchIndividualsForShareby,
  fetchTree,
  selectSharedByIndividual,
} from './tree/treeSlice'
import {
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_ARTICLE,
  PATH_SEGMENT_DOCUMENT,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_FAMILY,
  PATH_SEGMENT_FANS,
  PATH_SEGMENT_INDIVIDUAL,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_PHOTO_ALBUM,
  PUBLIC_ROOT,
} from '../app/links'
import { PublicContext } from './contexts'
import Footer from '../app/Footer'
import { useEmbed } from './hooks'
import PublicSubTreeDemo from './home/PublicSubTreeDemo'
import { useDispatch, useSelector } from 'react-redux'
import PublicMapBlock from './content/PublicMapBlock'

export const Content = styled(Box)(({ theme }) => ({
  paddingTop: theme.headerHeight,
  height: '100%',
}))

const Public = () => {
  const {
    url,
    params: { treeSlug },
  } = useRouteMatch(`/${PUBLIC_ROOT}/:treeSlug/`)
  const embed = useEmbed()
  const dispatch = useDispatch()
  const dispatchFetchIndividuals = useActionDispatcher(fetchIndividuals)
  const dispatchFetchFamilies = useActionDispatcher(fetchFamilies)
  const dispatchFetchTree = useActionDispatcher(fetchTree)
  const dispatchFetchIndividualsForSharedBy = useActionDispatcher(
    fetchIndividualsForShareby
  )
  const dispatchFetchIndividualsForPyramid = useActionDispatcher(
    fetchIndividualsForPyramid
  )
  const sharedByIndividual = useSelector(selectSharedByIndividual)

  const history = useHistory()
  const location = useLocation()
  const querystring = qs.parse(location.search.substring(1))
  const sharedById = querystring.sharedBy

  // Fetch tree individuals and families whenever treeSlug changes
  useEffect(() => {
    const fetchTreeData = async () => {
      // Try the quicker request first. If it 404s, redirect
      try {
        await dispatchFetchTree({ treeSlug }).unwrap()
      } catch (err) {
        if (err.status === 404) {
          history.push('/')
          return
        }
      }
      dispatchFetchIndividualsForSharedBy({ treeSlug, target: sharedById })
    }
    fetchTreeData()
  }, [
    dispatchFetchIndividualsForSharedBy,
    dispatchFetchTree,
    history,
    sharedById,
    treeSlug,
    dispatchFetchFamilies,
    dispatchFetchIndividuals,
    dispatch,
  ])

  useEffect(() => {
    if (sharedByIndividual) {
      dispatchFetchIndividualsForPyramid({
        treeSlug,
        target: sharedByIndividual.id,
      })
    }
  }, [treeSlug, dispatchFetchIndividualsForPyramid, sharedByIndividual])

  const loading = dispatchFetchIndividualsForSharedBy.status !== 'fulfilled'

  const treeLoading = dispatchFetchIndividualsForSharedBy.status !== 'fulfilled'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <ComponentOverrideContext.Provider
      value={{
        MediaDetail: PublicMediaDetail,
        ContentPhotos: PublicContentPhotos,
        ArticlePhoto: PublicArticlePhoto,
        MapBlock: PublicMapBlock,
        SubTreeBlock: PublicSubTreeBlock,
        PhotoListNavigatorDialog: PublicPhotoNavigator,
        AncestralFamiliesContainer: PublicAncestralFamiliesContainer,
        NavigatorViewerWithPyramid: PublicTreeExplorer,
      }}
    >
      <PublicContext.Provider value={{ treeSlug, loading, treeLoading }}>
        <PublicNavBar baseUrl={url} />
        <Content style={{ paddingTop: embed && 0 }}>
          <Switch>
            <Route path={`${url}/home`} component={PublicHomePage} />
            <Route path={`${url}/pyramid`} component={PublicHomePyramid} />
            <Route path={`${url}/subtreedemo`} component={PublicSubTreeDemo} />
            <Route
              path={`${url}/${PATH_SEGMENT_ARTICLE}/:id`}
              component={PublicArticle}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_DOCUMENT}/:id`}
              component={PublicArticle}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_PHOTO_ALBUM}/:id`}
              component={PublicAlbumPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_MEDIA}/:id`}
              component={PublicMediaPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_EVENT}/:linkedPageId`}
              component={BasePublicPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_LOCATION}/:linkedPageId`}
              component={BasePublicPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_ARTEFACT}/:linkedPageId`}
              component={BasePublicPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_FAMILY}/:linkedPageId`}
              component={PublicFamiliesPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_INDIVIDUAL}/:linkedPageId`}
              component={PublicIndividualsPage}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_FANS}/:linkedPageId`}
              component={PublicIndividualsPage}
            />
            ,
            <Redirect to={`${url}/home`} />
          </Switch>
          <Footer />
        </Content>
      </PublicContext.Provider>
    </ComponentOverrideContext.Provider>
  )
}

export default Public
