import { createSlice } from '@reduxjs/toolkit'
import api, { createWrappedAsyncThunk } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import {
  createNodeDirectory,
  processIndividualsAddUnions,
} from 'src/modules/viewer/api/nodeDirectory'
import { slugFromName } from './utils'
import { USER_TREE } from '../common/constants'

const SLICE_NAME = 'trees'

export const createTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/createTree`,
  ({ file, name, visibility }) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('slug', slugFromName(name))
    formData.append('file', file)
    formData.append(' visibility', visibility)
    return api.put('/tree/create/', { body: formData })
  }
)

export const createTreeExportJob = createWrappedAsyncThunk(
  `${SLICE_NAME}/createTreeExportJob`,
  treeSlug => {
    return api.post(`/exports/${treeSlug}/exports/`, { body: {} })
  }
)

export const createEmptyTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/createEmptyTree`,
  ({ visibility, treeType = USER_TREE }) => {
    const formData = new FormData()
    formData.append(' visibility', visibility)
    formData.append(' tree_type', treeType)
    return api.post('/tree/create-empty/', { body: formData })
  }
)

export const selectSelf = createWrappedAsyncThunk(
  `${SLICE_NAME}/selectSelf`,
  payload => {
    return api.patch(
      `/account/${getTreeSlugFromStore()}/user/associate-individual/`,
      {
        body: {
          setIndividualId: payload.id,
        },
      }
    )
  }
)

export const selectHomeIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/selectHomeIndividual`,
  payload => {
    return api.patch(
      `/account/${getTreeSlugFromStore()}/user/set-home-individual/`,
      {
        body: {
          setIndividualId: payload.id,
        },
      }
    )
  }
)

export const createDraftTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/createDraftTree`,
  payload => {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('name', payload.name)
    return api.put(`/tree/${payload.treeSlugToUpdate}/create-draft/`, {
      body: formData,
    })
  }
)

export const updateTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateTree`,
  ({ treeSlug, name, allowPublicVisibility }) => {
    let slug
    if (name) {
      slug = slugFromName(name)
    }
    return api.patch(`/tree/${treeSlug}/update/`, {
      body: { name, slug, allowPublicVisibility },
    })
  }
)

export const updateTreeSettings = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateTreeSettings`,
  ({ treeSlug, updates }) => {
    return api.patch(`/tree/${treeSlug}/update-tree-settings/`, {
      body: { ...updates },
    })
  }
)

export const fetchTreeNameAvailability = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchTreeNameAvailability`,
  ({ name, slug }) => {
    return api.get(`/tree/name-availability/`, {
      queryStringParameters: { name, slug },
    })
  }
)

export const fetchDraftTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchDraftTree`,
  payload => {
    return api.get(`/tree/${payload}/draft/`)
  }
)

export const fetchDraftTreeIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchDraftTreeIndividuals`,
  payload => {
    return api.get(`/tree/${payload}/draft-individuals/`)
  }
)

export const fetchDraftTreeRequiredResolutions = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchDraftTreeRequiredResolutions`,
  payload => {
    return api.get(`/tree/${payload}/resolutions/`)
  }
)

export const resolveUpdate = createWrappedAsyncThunk(
  `${SLICE_NAME}/resolveUpdate`,

  payload => {
    return api.patch(`/tree/resolutions/${payload.id}/`, {
      body: {
        setResolvedIndividual: payload.resolvedIndividualId,
        state: payload.state,
      },
    })
  }
)
export const resolveTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/resolveTree`,

  payload => {
    return api.patch(`/tree/${payload}/resolve/`)
  }
)
export const cancelResolution = createWrappedAsyncThunk(
  `${SLICE_NAME}/cancelResolution`,

  payload => {
    return api.patch(`/tree/${payload}/cancel/`)
  }
)

export const copyItemsToOtherTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/copyItemsToOtherTree`,

  payload => {
    return api.put(
      `/tree/${payload.toTreeSlug}/copyItemsFrom/${payload.fromTreeSlug}`
    )
  }
)

const INITIAL_CREATED_TREE_STATE = {
  tree: {
    id: null,
  },
  systemEvent: {
    id: null,
  },
}

const initialState = {
  createdTree: INITIAL_CREATED_TREE_STATE,
  draftTree: undefined,
  draftTreeUnresolved: [],
}

export const treesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearCreatedTree: state => {
      state.createdTree = INITIAL_CREATED_TREE_STATE
    },
  },
  extraReducers: {
    [createTree.fulfilled]: (state, { payload }) => {
      state.createdTree = payload
      // this does not send events because a .ged will have been submitted
      // and it might fail to process
    },
    [createEmptyTree.fulfilled]: (state, { payload }) => {
      // event creation moved to callers
      state.createdTree.tree = payload
    },
    [createDraftTree.fulfilled]: (state, { payload }) => {
      state.createdTree = payload
    },
    [fetchDraftTree.fulfilled]: (state, { payload }) => {
      state.draftTree = payload
    },
    [fetchDraftTreeIndividuals.fulfilled]: (state, { payload }) => {
      state.draftTreeIndividuals = payload
      const tempLookup = createNodeDirectory(payload)
      state.draftNodes = processIndividualsAddUnions(tempLookup, payload)
      state.draftNodeDirectory = createNodeDirectory(state.draftNodes)
    },
    [fetchDraftTreeRequiredResolutions.fulfilled]: (state, { payload }) => {
      state.draftTreeUnresolved = payload
    },
    [resolveUpdate.fulfilled]: (state, { payload }) => {
      state.draftTreeUnresolved = state.draftTreeUnresolved.map(
        updateResolution => {
          if (updateResolution.id === payload.id) {
            return payload
          }
          return updateResolution
        }
      )
    },
  },
})

export const { clearCreatedTree } = treesSlice.actions

export const selectCreatedTree = state => state[SLICE_NAME].createdTree
export const selectDraftTree = state => state[SLICE_NAME].draftTree
export const selectDraftTreeRequiredResolutions = state =>
  state[SLICE_NAME].draftTreeUnresolved
export const selectDraftNodeDirectory = state =>
  state[SLICE_NAME].draftNodeDirectory

export default treesSlice.reducer
