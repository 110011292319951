import { createSlice } from '@reduxjs/toolkit'
import { getTreeSlugFromStore } from '../auth/utils'
import api, { createWrappedAsyncThunk } from 'src/api'

const SLICE_NAME = 'map'

const initialState = {
  currentMap: {},
}

export const fetchMap = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchMap`,
  params => {
    return api.get(`/history/${getTreeSlugFromStore()}/mapblock/${params.id}/`)
  }
)

export const createMap = createWrappedAsyncThunk(
  `${SLICE_NAME}/createMap`,
  map => {
    const slug = getTreeSlugFromStore()

    return api.post(`/history/${slug}/mapblock/`, {
      body: map,
    })
  }
)

export const updateMap = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateMap`,
  ({ id, map }) => {
    const slug = getTreeSlugFromStore()

    return api.patch(`/history/${slug}/mapblock/${id}/`, {
      body: map,
    })
  }
)

export const mapSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMap.pending]: state => {
      state.currentMap = {}
    },
    [fetchMap.fulfilled]: (state, { meta, payload }) => {
      state.currentMap = payload
    },
    [createMap.pending]: state => {
      state.currentMap = {}
    },
    [createMap.fulfilled]: (state, { meta, payload }) => {
      state.currentMap = payload
    },
  },
})

export const selectCurrentMap = state => state[SLICE_NAME]?.currentMap

export default mapSlice.reducer
