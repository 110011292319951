import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { Button } from '../ui'
import Clamp from 'react-multiline-clamp'
import { formatLocationCoords } from './Map'
import { generateLinkForObject } from 'src/modules/app/links'
import { Link } from 'src/modules/ui'

const MarkerNavigator = ({
  currentMarker,
  navigateMarkers,
  //closeMap,
  //isPreview,
  //onClickStartFlyby,
  //flybyButtonText,
  showLatLong = true,
  maxTitleLines = 9,
  treeSlug,
  itemType, // used when creating link to navigate to item when clicked
}) => {
  const [itemUrl, setItemUrl] = useState()

  useEffect(() => {
    if (itemType && currentMarker?.id) {
      const newItemUrl = generateLinkForObject(
        treeSlug,
        itemType,
        currentMarker.id
      )
      setItemUrl(newItemUrl)
    } else {
      setItemUrl(null)
    }
  }, [currentMarker, itemType, treeSlug])
  return (
    <>
      {currentMarker ? (
        <Box
          sx={{
            height: '100%',
            //position: 'relative',
            maxWidth: '400px',
          }}
        >
          <Link to={itemUrl} key={currentMarker.id}>
            <Box p={2}>
              {currentMarker?.title &&
                currentMarker?.address !== currentMarker?.title && (
                  <Typography variant="h4">
                    <Clamp lines={maxTitleLines}>{currentMarker?.title}</Clamp>
                  </Typography>
                )}
              {currentMarker?.address && (
                <Typography
                  variant="subtitle1"
                  color="primary"
                  lineHeight={1.4}
                >
                  {currentMarker?.address}
                </Typography>
              )}
              {showLatLong && currentMarker?.lat && currentMarker?.lng && (
                <Typography variant="subtitle2" mt={0.5}>
                  {formatLocationCoords(currentMarker)}
                </Typography>
              )}
              <Typography mt={1}>{currentMarker?.desc}</Typography>
            </Box>

            {currentMarker?.photo?.fileMedium && (
              <Box style={{ flexGrow: 1 }}>
                <img
                  src={currentMarker?.photo?.fileMedium}
                  //width="100%"
                  //width="auto"
                  //height="auto"
                  style={{
                    objectFit: 'contain',
                    //maxWidth: '100px',
                    maxWidth: '100%',
                    //maxHeight: '100%',
                    maxHeight: '150px',
                    margin: 'auto',
                  }}
                  alt={currentMarker?.photo?.title || 'Marker Image'}
                />
              </Box>
            )}
          </Link>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'sticky',
              bottom: 0,
              width: '100%',
              backgroundColor: 'white',
              p: 1,
            }}
          >
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={() => navigateMarkers('prev')}
            >
              Prev
            </Button>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={() => navigateMarkers('next')}
            >
              Next
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {/* {flybyButtonText && (
            <Box
              sx={{
                display: 'flex',
                width: 'fit-content',
                backgroundColor: 'white',
                padding: '4px',
              }}
            >
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => {
                  if (onClickStartFlyby) {
                    onClickStartFlyby()
                  }
                  navigateMarkers('next')
                }}
                //sx={{ margin: 0 }}
              >
                {flybyButtonText}
              </Button>
            </Box>
          )} */}
        </>
      )}
    </>
  )
}

export default MarkerNavigator
