import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'

import { Typography } from 'src/modules/ui'

import { UserContext } from './Users'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

const UsersSortedByEmail = ({
  users,
  headerText,
  availableRoles,
  showRolesHelpDialog,
}) => {
  const classes = useStyles()

  const { IndividualComponent } = useContext(UserContext)

  const sortedUsers = users.slice().sort((a, b) => {
    return (a.user.email || '').localeCompare(b.user.email || '')
  })
  return (
    <>
      <Typography className={classes.header} variant="h6">
        {headerText}
      </Typography>

      <div className={classes.root}>
        {sortedUsers.map((u, idx) => (
          <IndividualComponent
            key={`${headerText}-${idx}-${u.id}`}
            individual={u}
            allowEditEmail={false}
            availableRoles={availableRoles}
            showRolesHelpDialog={showRolesHelpDialog}
          />
        ))}
      </div>
    </>
  )
}

export default UsersSortedByEmail
