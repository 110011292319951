import { createSlice } from '@reduxjs/toolkit'
import pickBy from 'lodash/pickBy'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { API_PATH_SEGMENT_FOR_INSTANCE_TYPE } from 'src/modules/app/links'
import { DEFAULT_FEED_LENGTH } from '../../app/appConstants'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  PATH_SEGMENT_BLOG_POSTS,
} from '../../app/links'
import { sortTypes } from '../../common/constants'
import {
  getViewConfig,
  updateStateWithViewConfigDefaults,
} from '../../common/viewConfigUtils'
import { fetchFamily, fetchIndividual } from '../tree/treeSlice'

const getPublicViewConfigAccessor = (state, type) => {
  const stateKey = stateKeyMap[type]

  if (stateKey) {
    return state.public.page.currentPageState[stateKey]
  }
  return {}
}

const SLICE_NAME = 'page'
const INDIVIDUALS_PAGE_SIZE = 20

const stateKeyMap = {}
stateKeyMap[INSTANCE_TYPE_DOCUMENT] = 'documents'
stateKeyMap[INSTANCE_TYPE_LOCATION] = 'publicPageLocations'
stateKeyMap[INSTANCE_TYPE_ARTEFACT] = 'publicPageArtefacts'
stateKeyMap[INSTANCE_TYPE_EVENT] = 'publicPageEvents'
stateKeyMap[INSTANCE_TYPE_ARTICLE] = 'content'
stateKeyMap[INSTANCE_TYPE_MEDIA] = 'media'
stateKeyMap[INSTANCE_TYPE_PHOTO_ALBUM] = 'media'
stateKeyMap[INSTANCE_TYPE_INDIVIDUAL] = 'publicPageIndividuals'

export const fetchFeedPending = (state, { meta }) => {
  const before = meta.arg?.before
  if (!before) {
    state.currentPageState.feed.hasMore = true
  } else {
    state.currentPageState.feed.before = before
  }
}
export const fetchFeedFulfilled = (state, { meta, payload }) => {
  const before = meta.arg?.before
  if (!before) {
    state.currentPageState.feed.results = payload
  } else {
    state.currentPageState.feed.results =
      state.currentPageState.feed.results.concat(payload)
  }
  if (payload.length < DEFAULT_FEED_LENGTH) {
    state.currentPageState.feed.hasMore = false
  }
}

export const fetchPublicPageFeed = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageFeed`,
  ({ treeSlug, before, target, sharedByIndividual }) => {
    const queryStringParameters = {
      ...(target ? { target } : {}),
    }
    if (before) {
      queryStringParameters.before = before
    }

    if (sharedByIndividual) {
      queryStringParameters.sharedById = sharedByIndividual.id
    }

    return api.get(`/public/tree/${treeSlug}/feed/`, {
      queryStringParameters,
    })
  }
)

export const fetchPublicPageThunk = (
  {
    target,
    page,
    treeSlug,
    pageType,
    block_type,
    block_limit,
    viewConfigQueryParams,
  },
  { getState }
) => {
  const limit = PAGE_SIZE
  const offset = page * limit

  const existingState = getState()

  const { hierarchical, ancestralOnly, sort } = getViewConfig(
    getPublicViewConfigAccessor(existingState, pageType),
    viewConfigQueryParams
  )

  const queryStringParameters = pickBy({
    target,
    offset,
    type: pageType,
    limit,
    block_limit,
    block_type,
    hierarchical,
    ordering: sort,
    ancestral_only: ancestralOnly,
  })

  const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]
  return api.get(`/public/${treeSlug}/${pathSegment}/`, {
    queryStringParameters,
  })
}

export const fetchPublicPageBlogPosts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageBlogPosts`,
  ({
    target,
    page,
    sort,
    treeSlug,
    pageType,
    block_type,
    block_limit,
    limit,
  }) => {
    const pageLimit = limit || PAGE_SIZE
    const offset = page * pageLimit
    const queryStringParameters = pickBy({
      target,
      offset,
      ordering: sort,
      type: pageType,
      limit: pageLimit,
      block_limit,
      block_type,
    })
    return api.get(`/public/${treeSlug}/${PATH_SEGMENT_BLOG_POSTS}/`, {
      queryStringParameters,
    })
  }
)

export const fetchPublicPageContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageContent`,
  fetchPublicPageThunk
)
export const fetchPublicPageDocuments = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageDocuments`,
  fetchPublicPageThunk
)
export const fetchPublicPageInstances = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageInstances`,
  fetchPublicPageThunk
)
// export const fetchPublicPageFacts = createWrappedAsyncThunk(
//   `${SLICE_NAME}/fetchPublicPageFacts`,
//   fetchPublicPageThunk
// )
export const fetchPublicPageEvents = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageEvents`,
  fetchPublicPageThunk
)
export const fetchPublicPageArtefacts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageArtefacts`,
  fetchPublicPageThunk
)
export const fetchPublicPageLocations = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageLocations`,
  fetchPublicPageThunk
)

export const fetchPublicPageIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageIndividuals`,
  ({ subject, target, page, treeSlug }, { getState }) => {
    const limit = INDIVIDUALS_PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { hierarchical, ancestralOnly, sort } = getViewConfig(
      getPublicViewConfigAccessor(existingState, INSTANCE_TYPE_INDIVIDUAL)
    )

    const { searchTerm } = getPublicViewConfigAccessor(
      existingState,
      INSTANCE_TYPE_INDIVIDUAL
    )

    const queryStringParameters = pickBy({
      subject,
      target,
      offset,
      limit,
      hierarchical,
      ancestral_only: ancestralOnly,
      ordering: sort,
      search: searchTerm,
    })

    return api.get(`/public/${treeSlug}/simple-individuals/`, {
      queryStringParameters,
    })
  }
)

export const fetchPublicPageFacts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedFacts`,
  ({ target, treeSlug, allFacts = false }) => {
    const allFactsParam = allFacts ? '&allFacts=true' : ''
    return api.get(
      `/public/tree/${treeSlug}/fact-timeline/?target=${target}${allFactsParam}`
    )
  }
)

export const fetchPublicPagePhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPagePhotos`,
  ({ target, treeSlug, page }, { getState }) => {
    const limit = PHOTO_PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { hierarchical, ancestralOnly, sort } = getViewConfig(
      getPublicViewConfigAccessor(existingState, INSTANCE_TYPE_MEDIA)
    )

    const queryStringParameters = pickBy({
      target,
      offset,
      limit,
      hierarchical,
      ancestral_only: ancestralOnly,
      ordering: sort,
    })
    return api.get(`/public/${treeSlug}/media/`, {
      queryStringParameters,
    })
  }
)
export const fetchPublicPageItem = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicPageItem`,
  ({ pageType, linkedPageId, treeSlug }) => {
    const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]
    return api.get(`/public/${treeSlug}/${pathSegment}/${linkedPageId}/`)
  }
)

export const setPublicViewConfig = createWrappedAsyncThunk(
  `${SLICE_NAME}/setPublicViewConfig`,
  ({ type, viewConfig }) => {
    return Promise.resolve({ type, viewConfig })
  }
)

const emptyPageState = {
  feed: {
    before: null,
    hasMore: true,
    results: [],
  },
  welcome: {
    loading: true,
    article: null,
    articleId: null,
  },
  content: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  documents: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  media: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.CREATED,
    hierarchical: false,
    ancestralOnly: false,
  },
  publicPageInstances: {
    page: 0,
    count: 0,
    results: [],
  },
  publicPageFacts: [],
  publicPageArtefacts: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  publicPageLocations: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  publicPageEvents: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
  },
  publicPageBlogPosts: {
    page: 0,
    count: 0,
    results: [],
  },
  publicPageIndividuals: {
    page: 0,
    count: 0,
    results: [],
    searchTerm: undefined,
    sort: sortTypes.FULL_NAME_ASC,
    hierarchical: false,
    ancestralOnly: false,
  },
  publicPageItem: {},
}

export const initialState = {
  currentPageId: undefined,
  currentPageState: emptyPageState,
  pageCache: {},
}

export const pageSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPublicIndividualsSearchTerm: (state, action) => {
      state.currentPageState.publicPageIndividuals.searchTerm = action.payload
    },
    resetPublicPageState: (state, action) => {
      if (state.currentPageId) {
        state.pageCache[state.currentPageId] = state.currentPageState
      }
      const newPage =
        state.pageCache[action.payload?.linkedPageId] || emptyPageState

      state.currentPageState = newPage

      state.currentPageId = action.payload?.linkedPageId
    },
    setSort: (state, action) => {
      state.currentPageState.content.sort = action.payload
    },
    setPublicPageArticle: (state, action) => {
      if (action) {
        state.currentPageState.welcome.article = action.payload
        state.currentPageState.welcome.articleId = action.payload?.id
        state.currentPageState.welcome.loading = false
      }
    },
  },

  extraReducers: {
    [fetchPublicPageFeed.pending]: fetchFeedPending,
    [fetchPublicPageFeed.fulfilled]: fetchFeedFulfilled,
    [fetchPublicPageItem.fulfilled]: (state, { payload }) => {
      state.currentPageState.publicPageItem = payload
      updateStateWithViewConfigDefaults(
        state.currentPageState,
        payload.viewConfigLinks,
        stateKeyMap
      )
    },
    [fetchIndividual.fulfilled]: (state, { payload }) => {
      updateStateWithViewConfigDefaults(
        state.currentPageState,
        payload.viewConfigLinks,
        stateKeyMap
      )
    },
    [fetchFamily.fulfilled]: (state, { payload, meta }) => {
      updateStateWithViewConfigDefaults(
        state.currentPageState,
        payload.viewConfigLinks,
        stateKeyMap
      )
    },
    ...handlePaginatedAction(
      fetchPublicPageContent,
      state => state.currentPageState.content
    ),
    ...handlePaginatedAction(
      fetchPublicPageDocuments,
      state => state.currentPageState.documents
    ),
    ...handlePaginatedAction(
      fetchPublicPagePhotos,
      state => state.currentPageState.media
    ),
    ...handlePaginatedAction(
      fetchPublicPageInstances,
      state => state.currentPageState.publicPageInstances
    ),
    ...handlePaginatedAction(
      fetchPublicPageFacts,
      state => state.currentPageState.publicPageFacts
    ),
    ...handlePaginatedAction(
      fetchPublicPageEvents,
      state => state.currentPageState.publicPageEvents
    ),
    ...handlePaginatedAction(
      fetchPublicPageArtefacts,
      state => state.currentPageState.publicPageArtefacts
    ),
    ...handlePaginatedAction(
      fetchPublicPageBlogPosts,
      state => state.currentPageState.publicPageBlogPosts
    ),
    ...handlePaginatedAction(
      fetchPublicPageLocations,
      state => state.currentPageState.publicPageLocations
    ),
    ...handlePaginatedAction(
      fetchPublicPageIndividuals,
      state => state.currentPageState.publicPageIndividuals
    ),
    [setPublicViewConfig.fulfilled]: (state, { payload }) => {
      const key = payload?.type
      const stateKey = stateKeyMap[key]

      if (stateKey) {
        state.currentPageState[stateKey].sort = payload?.viewConfig?.sort
        state.currentPageState[stateKey].hierarchical =
          payload?.viewConfig?.hierarchical
        state.currentPageState[stateKey].ancestralOnly =
          payload?.viewConfig?.ancestralOnly
        state.currentPageState[stateKey].userHasUpdatedViewConfig = true
      }
    },
  },
})

const PAGE_SIZE = 8
const PHOTO_PAGE_SIZE = 10
export const {
  setPublicIndividualsSearchTerm,
  setSort,
  setPublicPageArticle,
  resetPublicPageState,
} = pageSlice.actions
export const selectPublicContent = state =>
  state.public.page.currentPageState.content
export const selectPublicDocuments = state =>
  state.public.page.currentPageState.documents
export const selectPublicPhotos = state =>
  state.public.page.currentPageState.media
export const selectPublicFeed = state => state.public.page.currentPageState.feed
export const selectPublicPageItem = state =>
  state.public.page.currentPageState.publicPageItem
export const selectPublicPageInstances = state =>
  state.public.page.currentPageState.publicPageInstances
export const selectPublicPageArticle = state =>
  state.public.page.currentPageState.welcome
export const selectPublicPageFacts = state =>
  state.public.page.currentPageState.publicPageFacts
export const selectPublicPageEvents = state =>
  state.public.page.currentPageState.publicPageEvents
export const selectPublicPageArtefacts = state =>
  state.public.page.currentPageState.publicPageArtefacts
export const selectPublicPageLocations = state =>
  state.public.page.currentPageState.publicPageLocations
export const selectPublicBlogPosts = state =>
  state.public.page.currentPageState.publicPageBlogPosts
export const selectPublicPageIndividuals = state =>
  state.public.page.currentPageState.publicPageIndividuals

export const selectPublicIndividualsSearchTerm = state =>
  state.public.page.currentPageState.publicPageIndividuals.searchTerm

export default pageSlice.reducer
