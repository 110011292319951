// GeocodeInput.js
import React from 'react'
import { mapbox_access_token } from '../map/Map'
import { SearchBox } from '@mapbox/search-js-react'
import { useTheme } from '@mui/styles'

const GeocodeInput = ({
  handleSelectResult,
  value = '',
  icons,
  placeholder = 'Search to add a pin',
  searchTypes = [
    'country',
    'region',
    'postcode',
    'district',
    'place',
    'locality',
    'address',
    'poi',
  ],
  /*
country, region, postcode, district, place, city, locality, neighborhood, street, address, POI,
  */
  mapboxThemeVariablesOverride = {}, // see Mapbox's theme.d.ts ThemeVariables
  getMapboxTheme = muiTheme => {
    return {
      variables: {
        fontFamily: muiTheme.typography.body1.fontFamily,
        unit: '16px',
        padding: '0.5em',
        borderRadius: '5px',
        boxShadow: '0 0 0 1px silver',
        spacing: 0,
      },
      icons: {
        ...icons,
      },
    }
  },
}) => {
  const defaultTheme = useTheme()

  const theme = getMapboxTheme(defaultTheme)
  theme.variables = { ...theme.variables, ...mapboxThemeVariablesOverride }
  theme.icons = { ...theme.icons, ...icons }

  const handleSelect = params => {
    if (handleSelectResult && params?.features && params?.features[0]) {
      /*
       see for params https://docs.mapbox.com/mapbox-search-js/api/core/search/#searchboxfeaturesuggestion
       */

      handleSelectResult(params.features[0])
    }
  }

  function handleFormEvent(e) {
    e.preventDefault()
  }

  return (
    <form onSubmit={handleFormEvent} onClick={handleFormEvent}>
      <SearchBox
        theme={theme}
        value={value}
        options={{
          limt: 20,
          types: searchTypes,
        }}
        onRetrieve={handleSelect}
        accessToken={mapbox_access_token}
        placeholder={placeholder}
        //onChange={(value) => {}}
        //interceptSearch={(value) => {}}
      />
    </form>
  )
}

export default GeocodeInput
