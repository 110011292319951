import {
  Dialog,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import AiWizard from './AiWizard'
import { Box } from '@mui/system'
import { useEffect, useReducer, useState } from 'react'
import React from 'react'
import DialogTitle from '../ui/DialogTitle'
import { useActionDispatcher } from '../app'
import {
  aiWizardHandler,
  aiWizardLoader,
  selectWizardDefinitionById,
} from '../services/servicesSlice'
import { form2PayloadTranslator } from './AiWizardUtils'
import { generateTreeLink } from '../app/links'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useHistory, useParams } from 'react-router-dom'
import FixedHeightDialogContent from '../ui/FixedHeightDialogContent'
import { Button, LoadingIndicator } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { actionTypes, aiWizardsDefault, wizardStates } from './aiWizardConfig'
import { createAIBlock } from '../writeArticle/writeArticleSlice'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'

const gaEventPayload = {
  category: 'AIWIZARD',
  action: 'undefined',
  label: 'undefined',
}

const AiWizardDialog = ({
  wizardStateOverride,
  onAddAIContentBlock,
  trigger,
  addBlockAtIndex,
  aiwizardsOverride,
}) => {
  const [
    wizardState,
    handleShowModal,
    handleCloseModal,
    handleSubmit,
    modalOpen,
    dispatch,
    wizardDefinition,
  ] = useAiWizardHandlers({
    wizardStateOverride,
    onAddAIContentBlock,
    addBlockAtIndex,
  })

  const aiWizards = aiwizardsOverride || aiWizardsDefault

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} maxWidth="md" fullWidth>
        <DialogTitle onClose={handleCloseModal}>
          {wizardState.selectedWizard
            ? wizardState.selectedWizard.title
            : 'Ai Wizard'}
        </DialogTitle>
        <FixedHeightDialogContent>
          <List
            sx={{
              width: '100%',
              display:
                wizardState.status === wizardStates.SELECTING
                  ? 'block'
                  : 'none',
            }}
          >
            <div>
              <Typography>Select a Wizard from the list.</Typography>
            </div>
            {aiWizards.map(wizard => (
              <React.Fragment key={wizard.title}>
                <ListItem
                  disablePadding
                  sx={{
                    padding: '1rem',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f0f0f0', // Change this color to whatever highlight color you prefer
                    },
                  }}
                >
                  {wizard.icon && (
                    <ListItemIcon>
                      <wizard.icon />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primaryTypographyProps={{
                      sx: { fontWeight: 'bold' }, // Make the primary text bold
                    }}
                    onClick={() =>
                      dispatch({ type: actionTypes.SELECT, payload: wizard })
                    }
                    primary={wizard.title}
                    secondary={
                      <>
                        <Typography sx={{ display: 'inline' }}>
                          {wizard.description}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
          {wizardState.status === wizardStates.SELECTED && (
            <Box>
              <AiWizard
                wizardConfig={wizardState.selectedWizard}
                onSubmit={handleSubmit}
                formDefinition={wizardDefinition}
              />
            </Box>
          )}
          <Box
            sx={{
              display:
                wizardState.status === wizardStates.ERROR ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%',
              gap: 2,
            }}
          >
            <div>
              <Typography>Ooops something went wrong!</Typography>
            </div>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={() => dispatch({ type: actionTypes.RETRY })}
            >
              Re Try
            </Button>
          </Box>
          <Box
            sx={{
              display:
                wizardState.status === wizardStates.SUBMITTING
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <LoadingIndicator title={wizardState.loadingMessage} />
          </Box>
        </FixedHeightDialogContent>
      </Dialog>
    </>
  )
}

const useAiWizardHandlers = ({
  wizardStateOverride,
  onAddAIContentBlock,
  addBlockAtIndex,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const dispatchAiWizardArticleHandler = useActionDispatcher(aiWizardHandler)
  const dispatchAiWizardBlockHandler = useActionDispatcher(createAIBlock)
  const dispatchAiWizardLoader = useActionDispatcher(aiWizardLoader)
  const { id } = useParams()
  const isArticleBlock = id !== undefined
  const dispatchAiWizardHandler = isArticleBlock
    ? dispatchAiWizardBlockHandler
    : dispatchAiWizardArticleHandler

  const [wizardState, dispatch] = useReducer(
    aiWizardReducer(wizardStateOverride || initialWizardState),
    wizardStateOverride || initialWizardState
  )

  const wizardDefinition = useSelector(
    selectWizardDefinitionById(wizardState?.selectedWizard?.id)
  )

  useEffect(() => {
    if (wizardState.status === wizardStates.LOADING_DEFINITION) {
      sendEvent(ga4Events.AIWIZARD_STARTED)
      if (wizardDefinition) {
        dispatch({ type: actionTypes.LOADED_DEFINITION })
      } else {
        dispatchAiWizardLoader(wizardState.selectedWizard.id).then(response => {
          if (response.error) {
            dispatch({ type: actionTypes.ERROR })
          }
        })
      }
    }
    if (wizardState.status === wizardStates.SUBMITTING) {
      dispatchAiWizardHandler(
        { ...wizardState.params, articleId: id, order: addBlockAtIndex },
        {
          errorNotification: 'Error processing wizard',
          successNotification: 'Content Created successfully!',
          errorSnackbarOptions: {
            autoHideDuration: 10000,
          },
        }
      ).then(response => {
        if (response.error) {
          dispatch({ type: actionTypes.ERROR })
        } else {
          if (isArticleBlock) {
            sendEvent(ga4Events.AIWIZARD_BLOCK_COMPLETED, {
              ...gaEventPayload,
              action: ga4Events.AIWIZARD_BLOCK_COMPLETED,
              label: wizardState.selectedWizard.id,
            })
            //it's a block so just close
            if (onAddAIContentBlock) {
              onAddAIContentBlock(addBlockAtIndex)
            }
            setModalOpen(false)
            dispatch({ type: actionTypes.RESET })
          } else {
            sendEvent(ga4Events.AIWIZARD_COMPLETED, {
              ...gaEventPayload,
              action: ga4Events.AIWIZARD_COMPLETED,
              label: wizardState.selectedWizard.id,
            })
            dispatch({ type: actionTypes.SUCCESS, payload: response.payload })
          }
        }
      })
    }
    if (wizardState.status === wizardStates.SUCCESS) {
      if (!isArticleBlock) {
        //we are creating an article so just redirect there and modal will go anyway
        const url = generateTreeLink(
          treeSlug,
          `write-article/${wizardState.articleId}`
        )
        history.push(url)
      }
    }
    if (wizardState.status === wizardStates.CANCEL) {
      sendEvent(ga4Events.AIWIZARD_CANCELLED, {
        ...gaEventPayload,
        action: ga4Events.AIWIZARD_CANCELLED,
        label: wizardState.selectedWizard.id,
      })
      setModalOpen(false)
      dispatch({ type: actionTypes.RESET })
    }
  }, [
    wizardState,
    dispatchAiWizardHandler,
    dispatchAiWizardBlockHandler,
    history,
    setModalOpen,
    treeSlug,
    dispatchAiWizardLoader,
    wizardDefinition,
    isArticleBlock,
    addBlockAtIndex,
    id,
    onAddAIContentBlock,
  ])

  const handleShowModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
    dispatch({ type: actionTypes.CANCEL })
  }

  const handleSubmit = (values, selectedInstance) => {
    const params = form2PayloadTranslator(
      wizardState.selectedWizard,
      wizardDefinition,
      values,
      selectedInstance
    )

    dispatch({ type: actionTypes.SUBMIT, payload: params })
  }

  return [
    wizardState,
    handleShowModal,
    handleCloseModal,
    handleSubmit,
    modalOpen,
    dispatch,
    wizardDefinition,
  ]
}

const initialWizardState = {
  status: wizardStates.SELECTING,
  selectedWizard: null,
  params: null,
  articleId: null,
  loadingMessage: null,
}

const aiWizardReducer = initialWizardState => {
  return (state, action) => {
    switch (action.type) {
      case actionTypes.SELECT:
        return {
          ...state,
          status: wizardStates.LOADING_DEFINITION,
          selectedWizard: action.payload,
          loadingMessage: 'Loading wizard definition please wait!',
        }
      case actionTypes.LOADED_DEFINITION:
        return {
          ...state,
          status: wizardStates.SELECTED,
        }
      case actionTypes.RETRY:
        return {
          ...state,
          status: wizardStates.SUBMITTING,
        }
      case actionTypes.SUBMIT:
        return {
          ...state,
          status: wizardStates.SUBMITTING,
          params: action.payload,
          loadingMessage: 'Creating content please wait!',
        }
      case actionTypes.SUCCESS:
        return {
          ...state,
          status: wizardStates.SUCCESS,
          articleId: action.payload,
        }
      case actionTypes.ERROR:
        return {
          ...state,
          status: wizardStates.ERROR,
        }
      case actionTypes.CANCEL:
        return initialWizardState

      default:
        throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export default AiWizardDialog
