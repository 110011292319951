import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { Box, Typography, styled, useMediaQuery } from '@mui/material'
import { matchPath } from 'react-router'

import { PersonalSettings } from 'src/modules/auth'
import { selectNotificationsUnreadCount } from 'src/modules/app/appSlice'
import { fetchImageAccessCookie } from 'src/modules/viewer/viewerSlice'
import ManageTrees from 'src/modules/trees/ManageTrees'
import AskFamilyButton from 'src/modules/informationRequest/AskFamilyButton'

import NavBar from './NavBar'
import Footer from './Footer'
import config from 'src/config'

import PageVisibility from 'react-page-visibility'
import { RedirectToDefaultTree } from './RedirectToDefaultTree'
import TreeApp from './TreeApp'
import Onboarding from '../onboarding/Onboarding'
import FeedbackButton from './FeedbackButton'
import {
  fetchUser,
  selectAuthorisedTreeSlug,
  selectCurrentTree,
} from '../auth/authSlice'
import CompleteProfile from '../auth/CompleteProfile'
import CreateTreeWizard from '../trees/CreateTreeWizard'
import Pricing from './Pricing'
import { useAvailableFeatures, useTimeBeforeUserReload } from '../auth/hooks'
import { useActionDispatcher, useIsFreeTrial } from './hooks'
import { BLOG_TREE } from '../common/constants'
import CreateBlogWizard from '../trees/CreateBlogWizard'
import { getQueryParams } from '../../utils'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import { useAskFamilyButton } from './hooks'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from './appConstants'
import AppSideBar from './AppSideBar'
import { selectSideBarVisible } from './appSlice'

const MainContainer = styled('div')(({ theme, sideBarVisible }) => ({
  display: 'flex',
  height: '100%',
  transition: 'all 0.5s ease',
}))

const Content = styled('div')(({ theme, sideBarVisible }) => ({
  paddingTop: theme.headerHeight,
  width: sideBarVisible ? 'calc(100% - 300px)' : '100%',
  transition: 'width 0.5s ease',
  '@media print': {
    paddingTop: 0,
  },
}))

// these routes have a tabs component on them
const ROUTES_WITH_TABS = [
  '/:slug/home',
  '/:slug/individuals/:id',
  '/:slug/families/:id',
  '/:slug/occasions/:id',
  '/:slug/artefacts/:id',
  '/:slug/places/:id',
]

const ScrollToTop = React.memo(() => {
  const history = useHistory()

  const getPath = pathname => {
    const match = matchPath(pathname, {
      path: ROUTES_WITH_TABS,
    })

    // path without trailing slash if there is one
    return (match ? match.url : pathname).replace(/\/$/, '')
  }

  const prevPath = useRef(getPath(history.location.pathname))

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const { pathname } = location
      const path = getPath(pathname)

      if (path !== prevPath.current && action !== 'POP') {
        window.scrollTo(0, 0)
      }

      prevPath.current = path
    })
    return () => unlisten()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
})

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const { treeOverride, treeTypeOverride } = getQueryParams(location)
  const showBlog = useIsAlphaFeatureUser()?.blog
  const history = useHistory()
  const tree = useSelector(selectCurrentTree)
  const isExportMode = useIsAlphaFeatureUser()?.exportMode

  const unreadNotificationsCount = useSelector(selectNotificationsUnreadCount)

  const activeTimeout = useRef(null)
  const seconds = useTimeBeforeUserReload()

  const sideBarVisible = useSelector(selectSideBarVisible)

  useEffect(() => {
    if (seconds > 0) {
      if (activeTimeout.current) {
        clearTimeout(activeTimeout.current)
      }
      activeTimeout.current = setTimeout(() => {
        dispatchFetchUser()
      }, seconds)
    }

    return () => {
      clearTimeout(activeTimeout.current)
    }
  }, [seconds, dispatchFetchUser])

  const [
    imageAccessCookieLastFetchedDate,
    setImageAccessCookieLastFetchedDate,
  ] = useState(new Date()) // set to now assuming that config will be loaded when this component is loaded

  if (config.reFetchImageAccessCookie.ms) {
    config.reFetchImageAccessCookie.seconds =
      config.reFetchImageAccessCookie.ms / 1000
  }

  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const treeCanEdit = useAvailableFeatures()?.tree?.editTree
  const pageOutOfTree =
    !window?.location?.pathname.includes(treeSlug) ||
    window?.location?.pathname.includes('blog') ||
    treeTypeOverride === 'BLOG'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatchFetchImageAccessCookie = useCallback(() => {
    const fetch = async () => {
      await dispatch(fetchImageAccessCookie({ treeSlug }))
      const now = new Date()
      setImageAccessCookieLastFetchedDate(now)
    }
    if (treeSlug) {
      fetch()
    }
  }, [dispatch, treeSlug])

  useEffect(() => {
    if (config.reFetchImageAccessCookie.ms) {
      const timerId = setInterval(
        dispatchFetchImageAccessCookie,
        config.reFetchImageAccessCookie.ms
      )
      return () => {
        clearInterval(timerId)
      }
    }
  }, [dispatch, dispatchFetchImageAccessCookie])

  const updateConfigIfNeeded = useCallback(() => {
    if (imageAccessCookieLastFetchedDate) {
      const expiry = new Date(
        new Date().getTime() - (config.reFetchImageAccessCookie.ms + 5000)
      ) //give 5s leeway in case call already inflight
      if (expiry > imageAccessCookieLastFetchedDate) {
        dispatchFetchImageAccessCookie()
      }
    }
  }, [dispatchFetchImageAccessCookie, imageAccessCookieLastFetchedDate])

  const handleVisibilityChange = useCallback(
    isVisible => {
      if (isVisible) {
        updateConfigIfNeeded()
      }
    },
    [updateConfigIfNeeded]
  )

  const handleFocusState = e => {
    updateConfigIfNeeded()
  }

  useEffect(() => {
    window.addEventListener('focus', handleFocusState)

    return () => {
      window.removeEventListener('focus', handleFocusState)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const enableAskFamily = useAskFamilyButton()

  const isFreeTrial = useIsFreeTrial()

  const showBanner =
    (!treeCanEdit && !pageOutOfTree) ||
    (!tree?.includesSharedAbilities && isFreeTrial && !pageOutOfTree)

  return (
    <>
      <PageVisibility onChange={handleVisibilityChange}>
        <Box sx={{ height: '100%', position: 'relative' }}>
          <NavBar
            unreadNotifications={unreadNotificationsCount}
            authorisedTreeSlugOverride={treeOverride}
            treeTypeOverride={treeTypeOverride}
          />
          <MainContainer sideBarVisible={sideBarVisible}>
            <Content sideBarVisible={sideBarVisible}>
              {showBanner ? (
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: 'profileGrey.main',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    height: 45,
                  }}
                >
                  <Typography color="white" variant="body1" fontWeight="medium">
                    {isFreeTrial
                      ? 'You are currently in your 30 day free trial'
                      : 'This archive is in view only mode, please subscribe to activate edit mode'}
                  </Typography>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={() => history.push('/pricing')}
                  >
                    Subscribe
                  </Button>
                </Box>
              ) : null}
              <ScrollToTop />
              <Switch>
                <Route
                  exact
                  path={`/complete-profile`}
                  component={CompleteProfile}
                />
                <Route exact path={`/onboarding`} component={Onboarding} />
                <Route
                  exact
                  path={`/create-tree`}
                  component={CreateTreeWizard}
                />
                {showBlog && (
                  <Route
                    exact
                    path={`/create-blog`}
                    component={CreateBlogWizard}
                  />
                )}
                <Route
                  exact
                  path={`/personal-settings`}
                  component={PersonalSettings}
                />
                <Route exact path={`/pricing`} component={Pricing} />
                <Route exact path={`/tree-admin`} component={ManageTrees} />

                {showBlog && (
                  <Route
                    exact
                    path={`/blog-admin`}
                    render={props => <ManageTrees treeType={BLOG_TREE} />}
                  />
                )}
                <Route path={`/:slug`} component={TreeApp} />
                <Route path="/" component={RedirectToDefaultTree} />
                <Redirect to="/" />
              </Switch>
              <Footer />
            </Content>
            {!mobileBreakpoint && (
              <AppSideBar sideBarVisible={sideBarVisible} />
            )}
          </MainContainer>
          {!mobileBreakpoint && enableAskFamily && <AskFamilyButton />}
          {!mobileBreakpoint && !isExportMode && <FeedbackButton />}
        </Box>
      </PageVisibility>
    </>
  )
}

export default App
