import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_LOCATION } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'
import {
  fetchLinkedPageLocations,
  selectLinkedPageLocations,
} from './pageSlice'

import { Box } from '@mui/material'
import Map from '../map/Map'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

// used inside an item's locations tab
const Locations = () => {
  const { linkedPageId } = useParams()

  const [currentMap, setCurrentMap] = useState(null)

  const buildMapConfiguration = locations => {
    console.debug(`buildMapConfiguration(): called with locations:`, locations)
    if (locations && locations.results && locations.results.length > 0) {
      const mapParams = { title: 'Map of places tagged with current location' }

      const mapLinks = locations.results.flatMap(location => {
        if (location.address) {
          return {
            id: location.id,
            instanceType: 'address',
            title: location.title,
            target: {
              id: location.address.id,
              latiGed: location.address.latiGed,
              longGed: location.address.longGed,
              photo: location.photo,
            },
          }
        }
        return []
      })

      if (mapLinks && mapLinks.length > 0) {
        mapParams.mapLinks = mapLinks
      }

      console.debug(
        `buildMapConfiguration(): setting currentMap to:`,
        mapParams
      )

      setCurrentMap(mapParams)
    } else {
      console.debug(`buildMapConfiguration(): setting currentMap to null`)
      setCurrentMap(null)
    }
  }

  const linkedLocations = useSelector(selectLinkedPageLocations)

  useEffect(() => buildMapConfiguration(linkedLocations), [linkedLocations])

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  return (
    <>
      <LinkedPageList
        action={fetchLinkedPageLocations}
        selector={selectLinkedPageLocations}
        target={linkedPageId}
        type={INSTANCE_TYPE_LOCATION}
        map={
          <Box
            style={{
              width: '100%',
              border: 'solid 1px',
              display: 'flex', // this and below just to center the 'Waiting for places with addresses' text
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            {currentMap ? (
              <Map
                currentMap={currentMap}
                isArticle={false}
                initialFlyToMarkersIn2d={true}
                flyOnMarkerClick={false}
                inlineMapHeight={400}
                showFlybyButtonWhenInline={true}
                maxSidepanelTitleLines={2}
                sidePanelShowLatLong={false}
                showFullWindowButton={true}
                allowFullscreen={true}
                treeSlug={treeSlug}
                markerItemType={INSTANCE_TYPE_LOCATION}
              />
            ) : (
              'Waiting for places with addresses'
            )}
          </Box>
        }
      />
    </>
  )
}

export default Locations
