import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk } from 'src/api'
import { setSourceTypes } from 'src/modules/content/SourcesCommon'
import pickBy from 'lodash/pickBy'
import { updatePublicNodeDirectoryFromContent } from '../tree/treeSlice'
import { PATH_SEGMENT_BLOG_POSTS } from '../../app/links'

const SLICE_NAME = 'publicContent'

const fetchArticle = async ({
  id,
  treeSlug,
  block_limit,
  single_page_loading,
  instance_type,
}) => {
  const queryStringParameters = pickBy({
    block_limit,
    single_page_loading,
  })

  const article = await api.get(
    `/public/${treeSlug}/${instance_type || 'content'}/${id}/`,
    {
      queryStringParameters,
    }
  )

  return article
}

const fetchPublicArticleThunk = async (params, { dispatch }) => {
  const article = await fetchArticle(params)

  await dispatch(updatePublicNodeDirectoryFromContent(article))

  return article
}

export const fetchPublicArticleStub = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicArticleStub`,
  fetchPublicArticleThunk
)

export const fetchPublicArticle = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicArticle`,
  fetchPublicArticleThunk
)

const fetchPublicBlogPostThunk = async (params, { dispatch }) => {
  const article = await fetchArticle({
    ...params,
    instance_type: PATH_SEGMENT_BLOG_POSTS,
  })

  await dispatch(updatePublicNodeDirectoryFromContent(article))

  return article
}

export const fetchPublicBlogPostStub = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicBlogPostStub`,
  fetchPublicBlogPostThunk
)

export const fetchPublicBlogPost = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicBlogPost`,
  fetchPublicBlogPostThunk
)

export const fetchPublicContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicContent`,
  ({ id, treeSlug, allMedia = true }) => {
    const queryStringParameters = {
      allMedia,
    }

    return api.get(`/public/${treeSlug}/content/${id}/`, {
      queryStringParameters,
    })
  }
)

const initialState = {
  content: null,
  article: null,
}

export const contentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPublicContent: state => initialState,
  },
  extraReducers: {
    [fetchPublicArticleStub.pending]: state => {
      state.content = null
    },
    [fetchPublicArticleStub.fulfilled]: (state, { payload }) => {
      state.content = payload
      //the UI needs to know a source's type to display it, the type is not persisted to the db but can be derived - derive it now.
      setSourceTypes(state.content)
    },
    [fetchPublicArticle.fulfilled]: (state, { payload }) => {
      state.content = payload
      setSourceTypes(state.content)
    },
    [fetchPublicBlogPostStub.pending]: state => {
      state.content = null
    },
    [fetchPublicBlogPostStub.fulfilled]: (state, { payload }) => {
      state.content = payload
      //the UI needs to know a source's type to display it, the type is not persisted to the db but can be derived - derive it now.
      setSourceTypes(state.content)
    },
    [fetchPublicBlogPost.fulfilled]: (state, { payload }) => {
      state.content = payload
      setSourceTypes(state.content)
    },
    [fetchPublicContent.pending]: state => {
      state.content = null
    },
    [fetchPublicContent.fulfilled]: (state, { payload }) => {
      state.content = payload
      setSourceTypes(state.content)
    },
  },
})

export const { resetPublicContent } = contentSlice.actions

export const selectPublicContent = state => state.public.content.content

export default contentSlice.reducer
