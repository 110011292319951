import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { Box, Typography } from '@mui/material'
import { IconButton } from '../ui'
import { makeStyles } from '@mui/styles'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MapSidePanel from './MapSidePanel'
import PlaceIcon from '@mui/icons-material/Place'
import config from '../../config'

import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { Button } from 'src/modules/ui'
// import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'

export const mapbox_access_token = config?.mapbox?.token

mapboxgl.accessToken = mapbox_access_token

//TODO remove testArr from Map.js
// export const testArr = [
//   {
//     title: 'Birth of Aled',
//     address: 'London',
//     desc: 'Big shmoke',
//     lat: 51.5072,
//     lng: -0.1276,
//   },
//   {
//     title: 'Norwich',
//     desc: 'Little shmoke',
//     lat: 52.6293,
//     lng: 1.2979,
//   },
//   {
//     title: 'Bristol',
//     desc: 'West shmoke',
//     lat: 51.454514,
//     lng: -2.59665,
//   },
//   {
//     title: 'Stroud',
//     desc: 'Little Bristol',
//     lat: 51.7457,
//     lng: -2.2178,
//   },
// ]

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '100%',
  },
}))

export const createTemporaryMarkerId = (lat, lng) => {
  return `SORTABLE-ID-${Math.random()}-${lat}-${lng}-${Math.random()}`
}

const MapControls = ({ map, flyTo, threeD }) => {
  const increaseAltitude = () => {
    const camera = map.getFreeCameraOptions()
    const currentPosZ = camera._position.z
    camera._position.z = currentPosZ + 0.000001
    map.setFreeCameraOptions(camera)
  }

  const decreaseAltitude = () => {
    const camera = map.getFreeCameraOptions()
    const currentPosZ = camera._position.z
    camera._position.z = currentPosZ - 0.000001
    map.setFreeCameraOptions(camera)
  }

  const toggleCamera3D = () => {
    // console.debug(`toggleCamera3D(): threeD: ${threeD}`)
    if (threeD === true) {
      setCamera2D()
    } else {
      setCamera3D()
    }
  }
  const setCamera2D = () => {
    flyTo({
      pitch: 0,
      duration: 2000,
    })
  }

  const setCamera3D = () => {
    flyTo({
      pitch: 30,
      duration: 2000,
    })
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 0,
        marginRight: '44px',
        marginTop: '10px',
      }}
    >
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={increaseAltitude}
      >
        <ArrowUpwardIcon color="primary.darkGrey" fontSize="small" />
      </IconButton>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={decreaseAltitude}
      >
        <ArrowDownwardIcon color="primary.darkGrey" fontSize="small" />
      </IconButton>
      <Typography
        onClick={toggleCamera3D}
        fontWeight={600}
        sx={{ cursor: 'pointer', textAlign: 'center' }}
        pb={0.5}
      >
        {threeD ? `2D` : `3D`}
      </Typography>
    </Box>
  )
}

const getUserLatLng = async () => {
  try {
    const response = await fetch(
      'https://ssl.geoplugin.net/json.gp?k=5412b3c1581c5252'
    )
    const data = await response.json()
    if (data?.geoplugin_latitude) {
      const lat = Number(data?.geoplugin_latitude)
      const lon = Number(data?.geoplugin_longitude)
      return { lat: lat, lon: lon }
    }
  } catch (err) {
    console.log(err)
  }
  // default USA
  const latUS = 37.0902
  const lngUS = -95.7129
  return { lat: latUS, lon: lngUS }
}

export const formatDegreesAsString = (degrees, dp = 6) => {
  if (degrees !== null && degrees !== undefined) {
    return `${parseFloat(degrees).toFixed(dp)}°`
  }
  return ''
}

const formatLatiLongAsString = (lati, long, dp = 6) => {
  return `${formatDegreesAsString(lati, dp)}, ${formatDegreesAsString(
    long,
    dp
  )}`
}
export const formatLocationCoords = location => {
  if (location && location.lat && location.lng) {
    return formatLatiLongAsString(location.lat, location.lng)
  }
  return ''
}

const Map = ({
  id,
  inlineMapHeight = '100%',
  lng = -2.436,
  lat = 53.3781,
  zoom = 4,
  isEditing = false,
  handleRefreshMapBlock,
  isArticle, // set to true by MapBlock when inline (not full window size) and not empty
  mapBlock,
  closeMap,
  currentMap,
  initialFlyToMarkersIn2d = false,
  flyOnMarkerClick = true,
  sidePanelShowLatLong,
  flybyButtonText = 'Start Flyby',
  fitViewToMarkersPadding, // = 0.0005,
  clickableMarkers = true,
  interactive = !isArticle,
  markerInfoPanelEnabled = true,
  maxSidepanelTitleLines,
  allowMaximiseButton = true,
  onFullWindowButtonClick,
  allowFullscreen = false,
  initialMapIsFullWindow,
  singlePoint = false, // don't show the info card, use different exit/close buttons at the bottom right
  treeSlug,
  markerItemType,
  preferAddressAsMarkerPopup, // otherwise the location title or description will be preferred if set
}) => {
  const classes = useStyles()
  const mapRoot = useRef(null)
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(-1)
  const [locations, setLocations] = useState([])
  const [preview, setPreview] = useState(false)
  const [dropPinMode, setDropPinMode] = useState(false) // set to true in initMap() if singlePoint is true and there are no markers

  const [mapRootStyle, setMapRootStyle] = useState({})

  const [mapIsFullWindow, setMapIsFullWindow] = useState(initialMapIsFullWindow)
  const [mapIsFullScreen, setMapIsFullScreen] = useState(false)

  const [mapHeight, setMapHeight] = useState(inlineMapHeight)
  const [threeD, setThreeD] = useState(false)
  const [flyToMarkersIn2d, setFlyToMarkersIn2d] = useState(
    initialFlyToMarkersIn2d
  )
  const [markerInfoPanelOpen, setMarkerInfoPanelOpen] = useState(
    markerInfoPanelEnabled && currentMarkerIndex !== -1
  )
  const [showFlybyButton, setShowFlybyButton] = useState(
    !!flybyButtonText &&
      (!markerInfoPanelOpen || flyToMarkersIn2d) &&
      !isEditing
  )

  useEffect(() => {
    if (markerInfoPanelEnabled) {
      setMarkerInfoPanelOpen(currentMarkerIndex !== -1)
    }
  }, [currentMarkerIndex, markerInfoPanelEnabled])

  useEffect(() => {
    setShowFlybyButton(
      !!flybyButtonText &&
        (!markerInfoPanelOpen || flyToMarkersIn2d) &&
        !isEditing
    )
  }, [markerInfoPanelOpen, flybyButtonText, flyToMarkersIn2d, isEditing])

  useEffect(() => {
    if (!threeD) {
      setFlyToMarkersIn2d(true)
    }
  }, [threeD])

  const flyTo = options => {
    let center = map?.current?.getCenter()
    if (options?.center?.[0] >= -180 && options?.center?.[1] >= -90) {
      center = options?.center
    }

    map.current.flyTo({ ...options, center })
  }

  const resetMap = () => {
    flyTo({
      center: [lng, lat],
      zoom,
      pitch: 0,
      duration: 3000,
      bearing: 0,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    })
  }

  const flyToReadOnly = marker => {
    const newCenter = [marker.lng, marker.lat]

    const min = -90
    const max = 90
    const range = max - min
    const bearing = min + Math.random() * range

    flyTo({
      center: newCenter,
      zoom: 17,
      pitch: 65,
      duration: 6000,
      bearing: bearing,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    })
  }

  const flyToEditMode = marker => {
    const newCenter = [marker.lng, marker.lat]
    let zoom = map?.current?.getZoom()
    if (zoom < 8) {
      zoom = 8
    }
    flyTo({
      center: newCenter,
      zoom,
      duration: 3000,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    })
  }

  const navigateMarkers = (direction, force3D = false) => {
    let newIndex = currentMarkerIndex
    if (direction === 'next') {
      if (currentMarkerIndex === locations.length - 1) {
        newIndex = 0
      } else newIndex = currentMarkerIndex + 1
    } else if (direction === 'prev') {
      if (currentMarkerIndex < 1) {
        newIndex = locations.length - 1
      } else newIndex = currentMarkerIndex - 1
    }

    if (flyToMarkersIn2d && !force3D) {
      flyToEditMode(locations[newIndex])
    } else {
      flyToReadOnly(locations[newIndex])
    }
    setCurrentMarkerIndex(newIndex)
  }

  const handleMarkerClick = marker => {
    if (clickableMarkers) {
      const markerIndex = locations.findIndex(
        location => location.lat === marker.lat && location.lng === marker.lng
      )
      setCurrentMarkerIndex(markerIndex)
      if (flyOnMarkerClick) {
        if (flyToMarkersIn2d || (isEditing && !preview)) {
          flyToEditMode(marker)
        } else {
          flyToReadOnly(marker)
        }
      }
    }
  }

  const fitViewToMarkers = padding => {
    // 0.0005 is a good default for houses in a village
    if (currentMap?.mapLinks?.length > 0) {
      let swCorner = []
      let neCorner = []

      currentMap?.mapLinks?.forEach(mapLink => {
        const locationObj = {
          lat: mapLink.target.latiGed,
          lng: mapLink.target.longGed,
        }
        if (!swCorner[0] || locationObj.lng < swCorner[0]) {
          swCorner[0] = locationObj.lng
        }
        if (!neCorner[0] || locationObj.lng > neCorner[0]) {
          neCorner[0] = locationObj.lng
        }
        if (!swCorner[1] || locationObj.lat < swCorner[1]) {
          swCorner[1] = locationObj.lat
        }
        if (!neCorner[1] || locationObj.lat > neCorner[1]) {
          neCorner[1] = locationObj.lat
        }
      })

      swCorner[0] = Number(swCorner[0])
      swCorner[1] = Number(swCorner[1])
      neCorner[0] = Number(neCorner[0])
      neCorner[1] = Number(neCorner[1])

      // console.debug(
      //   `fitViewToMarkers(): ne corner lati/long: ${neCorner[1]}, ${neCorner[0]}`
      // )
      // console.debug(
      //   `fitViewToMarkers(): sw corner lati/long: ${swCorner[1]}, ${swCorner[0]}`
      // )

      var latiPadding
      var longPadding

      if (padding) {
        latiPadding = padding
        longPadding = padding
      } else {
        const latiDiff = neCorner[1] - swCorner[1] // lati - N/S - vertical - degrees from the equator, -90 to +90
        const longDiff = neCorner[0] - swCorner[0] // long - E/W - horizontal - degrees from Grenwich - -180 to 180

        // console.debug(
        //   `fitViewToMarkers(): diffs lati/long: ${latiDiff}, ${longDiff}`
        // )

        latiPadding = latiDiff / 4
        longPadding = longDiff / 4
      }

      swCorner[0] = swCorner[0] - longPadding
      swCorner[1] = swCorner[1] - latiPadding
      neCorner[0] = neCorner[0] + longPadding
      neCorner[1] = neCorner[1] + latiPadding

      // console.debug(
      //   `fitViewToMarkers(): after ${latiPadding}/${longPadding} padding: ne corner lati/long: ${neCorner[1]}, ${neCorner[0]}`
      // )
      // console.debug(
      //   `fitViewToMarkers(): after  ${latiPadding}/${longPadding} padding: sw corner lati/long: ${swCorner[1]}, ${swCorner[0]}`
      // )

      // const latiDiffPostPadding = swCorner[1] - neCorner[1] // N/S - vertical - degrees from the equator, -90 to +90
      // const longDiffPostPadding = swCorner[0] - neCorner[0] // E/W - horizontal - degrees from Grenwich - -180 to 180

      // console.debug(
      //   `fitViewToMarkers(): after  ${latiPadding}/${longPadding} padding: diffs lati/long: ${latiDiffPostPadding}, ${longDiffPostPadding}`
      // )

      map.current.fitBounds([swCorner, neCorner])
    }
  }

  function onDragEnd(marker, index) {
    if (isEditing && !preview) {
      const lngLat = marker.getLngLat()

      // update the popup on the marker to show the new coordinates
      marker.setPopup(
        new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setText(formatLatiLongAsString(lngLat.lat, lngLat.lng))
      )

      setLocations(prevState => {
        let newState = [...prevState]
        newState[index].marker = marker
        newState[index].lat = lngLat.lat
        newState[index].lng = lngLat.lng
        // address isn't used in singlePoint mode
        newState[index].address = formatLatiLongAsString(lngLat.lat, lngLat.lng)

        return newState
      })
    }
  }

  const layoutMarkers = () => {
    if (!map.current) return // wait for map to initialize

    locations.forEach((location, index) => {
      if (location?.lng && location?.lat && !location?.marker) {
        const popupText = preferAddressAsMarkerPopup
          ? location?.address || formatLocationCoords(location)
          : location?.title ||
            location?.description ||
            location?.address ||
            formatLocationCoords(location)

        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setText(popupText)
        const el = document.createElement('div')
        const marker = new mapboxgl.Marker(el, {
          draggable: isEditing && !preview,
        })
          .setLngLat([location.lng, location.lat])
          .setPopup(popup)
          .addTo(map.current)

        marker._element.key = location.id

        if (!isArticle) {
          if (clickableMarkers) {
            marker.getElement().addEventListener('click', () => {
              handleMarkerClick(location)
            })
          }

          if (isEditing && !preview) {
            marker.on('dragend', () => onDragEnd(marker, index))
          }
        }

        setLocations(prevState => {
          let newState = [...prevState]
          newState[index].marker = marker

          return newState
        })

        const markerDiv = marker.getElement()

        markerDiv.addEventListener('mouseenter', () => marker.togglePopup())
        markerDiv.addEventListener('mouseleave', () => marker.togglePopup())
      }
    })
    if (isArticle && locations.length > 0) {
      fitViewToMarkers(fitViewToMarkersPadding)
    }
  }

  const create3DTerrain = () => {
    if (!map.current) return // wait for map to initialize
    map.current.on('style.load', () => {
      // Custom atmosphere styling
      map.current.setFog({
        color: 'rgb(220, 159, 159)', // Pink fog / lower atmosphere
        'high-color': 'rgb(36, 92, 223)', // Blue sky / upper atmosphere
        'horizon-blend': 0.4, // Exaggerate atmosphere (default is .1)
      })

      map.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.terrain-rgb',
      })

      map.current.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1.5,
      })
    })
  }

  const initMap = async () => {
    if (map?.current) return // initialize map only once
    var mapInitialCenterCoords = null
    const willFitToMarkers = currentMap?.mapLinks?.length > 1 && !isArticle
    if (!willFitToMarkers) {
      // need to select a point to center on, using the provided zoom
      if (currentMap?.mapLinks?.length === 1 && !isArticle) {
        mapInitialCenterCoords = {
          lat: currentMap?.mapLinks[0].target.latiGed,
          lon: currentMap?.mapLinks[0].target.longGed,
        } // [lng, lat]
      } else {
        // either map has no markers yet or isArticle is true
        mapInitialCenterCoords = await getUserLatLng()
        // this can fail if user uses an adblocker
        // in which case the coordinates for central USA are returned
      }
    }

    var mapInitialCenter = null
    if (mapInitialCenterCoords) {
      mapInitialCenter = { center: mapInitialCenterCoords }
    }
    const mapinst = new mapboxgl.Map({
      container: mapContainer.current,
      //   style: `${MAP_STYLE}?optimise=true`,
      style: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
      zoom: zoom,
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
      interactive: interactive, //?? !isArticle,
      zoomControl: false, //interactive !== undefined ? interactive : true,
      ...mapInitialCenter,
    })
    map.current = mapinst

    mapinst.on('move', args => {
      // detect if the user has flipped us between 2d (100% top down) or 3d (any sort of angle) modes
      const map = args.target
      const camera = map.getFreeCameraOptions()
      const cameraOrientation = camera.orientation
      setThreeD(cameraOrientation[0] !== 0 || cameraOrientation[1] !== 0)
    })

    //console.debug(`initMap(): calling setupMapLinks()...`)
    setupMapLinks()
    if (willFitToMarkers) {
      // console.debug(
      //   `initMap(): isArticle and singlePoint both not truthy, calling fitViewToMarkers()...`
      // )
      fitViewToMarkers(fitViewToMarkersPadding)
    }
    //if (!isArticle && (interactive ?? true)) {
    if (interactive) {
      // console.debug(
      //   `initMap(): interactive true, (isArticle: ${isArticle}), calling addControl and enquing trigger to call create3DTerrain()...`
      // )
      map?.current?.addControl(new mapboxgl.NavigationControl())
      map?.current?.on('load', function () {
        create3DTerrain()
      })
    }

    // enter drop pin mode if we are in editing and singlePoint mode and there is no point already
    if (isEditing && singlePoint && mapIsEmpty(currentMap)) {
      // console.debug(
      //   `initMap(): currentMap has 0 links - currentMap?.mapLinks?.length: '${currentMap?.mapLinks?.length}', currentMap:`,
      //   currentMap
      // )
      setTimeout(() => {
        setDropPinMode(true)
      }, 150)
    }
  }

  const mapIsEmpty = currentMap => {
    return (currentMap?.mapLinks?.length ?? 0) < 1
  }

  const handleMapClick = e => {
    const lngLat = e.lngLat
    if (
      dropPinMode &&
      locations[locations.length - 1]?.lat !== lngLat.lat &&
      locations[locations.length - 1]?.lng !== lngLat.lng
    ) {
      const latiLongString = formatLatiLongAsString(lngLat.lat, lngLat.lng)
      let newLocations = [...locations]
      const addedLocation = {
        id: createTemporaryMarkerId(lngLat.lat, lngLat.lng),
        lng: lngLat.lng,
        lat: lngLat.lat,
        address: latiLongString,
        title: latiLongString,
      }
      if (!newLocations[newLocations?.length - 1]?.id) {
        const index =
          newLocations?.length - 1 < 0 ? 0 : newLocations?.length - 1
        newLocations[index] = addedLocation
      } else {
        newLocations.push(addedLocation)
      }
      setLocations(newLocations)
      setCurrentMarkerIndex(newLocations.length - 1)
      setDropPinMode(false)
    }
  }

  const setupMapLinks = useCallback(() => {
    if (currentMap?.mapLinks?.length > 0) {
      let mapLocations = []
      currentMap?.mapLinks?.forEach((mapLink, index) => {
        const linkDetails = mapLink.target
        let address = ''
        if (mapLink.instanceType === 'address') {
          address = linkDetails?.freeText
        }
        mapLocations.push({
          id: mapLink?.id,
          title: mapLink?.title,
          address,
          desc: mapLink?.description,
          lat: linkDetails?.latiGed,
          lng: linkDetails?.longGed,
          icon: PlaceIcon,
          photo: linkDetails?.photo,
        })
      })

      setLocations(mapLocations)
    }
  }, [currentMap?.mapLinks])

  useEffect(() => {
    setupMapLinks()
  }, [currentMap?.mapLinks, setupMapLinks])

  useEffect(() => {
    if (locations?.length > 0) {
      layoutMarkers()
    }
    // eslint-disable-next-line
  }, [map.current, locations, layoutMarkers])

  useEffect(() => {
    if (map?.current) {
      if (dropPinMode) {
        map.current.getCanvas().style.cursor = 'crosshair'
        map.current?.on('click', handleMapClick)
        // console.debug(
        //   `Map.useEffect([dropPinMode]): set cursor style to 'crosshair' and registered handleMapClick...'`
        // )
      } else {
        map.current?.off('click', handleMapClick)
        map.current.getCanvas().style.cursor = 'auto'
      }
    }

    return () => {
      if (map?.current) {
        map?.current?.off('click', handleMapClick)
        map.current.getCanvas().style.cursor = 'auto'
      }
    }
    // eslint-disable-next-line
  }, [dropPinMode])

  useEffect(() => {
    initMap()
    create3DTerrain()
  })

  const fullscreenChangeEventListener = useCallback(
    event => {
      // console.debug(`Map.fullscreenChangeEventListener() fired`)
      if (!document.fullscreenElement) {
        setMapIsFullScreen(false)
        if (mapRoot.current) {
          mapRoot.current.removeEventListener(
            'fullscreenchange',
            fullscreenChangeEventListener
          )
        }
      }
    },
    [setMapIsFullScreen]
  )

  useEffect(() => {
    if (mapRoot.current) {
      if (mapIsFullScreen === true) {
        mapRoot.current.requestFullscreen()
        mapRoot.current.addEventListener(
          'fullscreenchange',
          fullscreenChangeEventListener
        )
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    }
  }, [mapIsFullScreen, fullscreenChangeEventListener])

  const fullWindowStyle = useMemo(() => {
    return {
      position: 'fixed',
      top: 0,
      left: 0,
      //height: '100%',
      //width: '100%',
      zIndex: 2000,
    }
  }, [])

  const nonFullWindowStyle = useMemo(
    () => {
      return {
        position: 'relative', // this needs to be relative or our controls (up/down/3d) disappear
        //height: inlineMapHeight,
      }
    },
    [
      //inlineMapHeight
    ]
  )

  useEffect(() => {
    // console.debug(
    //   `Map.useEffect([mapIsFullWindow=${mapIsFullWindow}]): calling setMapRootStyle()...`
    // )
    setMapHeight(mapIsFullWindow ? '100%' : inlineMapHeight)
    setMapRootStyle(mapIsFullWindow ? fullWindowStyle : nonFullWindowStyle)
    // the above style change puts the map in a much larger container so ask the MapBox SDK
    // to resize itself.
    if (map.current) {
      // we need the reactions to the above setxxx()s to execute to apply the changed styles BEFORE we ask MapBox to resize itself
      // so use a setTimeout() with a 0ms delay to run on the next tick.
      const mc = map.current
      setTimeout(() => {
        mc.resize()
      }, 0)
      // } else {
      //   console.debug(
      //     `Map.useEffect([mapIsFullWindow=${mapIsFullWindow}]): map.current is NOT set, cannot call resize()...`
      //   )
    }
  }, [mapIsFullWindow, fullWindowStyle, nonFullWindowStyle, inlineMapHeight])

  return (
    <Box
      id="map-root"
      ref={mapRoot}
      sx={{
        height: mapHeight,
        width: '100%',
        display: 'flex',
        ...mapRootStyle, // either fullWindowStyle or nonFullWindowStyle
      }}
    >
      <div
        id="map-container"
        ref={mapContainer}
        className={classes.mapContainer}
        style={{ width: '100%' }}
      />
      {locations.map(item => {
        const marker = item?.marker
        const MarkerIcon = item?.icon || PlaceIcon
        if (marker) {
          const isSelected = item?.id === locations?.[currentMarkerIndex]?.id
          return (
            <div key={`map_marker_${item.id}`}>
              {createPortal(
                <div
                  key={`map_marker_${item.id}`}
                  style={{
                    transform: isSelected ? 'scale(1.5)' : 'scale(1.1)',
                    transitionDuration: '0.3s',
                  }}
                >
                  <MarkerIcon
                    fontSize="large"
                    color="error"
                    stroke={isSelected ? 'white' : 'black'}
                  />{' '}
                </div>,
                marker.getElement()
              )}
            </div>
          )
        } else return <div key={`empty_map_marker_${item?.id}`}></div>
      })}

      {interactive && (
        <>
          <MapControls map={map.current} flyTo={flyTo} threeD={threeD} />
        </>
      )}
      <Box
        id="weAreLeftControlsAndPanel"
        sx={{
          position: 'absolute', // absolute allows this box to overlay the map
          marginLeft: '10px',
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          id="weareLeftControlsRow"
          sx={{
            display: 'flex',
            alignItems: 'stretch', // makes the button container be the same height as the maximise button container
            gap: '8px',
          }}
        >
          {allowMaximiseButton &&
            (!mapIsFullWindow || (allowFullscreen && !mapIsFullScreen)) && (
              // maximise button
              <Box
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.6)',
                  padding: 0.3,
                  display: 'flex',
                  borderRadius: '4px',
                }}
              >
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={e => {
                    if (onFullWindowButtonClick) {
                      return onFullWindowButtonClick(e)
                    }
                    if (mapIsFullScreen) {
                      setMapIsFullScreen(false)
                      setMapIsFullWindow(false)
                    } else {
                      if (mapIsFullWindow) {
                        setMapIsFullScreen(true)
                      } else {
                        setMapIsFullWindow(true)
                      }
                    }
                  }}
                >
                  {/* putting a close button in the same place is confusing when there are three levels of zoom {mapIsFullScreen ? (
                    <CloseFullscreenIcon size="large" />
                  ) : ( */}
                  <FullscreenIcon size="large" />
                  {/* )} */}
                </IconButton>
                {/* </Box> */}
              </Box>
            )}
          {interactive && flybyButtonText && showFlybyButton && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(255,255,255,0.6)',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              <Button
                sx={{ margin: 'auto' }}
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => {
                  setFlyToMarkersIn2d(false)
                  navigateMarkers('next', true)
                }}
              >
                {flybyButtonText}
              </Button>
            </Box>
          )}
        </Box>
        {(clickableMarkers ||
          flybyButtonText ||
          mapIsFullScreen ||
          mapIsFullWindow) && (
          // this includes the Close button
          <MapSidePanel
            id={id}
            closeMap={params => {
              if (mapIsFullScreen) {
                setMapIsFullScreen(false)
              } else if (mapIsFullWindow) {
                setMapIsFullWindow(false)
              }
              if (closeMap) {
                closeMap(params)
              }
            }}
            currentMap={currentMap}
            isEditing={isEditing}
            navigateMarkers={navigateMarkers}
            setLocations={setLocations}
            locations={locations}
            currentMarker={locations[currentMarkerIndex]}
            handleRefreshMapBlock={handleRefreshMapBlock}
            resetMap={resetMap}
            setCurrentMarkerIndex={setCurrentMarkerIndex}
            flyToEditMode={flyToEditMode}
            preview={preview}
            setPreview={setPreview}
            dropPinMode={dropPinMode}
            setDropPinMode={setDropPinMode}
            maxHeight={mapHeight === '100%' ? undefined : mapHeight - 50}
            fixedWidth={!isEditing ? 250 : null}
            showLatLong={sidePanelShowLatLong}
            flybyButtonText={null} //no longer show the flyby button in the MapSidePanel - it is done above
            mapIsFullScreen={mapIsFullScreen}
            mapIsFullWindow={mapIsFullWindow}
            maxTitleLines={maxSidepanelTitleLines}
            markerInfoPanelEnabled={markerInfoPanelEnabled}
            singlePoint={singlePoint}
            treeSlug={treeSlug}
            markerItemType={markerItemType} // used when creating link to navigate to item when clicked
          />
        )}
      </Box>
    </Box>
  )
}

export default Map
