import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'

import { Typography } from 'src/modules/ui'
import SelectOthers from '../accountAdmin/SelectOther'
import { UserContext } from '../accountAdmin/Users'

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  group: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  dropdownContainer: {
    width: '35%',
  },
}))

const TypeAhead = ({ notYetInvitedUsers, value, setValue }) => {
  const classes = useStyles()
  const { IndividualComponent } = useContext(UserContext)

  const handleDelete = id => {
    setValue(value.filter(v => v.id !== id))
  }

  const selectedNotYetInvitedUsers = value
    ? value.filter(
        ind =>
          !ind.user ||
          (ind.user.state !== 'INVITED' && ind.user.state !== 'ACTIVE')
      )
    : []

  return (
    <div>
      <Typography className={classes.header} variant="h6">
        Find relation to invite
      </Typography>
      <SelectOthers
        dropdownClassName={classes.dropdownContainer}
        otherUsers={notYetInvitedUsers}
        value={value}
        setValue={setValue}
      />
      {selectedNotYetInvitedUsers.length > 0 && (
        <div className={classes.group}>
          {selectedNotYetInvitedUsers.map(ind => (
            <IndividualComponent
              individual={ind}
              key={`indLine-${ind.id}`}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default TypeAhead
