import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { Actions } from 'src/modules/page/LinkedPageList'

import BasePageList from 'src/modules/page/BasePageList'
import { setHomeViewConfig } from './homeSlice'
import { HomeViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../common/viewConfigUtils'

const HomePageList = ({
  canCreateItem = true,
  type,
  action,
  selector,
  queryAsSubject = false,
}) => {
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatch = useDispatch()
  const dispatchFetchHomePages = useActionDispatcher(action)
  const homePages = useSelector(selector)
  const { next, results } = homePages

  const makeArgs = useCallback(
    page => {
      const args = { page, type, viewConfigQueryParams }
      return args
    },
    [type, viewConfigQueryParams]
  )

  useEffect(() => {
    const fetchHomePages = async () => {
      await dispatchFetchHomePages(makeArgs(0))
    }
    if (!results.length) {
      fetchHomePages()
    }
  }, [dispatchFetchHomePages, makeArgs, type, results.length])

  const refresh = () => {
    dispatchFetchHomePages(makeArgs(0))
  }

  const handleFetchMore = () => {
    if (dispatchFetchHomePages.status === 'loading') {
      return
    }
    dispatchFetchHomePages(makeArgs(homePages.page + 1))
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setHomeViewConfig({ type, viewConfig }))
    dispatchFetchHomePages(makeArgs(0))
  }

  const { sort, hierarchical, ancestralOnly, showMap } = getViewConfig(
    homePages,
    viewConfigQueryParams
  )

  return (
    <BasePageList
      actions={
        canCreateItem && (
          <Actions
            viewConfigSelectContainer={HomeViewConfigSelectorContainer}
            type={type}
            onRefreshRequired={refresh}
            sort={sort}
            hierarchical={hierarchical}
            showMap={showMap}
            ancestralOnly={ancestralOnly}
            onSetViewConfig={handleSetViewConfig}
          />
        )
      }
      {...{
        dispatchFetchPage: dispatchFetchHomePages,
        next,
        handleFetchMore,
        pages: homePages,
        results,
        type,
      }}
    />
  )
}

export default HomePageList
