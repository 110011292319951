import React, { useRef, useState } from 'react'
import {
  MEDIA_ROW_ALIGNMENT_COVER,
  MEDIA_ROW_HEIGHT_LARGE,
  MEDIA_ROW_HEIGHT_MAP,
} from './contentBlockConstants'
import { Box, Dialog, Typography } from '@mui/material'
import { useActionDispatcher } from '../app'
import { updateContentBlock } from './writeArticleSlice'
import { isNull } from 'lodash'
import SiteWideIcon from '../ui/SiteWideIcon'
import { INSTANCE_TYPE_MAP } from '../app/links'
import Map from '../map/Map'
import { DisplayCaption } from './EditablePhoto'
import { useLocation } from 'react-router-dom'
import { useIsAlphaFeatureUser } from '../auth/hooks'

export const MapContainer = ({ children, onClick, rowHeight }) => (
  <Box
    onClick={onClick}
    sx={{
      height: rowHeight,
      width: '100%',
      cursor: 'pointer',
      position: 'relative',
    }}
  >
    {children}
  </Box>
)

const AddMapIcon = ({ onClick, width, height, label = 'Create map' }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width,
        height,
        backgroundImage: 'url(/map-placeholder.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        sx={{
          width,
          height,
          cursor: 'pointer',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'rgba(255,255,255,0.6)',
        }}
      >
        <SiteWideIcon
          color="primary"
          instanceType={INSTANCE_TYPE_MAP}
          fontSize={'large'}
        />
        <Typography variant="subtitle5" px={2} mt={1} textAlign="center">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

const MapBlock = ({
  contentId,
  id,
  mediaRowAlignment = MEDIA_ROW_ALIGNMENT_COVER,
  mediaRowMeta,
  mapBlock,
}) => {
  const containerRef = useRef()
  const location = useLocation()
  const [mapEditorOpen, setMapEditorOpen] = useState(false)
  const allowEditMap = useIsAlphaFeatureUser()?.maps
  let currentMap = mapBlock
  const isEditing = location?.pathname?.includes('write-article')

  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)

  const openMapEditor = () => {
    if (allowEditMap || !isEditing) setMapEditorOpen(true)
  }

  const closeMapEditor = () => {
    setMapEditorOpen(false)
  }

  const handleRefreshMapBlock = async mapBlock => {
    await dispatchUpdateContentBlock({
      contentId,
      id,
      setMapBlock: mapBlock?.id,
    })

    closeMapEditor()
  }

  const rowHeight = MEDIA_ROW_HEIGHT_MAP[MEDIA_ROW_HEIGHT_LARGE] || mediaRowMeta
  const mapIsEmpty = isNull(mapBlock)
  return (
    <Box ref={containerRef}>
      <Dialog
        open={mapEditorOpen}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: 'black',
          },
        }}
      >
        <Map
          // id={mapBlock?.id}
          isEditing={isEditing}
          handleRefreshMapBlock={handleRefreshMapBlock}
          closeMap={closeMapEditor}
          currentMap={currentMap}
          initialMapIsFullWindow={true}
        />
      </Dialog>
      {mapIsEmpty ? (
        isEditing ? (
          <>
            <AddMapIcon
              onClick={openMapEditor}
              height={rowHeight}
              width={'100%'}
            />
            <div></div>
          </>
        ) : null
      ) : (
        <MapContainer onClick={openMapEditor} rowHeight={rowHeight}>
          <Map
            currentMap={currentMap}
            isArticle={true}
            mapOpen={mapEditorOpen}
          />
        </MapContainer>
      )}
      {mapBlock?.title && (
        <Box py={2}>
          <DisplayCaption photo={{ ...mapBlock, caption: mapBlock?.title }} />
        </Box>
      )}
    </Box>
  )
}

export default MapBlock
