import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchPublicPageContent,
  fetchPublicPageDocuments,
  selectPublicContent,
  selectPublicDocuments,
  setPublicViewConfig,
} from './pageSlice'
import { BaseContentList } from 'src/modules/page'
import PublicArticleCard from './PublicArticleCard'
import PublicPhotoAlbum from '../content/PublicPhotoAlbum'
import { PublicContext } from '../contexts'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from '../../app/links'
import PublicActionBar from './PublicActionBar'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../../common/viewConfigUtils'

const instanceTypeMap = {
  [INSTANCE_TYPE_ARTICLE]: PublicArticleCard,
  [INSTANCE_TYPE_DOCUMENT]: PublicArticleCard,
  [INSTANCE_TYPE_PHOTO_ALBUM]: PublicPhotoAlbum,
}

const PublicPageContentList = ({
  target,
  state,
  pageType,
  block_limit,
  block_type,
}) => {
  const location = useLocation()
  const isHome = location.pathname.includes('home')
  const dispatch = useDispatch()
  const viewConfigQueryParams = useViewConfigQueryParams()
  const { treeSlug } = useContext(PublicContext)
  const dispatchFetchPageContent = useActionDispatcher(
    {
      [INSTANCE_TYPE_DOCUMENT]: fetchPublicPageDocuments,
    }[pageType] ?? fetchPublicPageContent
  )
  const content = useSelector(
    {
      [INSTANCE_TYPE_DOCUMENT]: selectPublicDocuments,
    }[pageType] ?? selectPublicContent
  )
  const { next, results } = content
  const [loading, setLoading] = useState()

  const makeArgs = useCallback(
    page => ({
      page,
      pageType,
      target,
      treeSlug,
      block_limit,
      block_type,
    }),
    [target, pageType, treeSlug, block_limit, block_type]
  )

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true)

      await dispatchFetchPageContent(makeArgs(0))
      setLoading(false)
    }
    setLoading(true)
    fetchContent()
  }, [dispatchFetchPageContent, makeArgs])

  const handleFetchMore = () => {
    if (dispatchFetchPageContent.status === 'loading') {
      return
    }
    dispatchFetchPageContent(makeArgs(content.page + 1))
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    content,
    viewConfigQueryParams
  )

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setPublicViewConfig({ type: pageType, viewConfig }))
    dispatchFetchPageContent(makeArgs(0))
  }

  return (
    <>
      <PublicActionBar
        type={pageType}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        handleSetViewConfig={handleSetViewConfig}
        isHome={isHome}
      />
      <BaseContentList
        {...{
          content,
          instanceTypeMap,
          dispatchFetchContent: dispatchFetchPageContent,
          handleFetchMore,
          loading,
          next,
          results,
          type: pageType,
        }}
      />
    </>
  )
}

export default PublicPageContentList
