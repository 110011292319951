import React, { useEffect, useRef, useState } from 'react'
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Dialog, Box, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isEqual } from 'lodash'

import { useActionDispatcher } from 'src/modules/app'
import { Button, ConfirmDialog, Typography } from 'src/modules/ui'
import AddMediaDialog from './AddMediaDialog'

import {
  deleteAlbum,
  updateAlbum,
  updatePhoto,
  removeMediaFromAlbum,
  reorderPhotos,
  fetchAlbum,
} from './photoSlice'
import ImagePreviewTile from './ImagePreviewTile'
import AlbumForm from './AlbumForm'

import AlbumModalLayout from './AlbumModalLayout'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'
import { handleImgName } from './MediaDropzone'
import { ACTION_ALL_ACCESS, ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_PHOTO_ALBUM } from '../app/links'
import { useDispatch } from 'react-redux'
import { resetHomePageAlbums } from '../home/homeSlice'
import { resetPageAlbums } from '../page/pageSlice'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden',
    },
  },
}))

const EditAlbumDialog = ({
  album,
  contentBlockId,
  onFinishedUpdating,
  onFinishedDeleting,
  open = false,
  trigger,
  hideSidePanel = false,
  allowAddFromYouTube = true,
  showAddTagsOnNewUploads = true,
  addMediaButtonLabel,
  hideDateTakenDialog,
  autoOpenAddMediaDialog,
}) => {
  const dispatchFetchAlbum = useActionDispatcher(fetchAlbum)
  const [albumLoaded, setAlbumLoaded] = useState(false)
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = () => {
    if (!albumLoaded) {
      dispatchFetchAlbum(
        { id: album.id, allMedia: true },
        {
          errorNotification: false,
        }
      ).then(() => {
        setModalOpen(true)
        setAlbumLoaded(true)
      })
    } else {
      setModalOpen(true)
    }
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOnFinishedUpdating = () => {
    handleCloseModal()
    if (onFinishedUpdating) {
      onFinishedUpdating()
    }
  }

  return (
    <>
      {trigger({
        onClick: handleShowModal,
        loading: dispatchFetchAlbum.status === 'loading',
      })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
        className={classes.dialog}
      >
        <EditAlbum
          onClose={handleCloseModal}
          album={album}
          contentBlockId={contentBlockId}
          onFinishedUpdating={handleOnFinishedUpdating}
          onFinishedDeleting={onFinishedDeleting}
          hideSidePanel={hideSidePanel}
          allowAddFromYouTube={allowAddFromYouTube}
          showAddTagsOnNewUploads={showAddTagsOnNewUploads}
          addMediaButtonLabel={addMediaButtonLabel}
          hideDateTakenDialog={hideDateTakenDialog}
          autoOpenAddMediaDialog={autoOpenAddMediaDialog}
        />
      </Dialog>
    </>
  )
}

const EditAlbum = ({
  onClose,
  album,
  contentBlockId,
  onFinishedDeleting,
  hideSidePanel = false,
  allowAddFromYouTube = true,
  showAddTagsOnNewUploads = true,
  addMediaButtonLabel,
  hideDateTakenDialog = false,
  autoOpenAddMediaDialog = false,
}) => {
  const { title, media, links } = album
  const presetTargets = links.map(link => link.target)

  const dispatchUpdateAlbum = useActionDispatcher(updateAlbum)
  const dispatchDeleteAlbum = useActionDispatcher(deleteAlbum)

  const [targets, setTargets] = useState(presetTargets)
  const [mediaTiles, setMediaTiles] = useState(media)
  const [previewTiles, setPreviewTiles] = useState([])

  const handleChangeTags = tags => {
    setTargets(tags)
  }

  const initialValues = {
    albumTitle: title,
  }

  const targetChange = !isEqual(presetTargets, targets)

  const noTagsDialogRef = useRef()

  const handleUpdate = async (
    { albumTitle: title },
    { setErrors, resetForm },
    allowNoTags = false
  ) => {
    if (!allowNoTags && targets.length === 0) {
      noTagsDialogRef.current.showDialog(async () => {
        //call this function again but with allowNoTags=true
        handleUpdate(
          { albumTitle: title },
          { setErrors, resetForm },
          (allowNoTags = true)
        )
      })
      return
    }

    try {
      await dispatchUpdateAlbum(
        {
          albumId: album.id,
          title,
          targets,
        },
        { successNotification: 'Album changes saved' }
      ).unwrap()
    } catch (err) {
      setErrors(err.data)
      return
    }
    resetForm()
    onClose()
  }

  const handleDeleteAlbum = async () => {
    try {
      await dispatchDeleteAlbum(
        { albumId: album.id },
        { successNotification: 'Album deleted' }
      ).unwrap()
      if (onFinishedDeleting) {
        onFinishedDeleting()
      }
    } catch (err) {}
  }

  const onFinishedUploading = ({ media }) => {
    setMediaTiles([...media])
  }

  useEffect(() => {
    if (!isEqual(media, mediaTiles)) {
      setMediaTiles(media)
    }
    // Only run when `media` updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, setMediaTiles])

  return (
    <>
      <NoTagsConfirmationDialog ref={noTagsDialogRef} />
      <AlbumModalLayout
        onClose={onClose}
        content={
          <Stack
            fullWidth
            sx={{
              width: '100%',
            }}
          >
            <Panel
              {...{
                mediaTiles,
                onMediaTilesUpdated: setMediaTiles,
                album,
                contentBlockId,
                previewTiles,
                setPreviewTiles,
                onFinishedUploading,
                allowAddFromYouTube,
                showAddTagsOnNewUploads,
                addMediaButtonLabel,
                hideDateTakenDialog,
                autoOpenAddMediaDialog,
              }}
            />
            {hideSidePanel && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  width: '100%',
                }}
              >
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  sx={{ m: 2.5 }}
                  onClick={onClose}
                >
                  Save & Close
                </Button>
              </Box>
            )}
          </Stack>
        }
        sidePanel={
          hideSidePanel ? null : (
            <AlbumForm
              {...{
                action: (
                  <ConfirmDialog
                    onConfirm={handleDeleteAlbum}
                    submitText="Delete"
                    submitColor="error"
                    trigger={props => (
                      <Button
                        permissionAction={ACTION_ALL_ACCESS}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        {...props}
                      >
                        Delete Album
                      </Button>
                    )}
                  >
                    <Typography>
                      Are you sure you want to delete this album?
                    </Typography>
                  </ConfirmDialog>
                ),
                album,
                editForm: true,
                formTitle: 'Edit Album',
                handleChangeTags,
                onSubmit: handleUpdate,
                initialValues,
                disableSubmit: !targetChange,
                presetTargets,
                selectedTargets: targets,
                submitText: 'Save Changes',
                onClose,
              }}
            />
          )
        }
      />
    </>
  )
}

export const SortableTile = ({ children, dragging, ...props }) => {
  const [disableDragging, setDisableDragging] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id, disabled: disableDragging })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
    zIndex: dragging ? 1 : 0,
  }

  const handleDisableDragging = () => setDisableDragging(true)
  const handleEnableDragging = () => setDisableDragging(false)

  return (
    // eslint-disable-next-line
    <div ref={setNodeRef} style={style} dragging={dragging}>
      {React.cloneElement(children, {
        dragProps: { ...listeners, ...attributes },
        onOpenDialog: handleDisableDragging,
        onCloseDialog: handleEnableDragging,
        ...props,
      })}
    </div>
  )
}

const Panel = ({
  album,
  contentBlockId,
  mediaTiles,
  onMediaTilesUpdated,
  previewTiles,
  setPreviewTiles,
  onFinishedUploading,
  allowAddFromYouTube,
  showAddTagsOnNewUploads,
  addMediaButtonLabel = 'Add Media',
  hideDateTakenDialog = false,
  autoOpenAddMediaDialog = false,
}) => {
  const dispatchDeletePhoto = useActionDispatcher(removeMediaFromAlbum)
  const dispatchReorderPhotos = useActionDispatcher(reorderPhotos)
  const dispatchUpdatePhoto = useActionDispatcher(updatePhoto)
  const [idDragging, setIdDragging] = useState(null)
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(sensor)
  const dispatch = useDispatch()

  const resetAlbumList = () => {
    dispatch(resetHomePageAlbums())
    dispatch(resetPageAlbums())
  }

  const handleRemovePreviewTile = async ({ id: mediaId, order }) => {
    try {
      await dispatchDeletePhoto(
        { mediaId, contentId: album.id, order, contentBlockId },
        { successNotification: 'Media removed from collection' }
      ).unwrap()
      resetAlbumList()
    } catch (err) {}
  }

  const handleUpdateTitle = ({ oldTitle, newTitle, id: existingId }) => {
    onMediaTilesUpdated(
      mediaTiles.map(({ title, id, ...rest }) => {
        if (id === existingId && title === oldTitle) {
          const imgTitle = handleImgName(newTitle)
          return { title: imgTitle, id, ...rest }
        } else {
          return { title, id, ...rest }
        }
      })
    )
    resetAlbumList()
  }

  const handleOnUpdateDates = async ({ id: existingId, dateTakenGed }) => {
    await dispatchUpdatePhoto(
      {
        mediaId: existingId,
        dateTakenGed,
      },
      { successNotification: 'Photo date updated' }
    ).unwrap()
    onMediaTilesUpdated(
      mediaTiles.map(({ id, ...rest }) =>
        id === existingId ? { id, ...rest, dateTakenGed } : { id, ...rest }
      )
    )
  }

  const handleBlurUpdateTitle = async ({ id: mediaId, oldTitle, newTitle }) => {
    try {
      if (oldTitle !== newTitle) {
        await dispatchUpdatePhoto(
          {
            mediaId,
            title: newTitle,
          },
          { successNotification: 'Photo title updated' }
        ).unwrap()
      }
    } catch (err) {
      console.log(err)
      return
    }
  }

  const handleDragEnd = async event => {
    const { active, over } = event
    setIdDragging(null)

    if (active.id !== over.id) {
      const oldIndex = mediaTiles.findIndex(({ id }) => id === active.id)
      const newIndex = mediaTiles.findIndex(({ id }) => id === over.id)
      const newTiles = arrayMove(mediaTiles, oldIndex, newIndex)
      onMediaTilesUpdated(newTiles)
      resetAlbumList()
      const putBelowPhoto = newIndex ? newTiles[newIndex - 1].id : undefined
      try {
        await dispatchReorderPhotos({
          contentId: album.id,
          id: contentBlockId ?? album.albumContentBlockId,
          photo: active.id,
          putBelowPhoto,
        }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <Stack sx={{ width: '100%', overflow: 'auto' }}>
      <Box sx={{ p: 2.5, pl: 3, pb: 1.5 }}>
        <AddMediaDialog
          {...{
            album,
            previewTiles,
            setPreviewTiles,
            onFinishedUploading,
          }}
          trigger={props => (
            <Button
              permissionAction={ACTION_CREATE}
              permissionParams={{ instanceType: INSTANCE_TYPE_PHOTO_ALBUM }}
              {...props}
            >
              {addMediaButtonLabel}
            </Button>
          )}
          showYouTube={allowAddFromYouTube}
          showAddTagsOnNewUploads={showAddTagsOnNewUploads}
          open={autoOpenAddMediaDialog}
        />
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', ml: 2 }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={({ active }) => {
            setIdDragging(active.id)
          }}
          onDragEnd={handleDragEnd}
          onDragCancel={() => setIdDragging(null)}
        >
          <SortableContext items={mediaTiles} strategy={rectSortingStrategy}>
            <Stack
              direction="row"
              sx={{
                flexWrap: 'wrap',
              }}
            >
              {mediaTiles.map(
                (
                  { id, title, fileMedium, fileThumbnail, order, ...rest },
                  i
                ) => (
                  // ...rest needed to move to top of props list as images from the photo album don't have a file field but after updating an image a file field is returned causing the component to use the wrong URL in the img source
                  <SortableTile
                    {...{
                      ...rest,
                      id,
                      key: i,
                      title: title,
                      file: fileMedium || fileThumbnail,
                      onRemovePreviewTile: handleRemovePreviewTile,
                      onUpdateTitle: handleUpdateTitle,
                      onBlurUpdateTitle: handleBlurUpdateTitle,
                      onUpdateDates: handleOnUpdateDates,
                      dragging: idDragging === id,
                      order,
                    }}
                    confirmDialog
                  >
                    <ImagePreviewTile
                      draggable
                      dragging={idDragging === id}
                      hideDateTakenDialog={hideDateTakenDialog}
                    />
                  </SortableTile>
                )
              )}
            </Stack>
          </SortableContext>
        </DndContext>
      </Box>
    </Stack>
  )
}

export default EditAlbumDialog
