import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
  styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/system'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'

import { Thumbnail } from 'src/modules/photo'
import { THUMB_SIZE_MICRO } from 'src/modules/photo/Thumbnail'
import {
  formatIndividualDates,
  formatIndividualName,
} from 'src/modules/ui/individualUtils'
import { IconButton, Link } from 'src/modules/ui'
import {
  selectFamilyByFamilyId,
  selectIndividualById,
} from 'src/modules/viewer/viewerSlice'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  generateLinkForObject,
  generatePublicLinkForObject,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
} from '../app/links'
import { PublicContext } from '../public/contexts'
import SiteWideIcon from '../ui/SiteWideIcon'

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}))

const HoverBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  '&:hover': {
    background: theme.palette.backgroundGrey.main,
  },
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#F2F0F4 !important',
  cursor: 'pointer',
  marginBottom: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  padding: theme.spacing(0.25, 0),
  height: 'auto',
  '.MuiChip-label': {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    whiteSpace: 'normal',
  },
}))

export const InnerLinkChips = ({ links, blurFilter }) => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug

  return (
    links &&
    links
      .filter(x => x.target)
      .map(({ target, display, instanceType }, index) => (
        <Link
          to={
            publicTreeSlug
              ? generatePublicLinkForObject(
                  publicTreeSlug,
                  instanceType,
                  target
                )
              : generateLinkForObject(authorisedTreeSlug, instanceType, target)
          }
          key={index}
          underline="none"
        >
          <StyledChip
            label={display}
            size="small"
            sx={{ filter: blurFilter }}
          />
        </Link>
      ))
  )
}

export const LinkChips = ({ links, ...props }) => {
  return (
    <Stack direction="row" flexWrap="wrap" {...props}>
      <InnerLinkChips links={links} />
    </Stack>
  )
}

const LINK_LIMIT = 2

export const AllLinksModal = ({
  links,
  open,
  onClose,
  title,
  LinkType,
  blurFilter = '',
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {title}
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent>
        {links.map(link => (
          <LinkType key={link.target} link={link} blurFilter={blurFilter} />
        ))}
      </DialogContent>
    </Dialog>
  )
}

export const ShowHideLinks = ({
  links = [],
  icon,
  LinkType,
  spaceAfter = 0,
  title,
  blurFilter = '',
}) => {
  let initiallyShownLinks = links
  const [showAllLinks, setShowAllLinks] = useState(false)
  let nHiddenLinks = links.length - LINK_LIMIT
  nHiddenLinks = Math.max(nHiddenLinks, 0)
  initiallyShownLinks = Array.from(links).splice(0, LINK_LIMIT)

  return (
    <>
      {!!initiallyShownLinks?.length && (
        <>
          <AllLinksModal
            links={links}
            open={showAllLinks}
            title={title}
            onClose={() => setShowAllLinks(false)}
            LinkType={LinkType}
          />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              ml: { xs: 2, md: 0 },
            }}
          >
            <Box sx={{ display: 'flex', gap: 0.6 }}>
              {icon && <Box sx={{ mr: 0.5 }}>{icon}</Box>}
              <Box sx={{ flexWrap: 'wrap', display: 'flex' }}>
                <InnerLinkChips
                  links={initiallyShownLinks}
                  blurFilter={blurFilter}
                />
                {!!nHiddenLinks && (
                  <StyledChip
                    sx={{ filter: blurFilter }}
                    label={`+ ${nHiddenLinks} more`}
                    size="small"
                    onClick={() => setShowAllLinks(true)}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mr: spaceAfter }}></Box>
        </>
      )}
    </>
  )
}

export const SplitIndividualFamilyLinkChips = ({ links, ...props }) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  let familyLinks = links.filter(
    link => link.instanceType === INSTANCE_TYPE_FAMILY
  )
  let individualLinks = links.filter(
    link => link.instanceType === INSTANCE_TYPE_INDIVIDUAL
  )

  let locationLinks = links.filter(
    link => link.instanceType === INSTANCE_TYPE_LOCATION
  )

  let eventLinks = links.filter(
    link => link.instanceType === INSTANCE_TYPE_EVENT
  )

  let artefactLinks = links.filter(
    link => link.instanceType === INSTANCE_TYPE_ARTEFACT
  )

  return (
    <Stack
      direction={mobileBreakpoint ? 'column' : 'row'}
      flexWrap="wrap"
      {...props}
    >
      <ShowHideLinks
        links={familyLinks}
        icon={<PeopleIcon fontSize="small" />}
        LinkType={FamilyLink}
        title="Families"
        spaceAfter={1}
      />
      <ShowHideLinks
        links={individualLinks}
        icon={<PersonIcon fontSize="small" />}
        LinkType={IndividualLink}
        title="Individuals"
      />
      <ShowHideLinks
        links={locationLinks}
        icon={
          <SiteWideIcon
            instanceType={INSTANCE_TYPE_LOCATION}
            fontSize={'small'}
          />
        }
        LinkType={GeneralLink}
        title="Places"
      />
      <ShowHideLinks
        links={eventLinks}
        icon={
          <SiteWideIcon instanceType={INSTANCE_TYPE_EVENT} fontSize={'small'} />
        }
        LinkType={GeneralLink}
        title="Occasions"
      />
      <ShowHideLinks
        links={artefactLinks}
        icon={
          <SiteWideIcon
            instanceType={INSTANCE_TYPE_ARTEFACT}
            fontSize={'small'}
          />
        }
        LinkType={GeneralLink}
        title="Artefacts"
      />
    </Stack>
  )
}

export const FamilyLink = ({ link }) => {
  const { instanceType, display, target } = link
  const family = useSelector(selectFamilyByFamilyId(target))
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug

  return (
    <HoverBox>
      <Link
        to={
          publicTreeSlug
            ? generatePublicLinkForObject(publicTreeSlug, instanceType, target)
            : generateLinkForObject(authorisedTreeSlug, instanceType, target)
        }
        key={target}
        underline="none"
      >
        <Stack direction="row">
          <Box sx={{ mr: 2 }}>
            {!!family.photo && (
              <Thumbnail
                round
                size={THUMB_SIZE_MICRO}
                file={family.photo.fileThumbnail}
                withEditMedia={false}
              />
            )}
            {!family.photo && <PeopleIcon />}
          </Box>
          <Box>{display}</Box>
        </Stack>
      </Link>
    </HoverBox>
  )
}

export const IndividualLink = ({ link }) => {
  const { instanceType, target } = link
  const individual = useSelector(selectIndividualById(target))
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug

  return (
    <HoverBox>
      <Link
        to={
          publicTreeSlug
            ? generatePublicLinkForObject(publicTreeSlug, instanceType, target)
            : generateLinkForObject(authorisedTreeSlug, instanceType, target)
        }
        key={target}
        underline="none"
      >
        <Stack direction="row">
          <Box sx={{ mr: 2 }}>
            <Thumbnail
              round
              size={THUMB_SIZE_MICRO}
              file={
                individual?.photo?.fileThumbnail || '/person-placeholder.png'
              }
              withEditMedia={false}
            />
          </Box>
          <Stack>
            <Box>{formatIndividualName(individual)}</Box>
            <Box>{individual && formatIndividualDates(individual)}</Box>
          </Stack>
        </Stack>
      </Link>
    </HoverBox>
  )
}

export const GeneralLink = ({ link }) => {
  const { instanceType, target } = link
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug

  return (
    <HoverBox>
      <Link
        to={
          publicTreeSlug
            ? generatePublicLinkForObject(publicTreeSlug, instanceType, target)
            : generateLinkForObject(authorisedTreeSlug, instanceType, target)
        }
        key={target}
        underline="none"
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <SiteWideIcon instanceType={instanceType} fontSize={'small'} />
          <div>{link.display}</div>
        </Stack>
      </Link>
    </HoverBox>
  )
}

export default LinkChips
