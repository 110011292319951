import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import {
  selectUser,
  selectAuthorisedTreeSlug,
} from 'src/modules/auth/authSlice'
import { Tabs } from 'src/modules/ui'
import { generateTreeLink } from 'src/modules/app/links'

import NewsFeed from './NewsFeed'
import HomeTree from './HomeTree'
import Events from './Events'
import Artefacts from './Artefacts'
import Locations from './Locations'
import Research from './Research'
import Documents from './Documents'
import Photos from './Photos'
import { generateTreeRoute } from 'src/modules/app/links'
import HtmlHeader from '../app/HtmlHeader'
import HomePinnedArticle from './HomePinnedArticle'
import { ShareButton } from '../visibility/InstanceVisibilityControl'
import { useShare } from '../app'
import {
  NAV_KEY_ARTEFACT,
  NAV_KEY_EVENT,
  NAV_KEY_FEED,
  NAV_KEY_LOCATION,
  NAV_KEY_MEDIA,
  NAV_KEY_RESEARCH,
  NAV_KEY_DOCUMENT,
  NAV_KEY_TREE,
  NAV_KEY_WELCOME,
  NAV_KEY_PEOPLE,
} from '../app/links'
import { useEmbed } from '../public/hooks'
import { UserJoyride } from '../app/joyrides'
import { selectUserIsFirstTimeUser } from '../auth/authSlice'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import {
  selectUserIndividualOnTree,
  selectUserHomeIndividual,
} from 'src/modules/auth/authSlice'
import Individuals from './Individuals'

const createHomeRoutesForTree = (treeSlug, showDocuments, forCurrentUser) =>
  [
    {
      navKey: NAV_KEY_WELCOME,
      navLabel: 'Welcome',
      exact: true,
      path: generateTreeLink(treeSlug, 'home'),
      route: generateTreeRoute('home'),
      component: HomePinnedArticle,
    },
    {
      navKey: NAV_KEY_FEED,
      navLabel: 'Feed',
      exact: true,
      path: generateTreeLink(treeSlug, 'home/feed'),
      route: generateTreeRoute('home/feed'),
      component: NewsFeed,
    },
    {
      navKey: NAV_KEY_TREE,
      navLabel: forCurrentUser ? 'Tree' : 'Tree',
      exact: true,
      path: generateTreeLink(treeSlug, `home/tree`),
      route: generateTreeRoute('home/tree'),
      component: HomeTree,
      fullWidth: true,
    },
    {
      navKey: NAV_KEY_RESEARCH,
      navLabel: 'Research & Recollections',
      path: generateTreeLink(treeSlug, `home/research`),
      route: generateTreeRoute('home/research'),
      component: Research,
    },
    showDocuments && {
      navKey: NAV_KEY_DOCUMENT,
      navLabel: 'Documents',
      path: generateTreeLink(treeSlug, `home/documents`),
      route: generateTreeRoute('home/documents'),
      component: Documents,
    },
    {
      navKey: NAV_KEY_MEDIA,
      navLabel: 'Media',
      path: generateTreeLink(treeSlug, `home/media`),
      route: generateTreeRoute('home/media'),
      component: Photos,
    },
    {
      navKey: NAV_KEY_EVENT,
      navLabel: 'Occasions',
      path: generateTreeLink(treeSlug, `home/occasions`),
      route: generateTreeRoute('home/occasions'),
      component: Events,
    },
    {
      navKey: NAV_KEY_ARTEFACT,
      navLabel: 'Artifacts',
      path: generateTreeLink(treeSlug, `home/artefacts`),
      route: generateTreeRoute('home/artefacts'),
      component: Artefacts,
    },
    {
      navKey: NAV_KEY_LOCATION,
      navLabel: 'Places',
      path: generateTreeLink(treeSlug, `home/places`),
      route: generateTreeRoute('home/places'),
      component: Locations,
    },
    {
      navKey: NAV_KEY_PEOPLE,
      navLabel: 'People',
      path: generateTreeLink(treeSlug, `home/people`),
      route: generateTreeRoute('home/people'),
      component: Individuals,
    },
  ].filter(x => x)

export const Title = ({ titleText, treeSlug, isPublic, fromPublic }) => {
  const handleShare = useShare(fromPublic)

  const embed = useEmbed()

  const embedTitle = (
    <Typography variant="h1" color="primary">
      {titleText ? titleText : 'We are making family history'}
    </Typography>
  )

  const fullScreenTitle = (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h1" ml={'auto'}>
        {titleText ? titleText : 'We are making family history'}
      </Typography>
      <Box ml="auto">
        <ShareButton
          permissionAction={ACTION_ALL_ACCESS}
          disabled={!isPublic}
          onClickShare={handleShare}
          isPublic={isPublic}
        />
      </Box>
    </Box>
  )

  return <>{embed ? embedTitle : fullScreenTitle}</>
}

const Home = () => {
  const history = useHistory()
  const user = useSelector(selectUser)
  const isFirstTimeUser = useSelector(selectUserIsFirstTimeUser)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const showFeature = useIsAlphaFeatureUser()?.documents
  const userIndividualOnTree = useSelector(selectUserIndividualOnTree)
  const userHomeIndividual = useSelector(selectUserHomeIndividual)
  const routes = useMemo(
    () =>
      createHomeRoutesForTree(
        treeSlug,
        showFeature,
        userIndividualOnTree === userHomeIndividual
      ),
    [treeSlug, showFeature, userIndividualOnTree, userHomeIndividual]
  )
  const treeName = user?.currentTree?.name

  const title = useMemo(
    () => (
      <Title
        titleText={treeName}
        treeSlug={treeSlug}
        isPublic={user?.currentTree?.allowPublicVisibility}
      />
    ),
    [treeName, treeSlug, user?.currentTree?.allowPublicVisibility]
  )

  useEffect(() => {
    if (!user.currentTree) {
      history.push('/onboarding')
    } else if (!user.individualOnTree && !user.homeIndividual) {
      history.push(`/${user.currentTree.slug}/select-myself`)
    }
  }, [history, user])

  if (!user.currentTree || (!user.individualOnTree && !user.homeIndividual)) {
    console.error(
      `Home(): no currentTree and no (individualOnTree or homeIndividual)`
    )
    return null
  }

  const headerProps = {
    title: 'Home',
    type: 'website',
    url: window.location.href,
    description: `${title} - a WeAre.xyz archive`,
  }

  return (
    <>
      <HtmlHeader {...headerProps} />
      <Tabs title={title} routes={routes} fullWidthSubRootContainer={true} />
      <UserJoyride
        joyRideName={'joyride_first_time_user'}
        runCondition={isFirstTimeUser}
      />
    </>
  )
}

export default Home
