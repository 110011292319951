import { CentreContainer, CreateOrUpdateTree } from './CreateOrUpdateTree'
import React, { useState } from 'react'
import { Container } from '../ui'
import TreeVisibilitySelector from './TreeVisibilitySelector'
import {
  VISIBILITY_TREE,
  VISIBILITY_PUBLIC,
} from '../visibility/visibilityUtils'
import { sendEvent, ga4Events } from '../analytics/AnalyticsUtils'

const CREATE_TREE_WIZARD_STEP_PRIVACY = 'createtreewizard_step_privacy'
const CREATE_TREE_WIZARD_STEP_UPLOAD = 'createtreewizard_step_upload'

const CreateTreeWizard = () => {
  /*
  README: I have probably broken update tree by adding this wizard but the CreateOrUpdateTree is so tightly coupled I
  thought I would kick the can down the road in a cowardly way until we re-enabled tree update.  Sorry RGS 13/2/2023 ;)
   */

  const [wizardState, setWizardState] = useState(
    CREATE_TREE_WIZARD_STEP_PRIVACY
  )
  const [treeVisibility, setTreeVisibility] = useState(VISIBILITY_PUBLIC)

  const handleOnNext = privacy => {
    if (privacy === VISIBILITY_TREE) {
      sendEvent(ga4Events.CREATE_TREE_SELECT_VISIBILITY_PRIVATE)
    } else if (privacy === VISIBILITY_PUBLIC) {
      sendEvent(ga4Events.CREATE_TREE_SELECT_VISIBILITY_PUBLIC)
    }

    setTreeVisibility(privacy)
    setWizardState(CREATE_TREE_WIZARD_STEP_UPLOAD)
  }

  return (
    <Container>
      <CentreContainer>
        {wizardState === CREATE_TREE_WIZARD_STEP_PRIVACY && (
          <TreeVisibilitySelector onNext={handleOnNext} />
        )}
        {wizardState === CREATE_TREE_WIZARD_STEP_UPLOAD && (
          <CreateOrUpdateTree treeVisibility={treeVisibility} />
        )}
      </CentreContainer>
    </Container>
  )
}

export default CreateTreeWizard
