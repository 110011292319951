export const DEFAULT_FEED_LENGTH = 9
export const ACTION_ALL_ACCESS = 'action_all_access'
export const ACTION_EDIT = 'action_edit'
export const ACTION_ADD_TO_BLOG = 'action_add_to_blog'
export const ACTION_DELETE = 'action_delete'
export const ACTION_CREATE = 'action_create'
export const ACTION_PUBLISH = 'action_publish'
export const ACTION_EDIT_TREE_INDIVIDUALS = 'action_edit_tree_individuals'
export const ACTION_CHANGE_TREE_VISIBILITY = 'action_change_tree_visibility'
export const ACTION_LOGIN = 'action_login'
export const ACTION_INVITE_USERS = 'action_invite_users'
export const ACTION_MAKE_AI_REQUEST = 'action_make_ai_request'
export const ACTION_MOVE_CONTENT = 'action_move_content'

export const UserLifecycleStates = {
  INVITED: 'INVITED',
  AWAITING_EMAIL_VERIFICATION: 'AWAITING_EMAIL_VERIFICATION',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  JOINED_EXISTING_TREE: 'JOINED_EXISTING_TREE',
  FIRST_TREE_CREATED_EMPTY: 'FIRST_TREE_CREATED_EMPTY',
  FIRST_TREE_CREATED_GEDCOM: 'FIRST_TREE_CREATED_GEDCOM',
  SUBSEQUENT_TREE_CREATED_GEDCOM: 'SUBSEQUENT_TREE_CREATED_GEDCOM',
  IN_PAID_SUBSCRIPTION: 'IN_PAID_SUBSCRIPTION',
  PAID_SUBSCRIPTION_ENDED: 'PAID_SUBSCRIPTION_ENDED',
}

export const STOP_SHOWING_SIDEBAR_KEY = 'stop_showing_sidebar'
