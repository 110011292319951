import { makeStyles } from '@mui/styles'
import Thumbnail from './Thumbnail'

const useStyles = makeStyles(theme => ({
  photoLarge: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FullMedia = ({ file }) => {
  const classes = useStyles()
  return (
    <Thumbnail className={classes.photoLarge} file={file} maintainProportions />
  )
}

export default FullMedia
