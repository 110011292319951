import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import {
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_DOCUMENT,
} from 'src/modules/app/links'
import {
  fetchHomeContent,
  fetchHomeContentAlbums,
  fetchHomeContentDocuments,
  selectHomeContent,
  selectHomeContentAlbums,
  selectHomeContentDocuments,
  setHomeViewConfig,
} from './homeSlice'
import { selectUser } from 'src/modules/auth/authSlice'

import { BaseContentList } from 'src/modules/page'
import { HomeViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import { getViewConfig } from '../common/viewConfigUtils'
import { AddStoryButton, UploadDocumentButton } from '../ui/actionButtons'
import { generateTreeLink, INSTANCE_TYPE_ARTICLE } from '../app/links'
import Refresh from '../ui/Refresh'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

const HomeContentList = ({ state, type }) => {
  const {
    content,
    contentCache,
    dispatchFetchHomeContent,
    handleFetchMore,
    handleSetViewConfig,
    loading,
    next,
    sort,
    hierarchical,
    ancestralOnly,
    user,
    fetchHomeContentList,
  } = useHomeContentList({ state, type })

  return (
    <>
      <BaseContentList
        actions={
          <Actions
            onSetViewConfig={handleSetViewConfig}
            sort={sort}
            hierarchical={hierarchical}
            ancestralOnly={ancestralOnly}
            fetchContent={fetchHomeContentList}
            type={type}
          />
        }
        {...{
          content,
          contentCache,
          dispatchFetchContent: dispatchFetchHomeContent,
          handleFetchMore,
          loading,
          next,
          results: contentCache,
          type,
          user,
        }}
      />
    </>
  )
}

const ActionButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' },
  [theme.breakpoints.down('md')]: { display: 'none' },
  justifyContent: 'space-between',
  paddingBottom: '0.5rem',
  alignItems: 'center',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

export const Actions = ({
  onSetViewConfig,
  sort,
  hierarchical,
  ancestralOnly,
  type,
  fetchContent,
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  return (
    <ActionButtons>
      <div>
        <HomeViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={onSetViewConfig}
        />
      </div>
      <div>
        {type === INSTANCE_TYPE_DOCUMENT && (
          <UploadDocumentButton
            to={generateTreeLink(treeSlug, 'edit-document')}
          />
        )}
        {type === INSTANCE_TYPE_ARTICLE && (
          <AddStoryButton to={generateTreeLink(treeSlug, 'write-article')} />
        )}
        <Refresh onClick={() => fetchContent()} />
      </div>
    </ActionButtons>
  )
}

const useHomeContentList = ({ state, type }) => {
  const dispatch = useDispatch()
  const dispatchFetchHomeContent = useActionDispatcher(
    {
      [INSTANCE_TYPE_PHOTO_ALBUM]: fetchHomeContentAlbums,
      [INSTANCE_TYPE_DOCUMENT]: fetchHomeContentDocuments,
    }[type] ?? fetchHomeContent
  )
  const content = useSelector(
    {
      [INSTANCE_TYPE_PHOTO_ALBUM]: selectHomeContentAlbums,
      [INSTANCE_TYPE_DOCUMENT]: selectHomeContentDocuments,
    }[type] ?? selectHomeContent
  )
  const user = useSelector(selectUser)
  const [contentCache, setContentCache] = useState([])
  const { next, results } = content
  const [loading, setLoading] = useState()

  const fetchHomeContentList = useCallback(async () => {
    setLoading(true)
    await dispatchFetchHomeContent({
      page: 0,
      state,
      type,
      block_limit: 1,
      block_type: 'TEXT',
    })

    setLoading(false)
  }, [dispatchFetchHomeContent, state, type])

  useEffect(() => {
    if (!results.length) {
      fetchHomeContentList()
    }
  }, [fetchHomeContentList, results.length])

  useEffect(() => {
    setContentCache(results)
  }, [results])

  const handleFetchMore = () => {
    if (dispatchFetchHomeContent.status === 'loading') {
      return
    }
    dispatchFetchHomeContent({
      page: content.page + 1,
      state,
      type,
      block_limit: 1,
      block_type: 'TEXT',
    })
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setHomeViewConfig({ type, viewConfig }))
    fetchHomeContentList()
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(content)

  return {
    content,
    contentCache,
    dispatchFetchHomeContent,
    handleFetchMore,
    handleSetViewConfig,
    loading,
    next,
    sort,
    hierarchical,
    ancestralOnly,
    user,
    fetchHomeContentList,
  }
}

export default HomeContentList
